import DateHelper from '../../../helper/date/DateHelper'
import { NumberServiceProvider } from '../../number/core/Number.service'
import VSysStakingServerLog from '../domain/VSysSupernodeLog.model'
import VSysSupernodeLogDigest from '../domain/VSysSupernodeLogDigest.model'

export default class BuildVSysSupernodeLogDigest {
  constructor(protected services: NumberServiceProvider) {
    //
  }

  execute(logs: VSysStakingServerLog[]): VSysSupernodeLogDigest[] {
    return logs.map(l => ({
      timestamp: DateHelper.format(l.timestamp / 1000000),
      status: `${l.status} ${l.transactions === 1 ? '' : `(${this.abbreviate(l.transactions.toString())})`} `,
      statusHoverMessage: l.transactions === 1 ? '' : `${l.transactions} transactions`,
      amount: this.abbreviate(l.amount, 'VSYS'),
      amountHoverMessage: this.formatVSysAmount(l.amount),
      fee: this.abbreviate(l.fee, 'VSYS'),
      feeHoverMessage: this.formatVSysAmount(l.fee),
      typeName: l.typeName,
      transactionId: this.trimTransactionId(l.transactionId),
      url: l.transactionId ? `https://explorer.v.systems/transactions/${l.transactionId}` : ''
    }))
  }

  formatVSysAmount(value?: string) {
    if (!value) {
      return ''
    }

    const numericValue = parseFloat(value)

    if (parseFloat(value) - parseInt(value) === 0) {
      return `${numericValue.toFixed(0)} VSYS`
    }

    return `${numericValue.toFixed(2)} VSYS`
  }

  abbreviate(value: string, postfix = ''): string {
    if (!parseFloat(value)) {
      return ''
    }

    return ` ${this.services.numberService.abbreviate(parseFloat(value), 1)} ${postfix}`
  }

  protected trimTransactionId(value?: string) {
    if (!value) {
      return value
    }

    return `${value.substring(0, 10)}...`
  }
}
