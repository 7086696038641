import Configuration from '../../config/Configuration'
import VSysSupernodePresenter from '../../../core/v-sys-supernode/presenter/VSysSupernode.presenter'
import VSysSupernodeState from '../../../core/v-sys-supernode/presenter/VSysSupernode.state'
import PresenterComponent from '../common/PresenterComponent'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import Table from '../table/Table'
import TD from '../table/TD'
import TH from '../table/TH'
import TDVSysStatus from '../table/status/TDVSysStatus'
import WarningStrip from '../table/WarningStrip'
import LoadMoreButton from '../table/LoadMoreButton'
import VSysServersStatus from './VSysServersStatus'

export interface VSysSupernodeStatusProps {
}

export default class VSysSupernodeStatusPage extends PresenterComponent<VSysSupernodeStatusProps, VSysSupernodeState, VSysSupernodePresenter> {
  constructor(props: VSysSupernodeStatusProps) {
    super(props, new VSysSupernodePresenter(Configuration.services))
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  render() {
    return <>
      <h1>VSys</h1>
      {this.renderHeader()}
      {this.renderWarning()}
      <VSysServersStatus />
      <h1>Transactions</h1>
      {this.renderTable()}
      {this.renderLoadMoreButton()}
    </>
  }

  protected renderHeader() {
    return <>
      <p>
        <strong>Supernode Address: </strong>
        <a href={this.state.status.url}>{this.state.status.address}</a>
      </p>
      <p><strong>Avg. Block Size: </strong>{this.state.status.avgBlockSize}</p>
      <p><strong>Avg. Tx. per Block: </strong>{this.state.status.avgTxPerBlock}</p>
      <p><strong>Block Delay: </strong>{this.state.status.genBlockDelay}</p>
      <p><strong>Success Block Rate: </strong>{this.state.status.missBlockRate}</p>
    </>
  }

  protected renderWarning() {
    if (!this.state.warning) {
      return
    }
    return <WarningStrip message={this.state.warning} />
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH>Timestamp</TH>
          <TH.Center>Type</TH.Center>
          <TH.Center>Status</TH.Center>
          <TH.Center>Amount</TH.Center>
          <TH.Center>Fee</TH.Center>
          <TH.Center>Transaction ID</TH.Center>
        </tr>
      </thead>
      <tbody>
        {this.renderTableData()}
        {this.renderLoadingContent()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <LoadingPlaceholder columns={6}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map(
      e => (
        <tr key={e.timestamp}>
          <TD.Bold>{e.timestamp}</TD.Bold>
          <TD.Center>{e.typeName}</TD.Center>
          <TDVSysStatus value={e.status} hoverMessage={e.statusHoverMessage}></TDVSysStatus>
          <TD.Center><span title={e.amountHoverMessage}>{e.amount}</span></TD.Center>
          <TD.Center><span title={e.feeHoverMessage}>{e.fee}</span></TD.Center>
          <TD.Center>
            <a href={e.url}>
              {e.transactionId}
            </a>
          </TD.Center>
        </tr>
      )
    )
  }

  private renderLoadMoreButton() {
    if (!this.state.displayLoadMoreButton) {
      return ''
    }

    return <LoadMoreButton onClick={() => this.handleLoadMoreButtonClick()} />
  }

  handleLoadMoreButtonClick() {
    this.presenter.loadMoreData()
  }
}
