import * as React from 'react'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'

export interface ErrorMessageProps {
  content?: string
}
export interface ErrorMessageState {
  display?: boolean
}

export default class ErrorMessage extends React.Component<ErrorMessageProps, ErrorMessageState> {
  constructor(props: ErrorMessageProps) {
    super(props)
    this.state = { display: true }
  }

  public render() {
    if (!this.props.content) {
      return
    }

    return (
      <ToastContainer position='middle-center'>
        <Toast show={this.state.display} onClose={() => this.hide()}>
          <Toast.Header className='justify-content-between'>
            <strong>Error</strong>
          </Toast.Header>
          <Toast.Body>{this.props.content}</Toast.Body>
        </Toast>
      </ToastContainer>
    )
  }

  hide() {
    this.setState({ display: false })
  }
}
