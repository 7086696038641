import Configuration from '../../config/Configuration'
import AllChainDataPresenter from '../../../core/chain-data/presenter/AllChainData.presenter'
import AllChainDataState from '../../../core/chain-data/presenter/AllChainData.state'
import ChainDataMethodFilter from './ChainDataMethodFilter'
import ChainFilter from '../chain/ChainFilter'
import PresenterComponent from '../common/PresenterComponent'
import Info from '../Info'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import Table from '../table/Table'
import TD from '../table/TD'
import TH from '../table/TH'

export interface AllChainDataProps {
}

export default class AllChainData extends PresenterComponent<AllChainDataProps, AllChainDataState, AllChainDataPresenter> {
  constructor(props: AllChainDataProps) {
    const presenter = new AllChainDataPresenter(Configuration.services)
    super(props, presenter)
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this.presenter.init()
  }

  public render() {
    return (
      <>
        {this.renderFilterPanel()}
        {this.renderTable()}
      </>
    )
  }

  private renderFilterPanel() {
    return <>
      <h1>Full List of Database Calls</h1>
      <ChainFilter onChange={(searchTerm: string) => this.presenter.setTextSearchTerm(searchTerm)}/>
      <ChainDataMethodFilter onFilter={(methodFilter) => this.presenter.setMethodFilter(methodFilter)} />
    </>
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH>Chain</TH>
          <TH.Center>Time of DB Function Call</TH.Center>
          <TH.Center>Method</TH.Center>
          <TH.Center>Result</TH.Center>
          <TH.Center>Instance ID, Region</TH.Center>
        </tr>
      </thead>
      <tbody>

        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <LoadingPlaceholder columns={5}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map((value, i) => (
      <tr key={`chain-data-${i}`}>
        <TD.Bold>{value.chain}</TD.Bold>
        <TD.Center>{value.time}</TD.Center>
        <TD.Center>{value.method}</TD.Center>
        <TD.Center>
          {value.result}
          {value.resultInfo ? <Info message={value.resultInfo}/> : ''}
        </TD.Center>
        <TD.Center>{value.instanceIdRegion}</TD.Center>
      </tr>
    ))
  }
}
