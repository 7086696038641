import * as React from 'react'

export interface ICheckboxProps {
  id?: string
  initialState?: boolean
  onChange?: (checked: boolean) => void
}

export interface ICheckboxState {
  id?: string
  isChecked: boolean
}

export default class Checkbox extends React.Component<ICheckboxProps, ICheckboxState> {
  constructor(props: ICheckboxProps) {
    super(props)
    this.state = {
      id: this.props.id,
      isChecked: this.props.initialState || false
    }
  }

  public render() {
    return (

      <input id={this.state.id}
        type="checkbox"
        onChange={(e) => this.handleCheckboxChange(e)}
        checked={this.state.isChecked}
        value={this.state.isChecked.toString()}
      />
    )
  }

  handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = event.target?.checked || false
    this.setState({ isChecked })
    if (this.props.onChange) {
      this.props.onChange(isChecked)
    }
  }

  toggleIsChecked() {
    this.setState({ isChecked: !this.state.isChecked })
  }
}
