import Configuration from '../../config/Configuration'
import SolanaPresenter from '../../../core/solana/presenter/Solana.presenter'
import SolanaState from '../../../core/solana/presenter/Solana.state'
import PresenterComponent from '../common/PresenterComponent'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import DiskUsage from '../table/DiskUsage'
import Table from '../table/Table'
import TD from '../table/TD'
import TH from '../table/TH'
import Error from '../Error'
import TDHealthStatus from '../table/status/TDHealthStatus'

export interface SolanaValidatorStatusProps {
}

export default class SolanaValidatorStatus extends PresenterComponent<SolanaValidatorStatusProps, SolanaState, SolanaPresenter> {
  constructor(props: SolanaValidatorStatusProps) {
    super(props, new SolanaPresenter(Configuration.services))
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  render() {
    return <>
      <h1>Solana Validators Status</h1>
      {this.renderTable()}
    </>
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH>Name</TH>
          <TH.Center>IP</TH.Center>
          <TH.Center>Public Key</TH.Center>
          <TH.Center>Vote Account</TH.Center>
          <TH.Center>Health</TH.Center>
          <TH.Center>Disk Usage</TH.Center>
          <TH.Center>Disk Usage Blockchain</TH.Center>
          <TH.Center>Time</TH.Center>
        </tr>
      </thead>
      <tbody>
        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <LoadingPlaceholder columns={10}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map(
      e => (
        <tr key={e.serverName}>
          <TD.Bold>{e.serverName}</TD.Bold>
          <TD.Center>{e.serverIp}</TD.Center>
          <TD.Center><a href={e.publicKeyURL}>{this.trimPublicKey(e.publicKey)}</a></TD.Center>
          <TD.Center><a href={e.voteAccountURL}>{this.trimPublicKey(e.voteAccount)}</a></TD.Center>
          <TDHealthStatus value={e.healthcheck}> <Error message={e.healthcheckError} /></TDHealthStatus>
          <DiskUsage>{e.diskUsage} <Error message={e.diskUsageError} /> </DiskUsage>
          <DiskUsage>{e.diskUsageBlockchain} <Error message={e.diskUsageBlockchainError} /> </DiskUsage>
          <TD.Center>{e.time}</TD.Center>
        </tr>
      )
    )
  }

  protected trimPublicKey(key: string) {
    return `${key.substring(0, 10)}...`
  }
}
