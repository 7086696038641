import { MessagesServiceProvider } from '../../messages/domain/Messages.service'
import ChainData, { ChainDataConnectBlockResult, ChainDataExternalBlockResult, ChainDataSnapshotResult, ChainDataVersionResult } from '../domain/ChainData.entity'
import ChainDataDigest from '../domain/ChainDataDigest.model'

export default class BuildChainDataDigest {
  execute(chainData: ChainData): ChainDataDigest {
    return this.buildChainDataDigest(chainData)
  }

  constructor(protected services: MessagesServiceProvider) {
    //
  }

  protected buildChainDataDigest(chainData: ChainData): ChainDataDigest {
    return {
      chain: chainData.chain || '',
      time: new Date(chainData.time).toLocaleString(),
      method: chainData.method,
      result: this.getResultDataAsString(chainData),
      resultInfo: this.buildResultInfo(chainData),
      instanceIdRegion: chainData.instanceId ? `${chainData.instanceId}, ${chainData.region}` : ''
    }
  }

  getResultDataAsString(data: ChainData): string {
    if (data.method === 'snapshot') {
      const result = data.result as ChainDataSnapshotResult
      return result.snapshotBlock?.toString() || (result.health && 'healthy ') || 'unhealthy '
    }

    if (data.method === 'external-block') {
      const result = data.result as ChainDataExternalBlockResult
      return result.externalBlock.toString()
    }

    if (data.method === 'versions') {
      const result = data.result as ChainDataVersionResult
      return result.softwareVersion
    }

    if (data.method === 'connect-block') {
      const result = data.result as ChainDataConnectBlockResult
      return result.connectBlock.toString()
    }

    return ''
  }

  protected buildResultInfo(data: ChainData) {
    if (data.method === 'snapshot') {
      const snapshotData = data.result as ChainDataSnapshotResult
      if (!snapshotData.snapshotBlock) {
        return this.services.messageService.get('missingSnapshotBlockInfo')
      }
    }
    return ''
  }
}
