import React, { ReactNode } from 'react'

export interface TDProps {
  className?: string
  style?: React.CSSProperties
  children?:
  | ReactNode
  | ReactNode[]
}

export default class TD extends React.Component<TDProps> {
  constructor(props: TDProps, protected className: string = '') {
    super(props)
  }

  static defaultProps = {
    className: '',
    style: {}
  }

  static Bold = (props: TDProps) => new TD(props, 'fw-bold').render()
  static Center = (props: TDProps) => new TD(props, 'text-center').render()
  static BoldCenter = (props: TDProps) => new TD(props, 'fw-bold text-center').render()

  public render() {
    return <td style={this.props.style} className={`${this.props.className} ${this.className}`}> {this.props.children} </td>
  }
}
