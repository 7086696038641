import { MessagesServiceProvider } from '../../messages/domain/Messages.service'
import ChainData, { ChainDataConnectBlockResult, ChainDataExternalBlockResult, ChainDataSnapshotResult } from '../domain/ChainData.entity'
import ChainStateDigest from '../domain/ChainStateDigest.model'
import FilterChainData from './FilterChainData.usecase'

export default class BuildChainsStateDigestFromRegion {
  constructor(protected services: MessagesServiceProvider) {
    //
  }

  execute(chainsData: ChainData[], region: string): ChainStateDigest[] {
    return this.sortDigestsByChainName(this.buildDigests(chainsData, region))
  }

  protected buildDigests(chainsData: ChainData[], region: string): ChainStateDigest[] {
    const data = this.buildDigestsFromSnapshotData(chainsData, region)
    this.fillConnectBlocks(chainsData, data)
    this.fillExternalBlocks(chainsData, data)

    return data
  }

  buildDigestsFromSnapshotData(chainsData: ChainData[], region: string): ChainStateDigest[] {
    const filter = {
      region,
      methodFilter: {
        snapshot: true
      }
    }

    const snapshotDataFromRegion = new FilterChainData().execute(chainsData, filter) as ChainData<ChainDataSnapshotResult>[]
    return snapshotDataFromRegion.map(e => {
      const chain = e.chain
      const region = e.region || ''
      const time = new Date(e.time).toLocaleString()
      const instanceId = e.instanceId || ''
      let snapshotBlock: string | undefined = e.result.snapshotBlock?.toString()
      let snapshotBlockInfo: string | undefined
      if (!snapshotBlock && e.result.health !== undefined) {
        snapshotBlock = e.result.health ? 'healthy' : 'unhealthy'
        snapshotBlockInfo = this.services.messageService.get('missingSnapshotBlockInfo')
      }
      const usedSpace = `${e.result.usedSpace} %`

      return {
        chain,
        region,
        time,
        instanceId,
        snapshotBlock,
        snapshotBlockInfo,
        usedSpace
      } as ChainStateDigest
    })
  }

  fillConnectBlocks(chainsData: ChainData[], digests: ChainStateDigest[]) {
    const connectBlocksChainData = new FilterChainData().execute<ChainDataConnectBlockResult>(chainsData, { methodFilter: { connectBlock: true } })
    connectBlocksChainData.forEach(e => {
      digests
        .filter(digest => e.chain === digest.chain)
        .forEach(digest => { digest.connectBlock = e.result.connectBlock.toString() })
    })
  }

  fillExternalBlocks(chainsData: ChainData[], digests: ChainStateDigest[]) {
    const externalBlocksChainData = new FilterChainData().execute<ChainDataExternalBlockResult>(chainsData, { methodFilter: { externalBlock: true } })
    externalBlocksChainData.forEach(e => {
      digests
        .filter(digest => e.chain === digest.chain)
        .forEach(digest => { digest.externalBlock = e.result.externalBlock.toString() })
    })
  }

  protected sortDigestsByChainName(data: ChainStateDigest[]) {
    return data.sort((a, b) => a.chain.localeCompare(b.chain))
  }
}
