import Presenter from '../../common/presenter/Presenter'
import { PackagesVersionsServiceProvider } from '../domain/PackagesVersions.service'
import PackagesVersionsState from './PackagesVersions.state'

const initialState: PackagesVersionsState = {
  clientVersion: '',
  cloudSnapVersion: '',
  serverVersion: ''
}

export default class PackagesVersionsPresenter extends Presenter<PackagesVersionsState> {
  constructor(protected services: PackagesVersionsServiceProvider) {
    super(initialState)
  }

  async init() {
    this.loadPackageVersions()
  }

  async loadPackageVersions() {
    const packageVersions = await this.services.packagesVersionsService.getVersions()
    this.changeState({
      clientVersion: packageVersions.client,
      serverVersion: packageVersions.server,
      cloudSnapVersion: packageVersions.cloudSnap
    })
  }
}
