import Presenter from '../../common/presenter/Presenter'
import { SolanaServiceProvider } from '../domain/Solana.service'
import SolanaValidatorStatusDigest from '../domain/SolanaValidatorStatusDigest.model'
import BuildSolanaValidatorStatusDigest from '../usecase/BuildSolanaValidatorStatusDigest.usecase'
import GetSolanaValidatorsStatus from '../usecase/GetSolanaValidatorStatus.usecase'
import SolanaState from './Solana.state'

const initialState: SolanaState = {
  displayData: false,
  displayLoadingMessage: false,
  data: []
}

export default class SolanaPresenter extends Presenter<SolanaState> {
  constructor(protected services: SolanaServiceProvider) {
    super(initialState)
  }

  async init() {
    this.loadServersData()
  }

  async loadServersData() {
    const serverData = await new GetSolanaValidatorsStatus(this.services).execute()
    const data: SolanaValidatorStatusDigest[] = new BuildSolanaValidatorStatusDigest().execute(serverData)

    this.changeState({
      data,
      displayData: true,
      displayLoadingMessage: false
    })
  }
}
