export default class NumberHelper {
  static abbreviate(value: number, maxFractionDigits = 1): string {
    const abbreviations = [
      { value: 10 ** 12, label: 'T' },
      { value: 10 ** 9, label: 'B' },
      { value: 10 ** 6, label: 'M' },
      { value: 10 ** 3, label: 'K' }
    ]

    for (const abb of abbreviations) {
      if (value >= abb.value) {
        return `${this.simplifyFractionDigits(value / abb.value, maxFractionDigits)}${abb.label}`
      }
    }

    return this.simplifyFractionDigits(value, maxFractionDigits)
  }

  static simplifyFractionDigits(value: number, maxFractionDigits = 1): string {
    return parseFloat(value.toFixed(maxFractionDigits)).toString()
  }
}
