import './App.scss'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NavBar from '../components/layout/NavBar'
import Footer from '../components/layout/Footer'

import styled from 'styled-components'
import OAuthLogin from '../components/login/OAuthLogin'
import PresenterComponent from '../components/common/PresenterComponent'
import AppState from '../../core/app/presenter/App.state'
import AppPresenter from '../../core/app/presenter/App.presenter'
import Configuration from '../config/Configuration'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ErrorBoundary from '../components/ErrorBoundary'
import ErrorMessage from '../components/ErrorMessage'
import AppStaticConfiguration from './AppStaticConfiguration'

const Container = styled.div.attrs({
  className: 'd-flex flex-column'
})`
  min-height: 100vh;
`

const MainContent = styled.div.attrs({
  className: 'container-fluid'
})`
  flex-grow: 1;
  padding: 1vw 2vw;
`

export interface AppProps {
  routes: {
    path: string
    component: JSX.Element
  }[]
  menuItems: {
    url: string
    label: string
  }[]

  brandName: string
  googleClientId: string
}

export default class App extends PresenterComponent<AppProps, AppState, AppPresenter> {
  constructor(props: AppProps) {
    super(props, new AppPresenter(Configuration.services))
    AppStaticConfiguration.execute(Configuration)
    this.presenter.init()
  }

  public render() {
    return (
      <ErrorBoundary>
        <GoogleOAuthProvider clientId={this.props.googleClientId}>
          {
            this.state.displayContent === false
              ? <OAuthLogin />
              : <Container>
                <BrowserRouter>
                  <NavBar brandName={this.props.brandName} menuItems={this.props.menuItems} />
                  <MainContent>
                    {
                      this.state.hasErrors
                        ? <ErrorMessage content={this.state.errorMessage}/>
                        : <Routes>
                          {
                            this.props.routes.map((route, i) =>
                              (<Route key={`route-${i}`} path={route.path} element={route.component} />)
                            )
                          }
                        </Routes>
                    }
                  </MainContent>
                  <Footer />
                </BrowserRouter>
              </Container>
          }
        </GoogleOAuthProvider>
      </ErrorBoundary>
    )
  }

  componentDidCatch(error: Error) {
    this.presenter.notifyError(error)
  }
}
