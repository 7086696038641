import { ClustersReportRepositoryProvider } from '../domain/ClustersReport.repository'

export default class GetClustersReport {
  constructor(protected services: ClustersReportRepositoryProvider) {
    //
  }

  async execute() {
    return this.services.clustersReportRepository.getReport()
  }
}
