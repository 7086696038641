import Configuration from '../../config/Configuration'
import VSysServerPresenter from '../../../core/v-sys-server/presenter/VSysServer.presenter'
import VSysServerState from '../../../core/v-sys-server/presenter/VSysServer.state'
import PresenterComponent from '../common/PresenterComponent'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import DiskUsage from '../table/DiskUsage'
import Table from '../table/Table'
import TD from '../table/TD'
import TH from '../table/TH'
import Error from '../Error'
import TDHealthStatus from '../table/status/TDHealthStatus'

export interface VSysServersStatusProps {
}

export default class VSysServersStatus extends PresenterComponent<VSysServersStatusProps, VSysServerState, VSysServerPresenter> {
  constructor(props: VSysServersStatusProps) {
    super(props, new VSysServerPresenter(Configuration.services))
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  render() {
    return <>
      <h1>Servers</h1>
      {this.renderTable()}
    </>
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH>Name</TH>
          <TH.Center>IP</TH.Center>
          <TH.Center>Health</TH.Center>
          <TH.Center>Disk Usage</TH.Center>
          <TH.Center>State Height</TH.Center>
          <TH.Center>Blockchain Height</TH.Center>
          <TH.Center>Updated Timestamp</TH.Center>
          <TH.Center>Time</TH.Center>
        </tr>
      </thead>
      <tbody>
        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <LoadingPlaceholder columns={8}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map(
      e => (
        <tr key={e.serverName}>
          <TD.Bold>{e.serverName}</TD.Bold>
          <TD.Center>{e.serverIp}</TD.Center>
          <TDHealthStatus value={e.healthcheck}> <Error message={e.healthcheckError} /> </TDHealthStatus>
          <DiskUsage>{e.diskUsage} <Error message={e.diskUsageError} /> </DiskUsage>
          <TD.Center>{e.stateHeight}  <Error message={e.stateHeightError} /> </TD.Center>
          <TD.Center>{e.blockchainHeight}  <Error message={e.blockchainHeightError} /> </TD.Center>
          <TD.Center>{e.updatedTimestamp}  <Error message={e.updatedTimestampError} /> </TD.Center>
          <TD.Center>{e.time}</TD.Center>
        </tr>
      )
    )
  }
}
