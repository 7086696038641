import Presenter from '../../common/presenter/Presenter'
import ClustersReport from '../domain/ClustersReport.entity'
import { ClustersReportRepositoryProvider } from '../domain/ClustersReport.repository'
import BuildRegionClustersReportDigest from '../usecase/BuildRegionClustersReportDigest.usecase'
import GetClustersReport from '../usecase/GetClustersReport.usecases'
import ClustersState from './Clusters.state'

const initialState: ClustersState = {
  displayData: false,
  displayLoadingMessage: true,
  data: {
    clusters: []
  },
  selectedAccount: '',
  selectedRegion: '',
  accounts: [],
  regions: []
}
export default class ClustersPresenter extends Presenter<ClustersState> {
  protected accounts: string[] = []
  protected regions: string[] = []
  protected selectedAccount: string | undefined
  protected selectedRegion: string | undefined
  protected clustersReport: ClustersReport | undefined

  constructor(protected services: ClustersReportRepositoryProvider) {
    super(initialState)
  }

  async init(): Promise<void> {
    await this.refreshStateData()
  }

  async selectAccount(selectedAccount: string): Promise<void> {
    this.selectedAccount = selectedAccount
    this.refreshStateData()
  }

  async selectRegion(selectedRegion: string): Promise<void> {
    this.selectedRegion = selectedRegion
    this.refreshStateData()
  }

  protected async loadDataFromServer(): Promise<void> {
    this.clustersReport = await (new GetClustersReport(this.services)).execute()
  }

  protected async refreshStateData(): Promise<void> {
    this.displayLoadingMessage()

    if (!this.clustersReport) {
      try {
        await this.loadDataFromServer()
      } catch (e) {
        this.displayLoadingErrorMessage()
        return
      }
    }

    this.refreshAccounts()
    this.refreshRegions()
    this.displaySelectedRegion()
  }

  protected displaySelectedRegion() {
    const selectedRegionReport = this.getRegionReport()

    if (!selectedRegionReport) {
      this.changeState({
        data: undefined,
        displayData: false,
        displayLoadingMessage: false,
        noDataMessage: 'No data available.'
      })
      return
    }

    const data = new BuildRegionClustersReportDigest().execute(selectedRegionReport)
    this.changeState({
      data,
      displayData: true,
      displayLoadingMessage: false,
      noDataMessage: undefined
    })
  }

  protected displayLoadingMessage() {
    this.changeState({ displayLoadingMessage: true, displayData: false })
  }

  protected displayLoadingErrorMessage() {
    this.changeState({
      errorMessage: 'Failed to load data',
      displayLoadingMessage: false,
      noDataMessage: 'No data available.'
    })
  }

  protected refreshAccounts() {
    if (!this.clustersReport) {
      throw Error('Clusters report not loaded')
    }

    this.accounts = Object.keys(this.clustersReport.accounts)

    if (!this.accounts.length) {
      this.selectedAccount = undefined
    }

    if (!this.selectedAccount) {
      this.selectedAccount = this.accounts[0]
    }

    this.changeState({
      accounts: this.buildAccounts(this.selectedAccount),
      selectedAccount: this.selectedAccount
    })
  }

  protected refreshRegions() {
    if (!this.clustersReport) {
      throw Error('Clusters report not loaded')
    }

    if (!this.selectedAccount) {
      throw Error('Account not selected')
    }

    const accountReport = this.clustersReport.accounts[this.selectedAccount]
    this.regions = Object.keys(accountReport.regions)

    if (!this.regions.length) {
      this.selectedRegion = undefined
    }

    if (!this.selectedRegion) {
      this.selectedRegion = this.regions[0]
    }

    this.changeState({
      regions: this.buildRegions(this.selectedRegion),
      selectedRegion: this.selectedRegion
    })
  }

  protected getRegionReport() {
    if (!this.clustersReport) {
      throw Error('Clusters report not loaded')
    }
    if (!this.selectedAccount) {
      throw Error('Account not selected')
    }
    if (!this.selectedRegion) {
      throw Error('Region not selected')
    }
    if (!this.clustersReport.accounts[this.selectedAccount]) {
      return undefined
    }
    if (!this.clustersReport.accounts[this.selectedAccount].regions[this.selectedRegion]) {
      return undefined
    }
    return this.clustersReport.accounts[this.selectedAccount].regions[this.selectedRegion]
  }

  protected buildAccounts(selectedAccount: string) {
    return this.accounts.map(key => ({
      key,
      active: selectedAccount === key
    }))
  }

  protected buildRegions(selectedRegion: string) {
    return this.regions.map(key => ({
      key,
      active: selectedRegion === key
    }))
  }
}
