import ServerOverview, { ServerStatus } from '../../overview/domain/Server.model'
import EthValidatorReport from '../domain/EthValidatorReport.model'

export default class BuildEthValidatorsOverview {
  constructor() {
    //
  }

  execute(reports: EthValidatorReport[]): ServerOverview[] {
    return reports.map((report) => {
      return {
        label: report.validatorIndex.toString(),
        status: this.getStatus(report),
        url: 'eth-validators'
      }
    })
  }

  getStatus(report: EthValidatorReport): ServerStatus {
    if (!report.status) {
      return 'notok'
    }

    if (report.status.state === 'exited') {
      if (report.status.balance > 0) {
        return 'notok'
      }
      return 'inactive'
    }

    if (report.status.state === 'active_online') {
      return 'ok'
    }

    return 'notok'
  }
}
