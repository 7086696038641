import ChainData from '../domain/ChainData.entity'
import { ChainDataRepositoryProvider } from '../domain/ChainData.repository'

export default class GetLatestActiveChainsData {
  constructor(protected services: ChainDataRepositoryProvider) {
    //
  }

  async execute(includeInactiveChains = false): Promise<ChainData[]> {
    return this.services.chainDataRepository.getLatestChainData(includeInactiveChains)
  }
}
