import StateController from '../../common/state/StateController'
import Credential from '../domain/Credential.model'
import CredentialHolderService from '../domain/CredentialHolder.service'

const SESSION_STORAGE_KEY = 'authToken'

export default class SessionStorageCredentialHolder extends StateController<Partial<Credential>> implements CredentialHolderService {
  constructor() {
    super(SessionStorageCredentialHolder.getCredential() || {})
  }

  static getCredential(): Credential | undefined {
    const credentialString = sessionStorage.getItem(SESSION_STORAGE_KEY)

    if (!credentialString) {
      return undefined
    }

    return JSON.parse(credentialString)
  }

  setCredential(credential: Credential | undefined): void {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(credential))
    this.setState(credential || {})
  }

  getCredential(): Credential | undefined {
    return SessionStorageCredentialHolder.getCredential()
  }
}
