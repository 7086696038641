import APIAdapter from '../../common/adapter/APIAdapter'
import OrchidService from '../domain/Orchid.service'
import OrchidStatus from '../domain/OrchidStatus.model'

export default class OrchidAPI extends APIAdapter implements OrchidService {
  async getStatus(pageNumber: number, pageSize: number): Promise<OrchidStatus[]> {
    const response = await this.get<{
      success: boolean,
      data: OrchidStatus[],
    }>('/orchid-status', {
      pageNumber,
      pageSize
    })
    return response.data
  }
}
