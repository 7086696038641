import EthValidatorTimeline from '../domain/EthValidatorTimeline.model'
import { EthValidatorTimelineServiceProvider } from '../domain/EthValidatorTimeline.service'

export default class GetValidatorsTimelines {
  constructor(
    private services: EthValidatorTimelineServiceProvider
  ) {
    //
  }

  async execute(instancesIds?: number[], initialTimestamp?: number, finalTimestamp?: number): Promise<EthValidatorTimeline[]> {
    return this.services.ethValidatorTimelineService.getTimelines(instancesIds, initialTimestamp, finalTimestamp)
  }
}
