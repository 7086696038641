import { VSysSupernodeServiceProvider } from '../domain/VSysSupernode.service'
import VSysSupernodeStatus from '../domain/VSysSupernodeStatus.model'

export default class GetVSysSupernodeStatus {
  constructor(protected services: VSysSupernodeServiceProvider) {
    //
  }

  async execute(): Promise<VSysSupernodeStatus> {
    return this.services.vsysSupernodeService.getStatus()
  }
}
