import * as React from 'react'
import styled from 'styled-components'

export interface IStatusCellProps {
  className?: string
  style?: React.CSSProperties
  label: string
  color: string
  blink?: boolean
  displayTag?: boolean
  hoverMessage?: string
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

const Container = styled.div`
  display: inline;
  text-align: center;
`

const Tag = styled.div`
  border-radius: 0.2em;
  background-color: #eee;
  display: inline-flex;
  flex-direction: row;
`
const Indicator = styled.div`
  border-radius: 100%;
  height: 0.6em;
  width: 0.6em;
  margin: auto 0.5em;

  @keyframes blinker {
    from {opacity: 1.0;}
    to {opacity: 0.0;}
  }

  &.blink {
    animation-name: blinker;
    animation-duration: 0.5s;
    animation-iteration-count:infinite;
    animation-timing-function:ease-in-out;
    animation-direction: alternate;
  }
`
const Label = styled.div`
  margin: auto 0.5em auto 0;
  font-size: 0.8em;
  line-height: 2em;

  span {
    margin: 0 !important;
    padding: 0 !important;
  }
`

export default class Status extends React.Component<IStatusCellProps> {
  static defaultProps = {
    blink: false,
    displayTag: true,
    hoverMessage: '',
    className: '',
    style: {}
  }

  public render() {
    return (
      <Container style={this.props.style} className={this.props.className}>
        { this.props.displayTag
          ? <Tag>
              <Indicator className={this.props.blink ? 'blink' : ''} style={{ backgroundColor: this.props.color }}></Indicator>
              <Label>
                <span title={this.props.hoverMessage}>
                  {this.props.label}
                </span>
              </Label>
            </Tag>
          : ''
        }
        {this.props.children}
      </Container>
    )
  }
}
