import * as React from 'react'
import styled from 'styled-components'
import logo from '../../../resources/logo.svg'

const Wrapper = styled.a.attrs({
  className: 'navbar-brand'
})``

export interface ILogoProps {
}

export default class Logo extends React.Component<ILogoProps> {
  public render() {
    return (
      <Wrapper href="">
        <img src={logo} width="50" height="50" alt="" />
      </Wrapper>
    )
  }
}
