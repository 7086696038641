import React from 'react'
import styled from 'styled-components'
import ChainDataMethodFilterModel from '../../../core/chain-data/domain/ChainDataMethodFilter.model'
import Checkbox from '../form/Checkbox'

const CheckboxFilter = styled.label`
  display: inline-flex;
  flex-direction: column;
  margin-right: 1em;
  margin-bottom: 1em;
`

export interface ChainDataMethodFilterProps {
  onFilter: MethodFilterHandler
}

export interface ChainDataMethodFilterState extends ChainDataMethodFilterModel {

}

export type MethodFilterHandler = (options: ChainDataMethodFilterModel) => void

export default class ChainDataMethodFilter extends React.Component<ChainDataMethodFilterProps, ChainDataMethodFilterState> {
  public render() {
    return (

      <div className='mt-4 mb-2'>
        <h6>Filter by method: </h6>
        <CheckboxFilter>
          External Block:
          <Checkbox onChange={(externalBlock) => this.setState({ ...this.state, externalBlock })} />
        </CheckboxFilter>
        <CheckboxFilter>
          Connect Block:
          <Checkbox onChange={(connectBlock) => this.setState({ ...this.state, connectBlock })} />
        </CheckboxFilter>
        <CheckboxFilter>
          Running Block:
          <Checkbox onChange={(runningBlock) => this.setState({ ...this.state, runningBlock })} />
        </CheckboxFilter>
        <CheckboxFilter>
          Snapshot Block:
          <Checkbox onChange={(snapshot) => this.setState({ ...this.state, snapshot })} />
        </CheckboxFilter>
        <CheckboxFilter>
          Versions:
          <Checkbox onChange={(versions) => this.setState({ ...this.state, versions })} />
        </CheckboxFilter>
        <button onClick={() => this.props.onFilter(this.state)}>
          Filter by Checked Methods
        </button>
      </div>
    )
  }
}
