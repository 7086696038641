import DateHelper from '../../../helper/date/DateHelper'
import ChainSyncTimeMetricDigest from '../domain/ChainSyncTimeMetricsDigest'
import NodeSyncTimeMetrics from '../domain/NodeSyncTimeMetrics.entity'
import SyncTimeMetricsSection from '../domain/SyncTimeMetricsSection.model'

export default class BuildSyncTimeMetricsDigests {
  constructor() {
    //
  }

  async execute(metrics: NodeSyncTimeMetrics[]): Promise<ChainSyncTimeMetricDigest[]> {
    return this.buildData(metrics)
  }

  protected async buildData(metrics: NodeSyncTimeMetrics[]) {
    return metrics.map(e => ({
      chain: e.chain,
      lastMonth: this.formatPeriodMetrics(e.month),
      lastQuarter: this.formatPeriodMetrics(e.quarter),
      lastYear: this.formatPeriodMetrics(e.year)
    }))
  }

  protected formatPeriodMetrics(section: SyncTimeMetricsSection) {
    return {
      avg: `${DateHelper.formatMsAsMinutes(section.avgTimeToSync)} min`,
      max: `${DateHelper.formatMsAsMinutes(section.maxTimeToSync)} min`,
      min: `${DateHelper.formatMsAsMinutes(section.minTimeToSync)} min`,
      avgToCatchUp: `${DateHelper.formatMsAsMinutes(section.avgTimeToCatchUp)} min (${DateHelper.formatMsAsSeconds(section.avgTimeToCatchUp)} secs)`
    }
  }
}
