import * as React from 'react'

export interface ErrorProps {
  message?: string
}

export default class Error extends React.Component<ErrorProps> {
  public render() {
    if (!this.props.message) {
      return
    }

    return (
      <span className='mx-1' title={this.props.message} style={{
        cursor: 'pointer'
      }}>
        ⚠
      </span>
    )
  }
}
