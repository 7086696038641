import { VSysSupernodeServiceProvider } from '../domain/VSysSupernode.service'
import VSysSupernodeLog from '../domain/VSysSupernodeLog.model'

export default class GetVSysSupernodeLog {
  constructor(protected services: VSysSupernodeServiceProvider) {
    //
  }

  async execute(pageNumber = 1, pageSize = 20): Promise<VSysSupernodeLog[]> {
    return this.services.vsysSupernodeService.getLog(pageNumber, pageSize)
  }
}
