import ChainData from '../domain/ChainData.entity'
import { ChainDataRepositoryProvider } from '../domain/ChainData.repository'

export default class GetAllChainData {
  constructor(protected services: ChainDataRepositoryProvider) {
    //
  }

  async execute(): Promise<ChainData[]> {
    return this.services.chainDataRepository.getChainData()
  }
}
