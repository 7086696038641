import LastestChainDataPresenter from '../../../core/chain-data/presenter/LastestChainData.presenter'
import LastestChainDataState from '../../../core/chain-data/presenter/LastestChainData.state'
import Configuration from '../../config/Configuration'
import Info from '../Info'
import PresenterComponent from '../common/PresenterComponent'
import Checkbox from '../form/Checkbox'
import OptionsPane from '../layout/OptionsPane'
import Tabs from '../layout/Tabs'
import DiskUsage from '../table/DiskUsage'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import TD from '../table/TD'
import TH from '../table/TH'
import Table from '../table/Table'

export interface ChainDataLatestProps {
  regions: string[]
}

export default class ChainDataLatest extends PresenterComponent<ChainDataLatestProps, LastestChainDataState, LastestChainDataPresenter> {
  constructor(props: ChainDataLatestProps) {
    const presenter = new LastestChainDataPresenter(Configuration.usecases.getLatestActiveChainsData())
    super(props, presenter)
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    const hash = window.location.hash.replace('#', '')
    this.presenter.init(this.props.regions, hash ?? this.props.regions[0])
  }

  public render() {
    return (
      <>
        {this.renderOptions()}
        {this.renderTabs()}
        {this.renderTable()}
      </>
    )
  }

  protected renderOptions() {
    return <OptionsPane title='Snapshots'>
      <Checkbox
        id="display-inactive-checkbox"
        onChange={this.toggleDisplayInactiveChains.bind(this)}
        initialState={this.state.displayInactiveChains}
      />
      <label htmlFor="display-inactive-checkbox">Display Inactive Chains</label>
    </OptionsPane>
  }

  protected toggleDisplayInactiveChains() {
    this.presenter.setDisplayInactiveChains(!this.state.displayInactiveChains)
  }

  private renderTabs() {
    return <Tabs tabs={this.state.regions.map(region => ({
      ...region,
      onClick: () => { this.presenter.selectRegion(region.key) }
    }))} />
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <LoadingPlaceholder columns={8}></LoadingPlaceholder>
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH>Chain</TH>
          <TH.Center>Last Time of Snapshot</TH.Center>
          <TH.Center>External Block</TH.Center>
          <TH.Center>Connect Block</TH.Center>
          <TH.Center>Snapshot Block</TH.Center>
          <TH.Center>Running Block</TH.Center>
          <TH.Center>Snapshot Instance Used Space</TH.Center>
          <TH.Center>Snapshot Instance ID, Availability Zone</TH.Center>
        </tr>
      </thead>
      <tbody>

        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map((value) => (
      <tr key={value.instanceId}>
        <TD.Bold>{value.chain}</TD.Bold>
        <TD.Center>{value.time}</TD.Center>
        <TD.Center>{value.externalBlock}</TD.Center>
        <TD.Center>{value.connectBlock}</TD.Center>
        <TD.Center>
          {value.snapshotBlock}
          {value.snapshotBlockInfo ? <Info message={value.snapshotBlockInfo}></Info> : ''}
        </TD.Center>
        <TD.Center>{value.runningBlock}</TD.Center>
        <DiskUsage>{value.usedSpace}</DiskUsage>
        <TD.Center>{value.instanceId}, {value.region}</TD.Center>
      </tr>
    ))
  }
}
