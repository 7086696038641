import * as React from 'react'
import HealthStatus from '../../status/HealthStatus'
import TD from '../TD'

export interface IHealthStatusCellProps {
  value?: string
  children?:
  | React.ReactNode
  | React.ReactNode[]
}
export default class TDHealthStatus extends React.Component<IHealthStatusCellProps> {
  public render() {
    return (
      <TD.Center>
        <HealthStatus value={this.props.value}>
          {this.props.children}
        </HealthStatus>
      </TD.Center>
    )
  }
}
