import Presenter from '../../common/presenter/Presenter'
import ChainVersions from '../domain/ChainVersions.model'
import { VersionsServiceProvider } from '../domain/Versions.service'
import GetChainVersionsByLocation from '../usecase/GetChainVersionsByLocation.usecase'
import VersionsState from './Versions.state'

const initialState: VersionsState = {
  data: [],
  spots: ['util.js', 'docker', 'github'],
  chains: [],
  displayData: false,
  displayLoadingMessage: false,
  displayError: false,
  displayInactiveChains: false
}

export default class VersionsPresenter extends Presenter<VersionsState> {
  constructor(protected services: VersionsServiceProvider) {
    super({
      ...initialState
    })
  }

  async init(): Promise<void> {
    this.loadData()
  }

  protected async loadData(): Promise<void> {
    this.changeState({ displayLoadingMessage: true, displayData: false })
    const data = await new GetChainVersionsByLocation(this.services).execute(this.state.displayInactiveChains)
    const regions = this.extractRegionsFromData(data)
    const chains = this.extractChainsFromData(data)
    this.changeState({
      data,
      spots: regions,
      chains,
      displayLoadingMessage: false,
      displayData: true
    })
  }

  protected extractRegionsFromData(data: ChainVersions[]) {
    return Array.from(new Set(data.map(e => Object.keys(e.versions)).flat()))
  }

  protected extractChainsFromData(data: ChainVersions[]) {
    return Array.from(new Set(data.map(e => e.chain).flat()))
  }

  async setDisplayInactiveChains(displayInactiveChains: boolean): Promise<void> {
    this.changeState({ displayInactiveChains })
    this.loadData()
  }
}
