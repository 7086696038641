import ServerOverview from '../../overview/domain/Server.model'
import OrchidStatus from '../domain/OrchidStatus.model'

export default class BuildOrchidOverview {
  constructor() {
    //
  }

  execute(logs: OrchidStatus[]): ServerOverview {
    const url = 'orchid'
    if (logs.length === 0) {
      return {
        label: 'No data',
        status: 'notok',
        url
      }
    }

    if (logs[0].error) {
      return {
        label: 'Error',
        status: 'notok',
        url
      }
    }

    if (!logs[0].status) {
      return {
        label: 'No data',
        status: 'notok',
        url
      }
    }

    return {
      label: 'ok',
      status: 'ok',
      url
    }
  }
}
