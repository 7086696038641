import { Chart, Plugin } from 'chart.js'

export default class HorizonalLinePlugin implements Plugin<'line'> {
  id = 'horizontalLinePlugin'

  constructor(protected options: HorizonalLinePluginOptions) {
    //
  }

  beforeDraw(chart: Chart<'line'>) {
    if (this.options.value > chart.scales.y.max) {
      return
    }

    const yValue = this.options.value
    const ctx = chart.ctx
    const yScale = chart.scales.y
    const xScale = chart.scales.x
    const x1 = xScale.left
    const x2 = xScale.right

    // Convert y-value to pixel coordinate
    const y1 = yScale.getPixelForValue(yValue)

    // Draw the horizontal line
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y1)
    ctx.lineWidth = 1
    ctx.setLineDash([8, 3])
    ctx.strokeStyle = '#609966'
    ctx.stroke()
  }
}

export interface HorizonalLinePluginOptions {
  value: number
}
