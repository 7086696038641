import { NodeSyncTimeRepositoryProvider } from '../domain/NodeSyncTime.repository'

export default class GetSyncTimes {
  constructor(protected services: NodeSyncTimeRepositoryProvider) {
    //
  }

  execute() {
    return this.services.nodeSyncTimeRepository.getNodesSyncTimes()
  }
}
