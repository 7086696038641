import DateHelper from '../../../helper/date/DateHelper'
import ClusterNodeReport from '../domain/ClusterNodeReport.model'
import ClusterNodeReportDigest from '../domain/ClusterNodeReportDigest.model'
import ClusterReport from '../domain/ClusterReport.model'
import ClusterReportDigest from '../domain/ClusterReportDigest.model'
import RegionClustersReport from '../domain/RegionClustersReport.model'
import RegionClustersReportDigest from '../domain/RegionClustersReportDigest.model'

export default class BuildRegionClustersReportDigest {
  execute(report: RegionClustersReport): RegionClustersReportDigest {
    return {
      clusters: this.buildClustersReportsDigest(report.clusters)
    }
  }

  protected buildClustersReportsDigest(clustersReports: ClusterReport[]): ClusterReportDigest[] {
    if (!clustersReports) {
      return []
    }
    return clustersReports.map(r => this.buildClusterReportDigest(r))
  }

  protected buildClusterReportDigest(clusterReport: ClusterReport): ClusterReportDigest {
    return {
      name: clusterReport.name,
      nodes: this.buildNodesReportsDigest(clusterReport.nodes)
    }
  }

  protected buildNodesReportsDigest(nodesReports: ClusterNodeReport[]): ClusterNodeReportDigest[] {
    return nodesReports.map(r => this.buildNodeReportDigest(r))
  }

  protected buildNodeReportDigest(nodeReport: ClusterNodeReport): ClusterNodeReportDigest {
    return {
      ip: nodeReport.ip,
      chain: nodeReport.chain || '',
      chainError: nodeReport.chainError,
      diskUsage: this.formatDiskUsage(nodeReport.diskUsage),
      diskUsageError: nodeReport.diskUsageError,
      lag: this.formatLag(nodeReport.lag),
      lagError: nodeReport.lagError,
      health: nodeReport.healthError ? '' : nodeReport.health ? 'healthy' : 'unhealthy',
      healthError: nodeReport.healthError,
      time: DateHelper.format(nodeReport.time)
    }
  }

  protected formatDiskUsage(diskUsage?: number): string {
    if (!diskUsage) {
      return ''
    }

    return `${diskUsage.toFixed(2)} %`
  }

  protected formatLag(lag?: number): string {
    if (!lag) {
      return ''
    }

    return lag.toFixed(0).toString()
  }
}
