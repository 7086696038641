import React from 'react'
import Status from '../status/Status'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export interface IServerProps {
  label: string
  status: string
  message?: string
  url?: string
}

const Container = styled.div`
  display: inline-flex;
  padding: 0em 0em 0em 0em;
  
  > a {
    color: #000;
  }
`

export default class ServerOverview extends React.Component<IServerProps> {
  public render() {
    return (
      <Container>
        <Link to={this.props.url ?? ''}>
          <Status
            color={this.color}
            label={this.label}
            hoverMessage={this.props.message}
            blink={this.isUnhealthy()}
          ></Status>
        </Link>
      </Container>
    )
  }

  get color(): string {
    if (this.props.status === 'inactive') {
      return 'gray'
    }

    if (this.isHealthy()) {
      return 'green'
    }

    return 'red'
  }

  isHealthy() {
    if (this.props.status === 'ok') {
      return true
    }

    return false
  }

  isUnhealthy() {
    if (this.props.status === 'notok') {
      return true
    }

    return false
  }

  get label(): string {
    return this.props.label
  }
}
