import { Chart, Plugin } from 'chart.js'

export default class VerticalTextPlugin implements Plugin<'line'> {
  id = 'verticalTextPlugin'

  constructor(protected options: PluginOptions) {
    //
  }

  beforeDraw(chart: Chart<'line'>) {
    const xValue = this.options.x || 100
    const ctx = chart.ctx
    const yScale = chart.scales.y
    const xScale = chart.scales.x
    const x1 = xScale.getPixelForValue(xValue)
    const y2 = yScale.bottom

    this.drawVerticalText(ctx, this.options.text, x1, y2, -Math.PI / 2)
  }

  drawVerticalText(ctx: CanvasRenderingContext2D, text: string, x: number, y: number, angle: number) {
    ctx.save()
    ctx.translate(x, y)
    ctx.rotate(angle)
    ctx.fillStyle = 'red'
    ctx.textAlign = 'left'
    ctx.fillText(text, 0, 0)
    ctx.restore()
  }
}

export interface PluginOptions {
  x: number
  text: string
}
