import Presenter from '../../common/presenter/Presenter'
import { NumberServiceProvider } from '../../number/core/Number.service'
import { VSysSupernodeServiceProvider } from '../domain/VSysSupernode.service'
import VSysSupernodeLogDigest from '../domain/VSysSupernodeLogDigest.model'
import VSysSupernodeStatus from '../domain/VSysSupernodeStatus.model'
import VSysSupernodeStatusDigest from '../domain/VSysSupernodeStatusDigest.model'
import BuildVSysSupernodeLogDigest from '../usecase/BuildVSysSupernodeLogDigest.usecase'
import BuildVSysSupernodeStatusDigest from '../usecase/BuildVSysSupernodeStatusDigest.usecase'
import GetVSysSupernodeLog from '../usecase/GetVSysSupernodeLog.usecase'
import GetVSysSupernodeStatus from '../usecase/GetVSysSupernodeStatus.usecase'
import VSysSupernodeState from './VSysSupernode.state'

const initialState: VSysSupernodeState = {
  displayData: false,
  displayLoadingMessage: false,
  displayLoadMoreButton: true,
  data: [],
  status: {
    timestamp: 0,
    url: '',
    address: '',
    latestSyncTimestamp: 0,
    successAccumulation: {
      amountSum: 0,
      count: 0,
      feeSum: 0,
      timestamp: 0
    },
    genBlockDelay: '',
    missBlockRate: '',
    avgTxPerBlock: '',
    avgBlockSize: ''
  },
  pageNumber: 1,
  pageSize: 100
}

export default class VSysSupernodePresenter extends Presenter<VSysSupernodeState> {
  constructor(protected services: VSysSupernodeServiceProvider & NumberServiceProvider) {
    super(initialState)
  }

  async init() {
    this.loadServersData()
  }

  getWarning(status: VSysSupernodeStatus) {
    const lastSync = status.latestSyncTimestamp / 1000000
    const diff = Date.now() - lastSync
    const twoMinutes = 2 * 60 * 1000

    if (diff > twoMinutes) {
      return 'Last transaction delayed'
    }

    return ''
  }

  async loadServersData() {
    const status: VSysSupernodeStatus = await new GetVSysSupernodeStatus(this.services).execute()
    const statusDiggested: VSysSupernodeStatusDigest = new BuildVSysSupernodeStatusDigest().execute(status)

    const log = await new GetVSysSupernodeLog(this.services).execute(this.state.pageNumber, this.state.pageSize)
    const logDiggested: VSysSupernodeLogDigest[] = new BuildVSysSupernodeLogDigest(this.services).execute(log)

    this.changeState({
      status: statusDiggested,
      data: logDiggested,
      displayData: true,
      displayLoadingMessage: false,
      warning: this.getWarning(status),
      displayLoadMoreButton: logDiggested.length >= this.state.pageSize
    })
  }

  async loadMoreData() {
    this.changeState({
      displayLoadingMessage: true
    })
    const log = await new GetVSysSupernodeLog(this.services).execute(this.state.pageNumber + 1, this.state.pageSize)
    const logDiggested: VSysSupernodeLogDigest[] = new BuildVSysSupernodeLogDigest(this.services).execute(log)
    this.state.data.push(...logDiggested)
    this.changeState({
      data: this.state.data,
      pageNumber: this.state.pageNumber + 1,
      displayLoadMoreButton: logDiggested.length >= this.state.pageSize,
      displayLoadingMessage: false
    })
  }
}
