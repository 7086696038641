import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './react/app/App'
import reportWebVitals from './reportWebVitals'

import 'bootstrap/dist/css/bootstrap.min.css'
import Configuration from './react/config/Configuration'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <App googleClientId="939265468530-1r58bh4v5e1j7mldii7qts1s5e81a1gv.apps.googleusercontent.com" routes={Configuration.routes()} menuItems={Configuration.menuItems} brandName={Configuration.services.messageService.get('brandName')} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
