import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import styled from 'styled-components'
import LoginPresenter from '../../../core/login/presenter/Login.presenter'
import LoginState from '../../../core/login/presenter/Login.state'
import Configuration from '../../config/Configuration'
import PresenterComponent from '../common/PresenterComponent'

const Panel = styled.div`
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100vh;
  padding: auto;
  
  .card {
    width: 17rem;
    margin: auto;
  }
`

export interface LoginProps {
  //
}

export default class OAuthLogin extends PresenterComponent<LoginProps, LoginState, LoginPresenter> {
  constructor(props: LoginProps) {
    super(props, new LoginPresenter(Configuration.services))
    this.presenter.init()
  }

  public render() {
    return (
      <Panel style={{ display: 'flex' }}>
        <div className='card'>
          <div className='card-header'>
            Bloq Nodes Dashboard
          </div>
          <div className='card-body'>
            <p className='card-text'>You need to login in your bloq account in order to have access</p>

            <GoogleLogin
              onSuccess={credentialResponse => this.handleSuccess(credentialResponse)}
              onError={() => this.handleError()}
              useOneTap
              auto_select
              text='signin_with'
              cancel_on_tap_outside={false}
            />
          </div>
        </div >
      </Panel>
    )
  }

  protected handleSuccess(credentialResponse: CredentialResponse) {
    this.presenter.handeLogIn({
      clientId: credentialResponse.clientId,
      credential: credentialResponse.credential
    })
  }

  protected handleError() {
    throw Error('Login Failed')
  }
}
