import * as React from 'react'
import TDStatus from './TDStatus'

export interface IBeaconchainStatusCellProps {
  value?: string
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

export default class TDBeaconchainStatus extends React.Component<IBeaconchainStatusCellProps> {
  public render() {
    return (
      <TDStatus color={this.color} label={this.label} displayTag={!!this.props.value}>
        {this.props.children}
      </TDStatus>
    )
  }

  get color(): string {
    if (this.props.value === 'exited') {
      return 'gray'
    }
    if (this.props.value === 'pending') {
      return 'orange'
    }
    if (this.props.value === 'slashing_offline') {
      return 'red'
    }
    if (this.props.value === 'slashing_online') {
      return 'green'
    }
    if (this.props.value === 'active_offline') {
      return 'red'
    }
    if (this.props.value === 'active_online') {
      return 'green'
    }

    return 'black'
  }

  get label(): string {
    if (this.props.value === 'exited') {
      return 'Exited'
    }
    if (this.props.value === 'pending') {
      return 'Pending'
    }
    if (this.props.value === 'slashing_offline') {
      return 'Slashing Offline'
    }
    if (this.props.value === 'slashing_online') {
      return 'Slashing Online'
    }
    if (this.props.value === 'active_offline') {
      return 'Active Offline'
    }
    if (this.props.value === 'active_online') {
      return 'Active Online'
    }
    return this.props.value || ''
  }

  get blink(): boolean {
    if (this.props.value?.startsWith('slashing')) {
      return true
    }

    return false
  }
}
