import ServerOverview, { ServerStatus } from '../../overview/domain/Server.model'
import VSysServerStatus from '../domain/VSysServerStatus.model'

export default class BuildVSysOverview {
  constructor(protected diskUsageLimit: number) {
    //
  }

  execute(serversStatus: VSysServerStatus[]): ServerOverview[] {
    return serversStatus.map(e => ({
      label: e.server.name,
      status: e.healthy ? 'ok' : 'notok',
      url: 'vsys-supernode'
    }))
  }

  getStatus(serversStatus: VSysServerStatus): ServerStatus {
    if (!serversStatus.healthy) {
      return 'notok'
    }

    if (serversStatus.diskUsage && serversStatus.diskUsage?.root > this.diskUsageLimit) {
      return 'notok'
    }

    return 'ok'
  }
}
