import { Component } from 'react'
import Presenter from '../../../core/common/presenter/Presenter'

export default class PresenterComponent<
  PropertiesClass,
  StateClass,
  PresenterClass extends Presenter<StateClass>
> extends Component<PropertiesClass, StateClass> {
  constructor(
    props: PropertiesClass,
    protected presenter: PresenterClass
  ) {
    super(props)
    this.state = presenter.state
  }

  async componentDidMount() {
    this.presenter.subscribe(this.loadPresenterState)
  }

  async componentWillUnmount() {
    this.presenter.unsubscribe(this.loadPresenterState)
  }

  loadPresenterState = (state: StateClass) => {
    this.setState(state)
  }
}
