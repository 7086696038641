import APIAdapter from '../../common/adapter/APIAdapter'
import VSysServerService from '../domain/VSysServer.service'
import VSysServerStatus from '../domain/VSysServerStatus.model'

export default class VSysServerAPI extends APIAdapter implements VSysServerService {
  async getAll(): Promise<VSysServerStatus[]> {
    const response = await this.get<{
      success: boolean,
      data: VSysServerStatus[]
    }>('/vsys-server-status')
    return response.data
  }
}
