import { googleLogout } from '@react-oauth/google'
import Credential from '../domain/Credential.model'
import LoginService from '../domain/Login.service'
import CredentialHolder from '../domain/CredentialHolder.service'

export default class GoogleLogin implements LoginService {
  constructor(protected credentialHolder: CredentialHolder) {
    //
  }

  setCredential(credential: Credential): void {
    this.credentialHolder.setCredential(credential)
  }

  isLoggedIn(): boolean {
    return this.getCredential() !== undefined
  }

  logout(): void {
    googleLogout()
    this.credentialHolder.setCredential(undefined)
  }

  getCredential(): Credential | undefined {
    return this.credentialHolder.getCredential()
  }
}
