import * as React from 'react'

export interface CopyToClipboardButtonProps {
  text: string
}

export default class CopyToClipboardButton extends React.Component<CopyToClipboardButtonProps> {
  public render() {
    if (!this.props.text) {
      return ''
    }

    return (
      <span
        onClick={this.copyToClipboard}
        style={{
          padding: '1em',
          cursor: 'pointer'
        }}
      >
        ⎘
      </span>
    )
  }

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.props.text)
  }
}
