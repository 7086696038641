import { OrchidServiceProvider } from '../domain/Orchid.service'
import OrchidStatus from '../domain/OrchidStatus.model'

export default class GetOrchidStatus {
  constructor(protected services: OrchidServiceProvider) {
    //
  }

  async execute(pageNumber = 1, pageSize = 20): Promise<OrchidStatus[]> {
    return this.services.orchidService.getStatus(pageNumber, pageSize)
  }
}
