import Presenter from '../../common/presenter/Presenter'
import { StakingNodeDigest } from '../domain/StakingNodeDigest'
import StakingNodeTimeline from '../domain/StakingNodeTimeline.model'
import { StakingNodeTimelineServiceProvider } from '../domain/StakingNodeTimeline.service'
import BuildStakingNodeDigest from '../usecase/BuildStakingNodeDigest'
import GetStakingNodesTimelines from '../usecase/GetStakingNodesTimelines'
import StakingNodesState from './StakingNodes.state'

const SIX_HOURS = 1000 * 60 * 60 * 6

const initialState: StakingNodesState = {
  displayData: false,
  displayLoadingMessage: false,
  displayAccountSelector: false,
  displayRegionSelector: false,
  selectedAccount: '',
  selectedRegion: '',
  accounts: [],
  regions: [],
  nodes: [],
  initialTimestamp: Date.now() - SIX_HOURS,
  finalTimestamp: Date.now(),
  initialTimestampLabel: 'From:',
  finalTimestampLabel: 'To:',
  logarithmicScale: true
}

export default class StakingNodesPresenter extends Presenter<StakingNodesState> {
  protected accounts: string[] = []
  protected regions: string[] = []
  protected selectedAccount: string | undefined
  protected selectedRegion: string | undefined
  protected timelines: StakingNodeTimeline[] | undefined

  constructor(
    protected services: StakingNodeTimelineServiceProvider
  ) {
    super(initialState)
  }

  async init(): Promise<void> {
    await this.refreshStateData()
  }

  async selectAccountAndRegion(selectedAccount: string, selectedRegion: string): Promise<void> {
    this.selectedAccount = selectedAccount
    this.selectedRegion = selectedRegion
    this.refreshStateData()
  }

  async selectAccount(selectedAccount: string): Promise<void> {
    this.selectedAccount = selectedAccount
    this.clearRegionSelection()
    this.refreshStateData()
  }

  clearRegionSelection(): void {
    this.selectedRegion = undefined
  }

  async selectRegion(selectedRegion: string): Promise<void> {
    this.selectedRegion = selectedRegion
    this.refreshStateData()
  }

  async setPeriod(initialTimestamp: number, finalTimestamp: number): Promise<void> {
    this.state.initialTimestamp = initialTimestamp
    this.state.finalTimestamp = finalTimestamp
    this.timelines = undefined

    if (initialTimestamp > finalTimestamp) {
      this.displayInvalidPeriodErrorMessage()
      return
    }

    this.refreshStateData()
  }

  protected async loadDataFromServer(): Promise<void> {
    this.timelines = await (new GetStakingNodesTimelines(this.services))
      .execute([], this.state.initialTimestamp, this.state.finalTimestamp)
  }

  protected async refreshStateData(): Promise<void> {
    this.displayLoadingMessage()

    if (!this.timelines) {
      try {
        await this.loadDataFromServer()
      } catch (e) {
        this.displayLoadingErrorMessage()
        return
      }
    }

    this.refreshAccounts()
    this.refreshRegions()
    this.displaySelectedRegion()
  }

  protected displaySelectedRegion() {
    if (!this.timelines || !this.selectedAccount || !this.selectedRegion) {
      this.changeState({
        displayData: false,
        displayLoadingMessage: false,
        noDataMessage: 'No data available.',
        nodes: []
      })
      return
    }

    const regionTimelines = this.filterTimelinesByAccountAndRegion(this.timelines, this.selectedAccount, this.selectedRegion)
    this.changeState({
      displayData: true,
      displayLoadingMessage: false,
      noDataMessage: undefined,
      nodes: this.buildNodesDigest(regionTimelines)
    })
  }

  filterTimelinesByAccountAndRegion(timelines: StakingNodeTimeline[], account: string, region: string): StakingNodeTimeline[] {
    return timelines.filter(t => t.node.account === account && t.node.region === region)
  }

  protected displayLoadingMessage() {
    this.changeState({ displayLoadingMessage: true, displayData: false, errorMessage: undefined })
  }

  protected displayLoadingErrorMessage() {
    this.changeState({
      errorMessage: 'Failed to load data',
      displayLoadingMessage: false,
      noDataMessage: 'No data available.'
    })
  }

  protected displayInvalidPeriodErrorMessage() {
    this.changeState({
      errorMessage: 'Invalid period',
      displayLoadingMessage: false,
      displayData: false,
      noDataMessage: 'No data available.'
    })
  }

  protected refreshAccounts() {
    if (!this.timelines) {
      throw Error('Clusters report not loaded')
    }

    this.accounts = Array.from(new Set(this.timelines.map(t => t.node.account)))

    if (!this.accounts.length) {
      this.selectedAccount = undefined
    }

    if (!this.selectedAccount) {
      this.selectedAccount = this.accounts[0]
    }

    this.changeState({
      accounts: this.buildAccounts(this.selectedAccount),
      selectedAccount: this.selectedAccount,
      displayAccountSelector: this.accounts.length > 1
    })
  }

  protected refreshRegions() {
    if (!this.timelines) {
      throw Error('Clusters report not loaded')
    }

    if (!this.selectedAccount) {
      this.changeState({
        regions: [],
        selectedRegion: '',
        displayRegionSelector: false
      })
      return
    }

    this.regions = Array.from(new Set(this.timelines.filter(t => t.node.account === this.selectedAccount).map(t => t.node.region)))

    if (!this.regions.length) {
      this.selectedRegion = undefined
    }

    if (!this.selectedRegion) {
      this.selectedRegion = this.regions[0]
    }

    this.changeState({
      regions: this.buildRegions(this.selectedRegion),
      selectedRegion: this.selectedRegion,
      displayRegionSelector: this.regions.length > 1
    })
  }

  protected buildAccounts(selectedAccount: string) {
    return this.accounts.map(key => ({
      key,
      active: selectedAccount === key
    }))
  }

  protected buildRegions(selectedRegion: string) {
    return this.regions.map(key => ({
      key,
      active: selectedRegion === key
    }))
  }

  private buildNodesDigest(nodeTimelines: StakingNodeTimeline[]): StakingNodeDigest[] {
    const digester = new BuildStakingNodeDigest()
    return nodeTimelines.map(digester.execute.bind(digester))
  }

  toggleLogarithmicScale() {
    this.changeState({ logarithmicScale: !this.state.logarithmicScale })
  }
}
