import Event from '../../common/event/Event'

export default class LoginErrorEvent extends Event {
  static key = Symbol('LoginError')

  constructor(protected givenError: Error) {
    super(LoginErrorEvent.key)
  }

  get error() {
    return this.givenError
  }
}
