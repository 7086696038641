import Ether from './Ether.model'

export const ERROR_INVALID_AMOUNT = 'Invalid amount.'

export default class FormattedEther implements Ether {
  protected _amount: number

  constructor(amount: string | number) {
    if (typeof amount === 'string') {
      if (!FormattedEther.validateAmount(amount)) {
        throw new Error(ERROR_INVALID_AMOUNT)
      }
      this._amount = parseFloat(amount)
      return
    }

    this._amount = amount
  }

  get amount() {
    return this._amount
  }

  static validateAmount(amount: string) {
    return (!Number.isNaN(parseFloat(amount)))
  }

  /**
   * Returns a string representing an eth amount formatted in fixed-point notation.
   *
   * @param {number} fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
   * @returns `string` with the amount formatted in fixed-point notation with ETH postfix.
   */
  getFormattedAmount(fractionDigits = 4) {
    const formattedDecimal = this.amount.toFixed(fractionDigits).toString()
    return `${formattedDecimal} ETH`
  }

  toString() {
    return this.getFormattedAmount()
  }
}
