import ChainData, { ChainDataResult } from '../domain/ChainData.entity'
import ChainDataMethodFilter from '../domain/ChainDataMethodFilter.model'
import ChainDataFilter from '../domain/ChainDataFilter'

export default class FilterChainData {
  execute<T extends ChainDataResult = ChainDataResult>(chainsData: ChainData[], options: ChainDataFilter): ChainData<T>[] {
    return this.filterData(chainsData, options) as unknown as ChainData<T>[]
  }

  protected filterData(chainsData: ChainData[], options: ChainDataFilter): ChainData[] {
    let data = this.filterDataBySearchTerm(chainsData, options.searchTerm)
    data = this.filterDataByType(data, options.methodFilter)
    data = this.filterDataByRegion(data, options.region)
    return data
  }

  protected filterDataByType(data: ChainData[], methodFilter?: ChainDataMethodFilter): ChainData[] {
    if (!methodFilter ||
      (!methodFilter.connectBlock &&
        !methodFilter.externalBlock &&
        !methodFilter.snapshot &&
        !methodFilter.versions
      )
    ) {
      return data
    }

    return data.filter(e => {
      return (methodFilter.connectBlock && e.method === 'connect-block') ||
        (methodFilter.externalBlock && e.method === 'external-block') ||
        (methodFilter.snapshot && e.method === 'snapshot') ||
        (methodFilter.versions && e.method === 'versions')
    })
  }

  protected filterDataBySearchTerm(data: ChainData[], searchTerm?: string): ChainData[] {
    if (!searchTerm) {
      return data
    }

    return data.filter(e => {
      return e.chain.indexOf(searchTerm) >= 0
    })
  }

  protected filterDataByRegion(chaindata: ChainData[], region?: string): ChainData[] {
    if (!region) {
      return chaindata
    }

    return chaindata.filter(chainData => chainData.region?.startsWith(region))
  }
}
