import * as React from 'react'
import Status from './Status'

export interface IHealthStatusCellProps {
  className?: string
  style?: React.CSSProperties
  value?: string
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

const HEALTHY_VALUES = ['healthy', 'ok', 'true']

export default class HealthStatus extends React.Component<IHealthStatusCellProps> {
  static defaultProps = {
    className: '',
    style: {}
  }

  public render() {
    return (
      <Status
        color={this.color}
        label={this.label}
        displayTag={this.displayTag}
        style={this.props.style}
        className={this.props.className}
      >
        {this.props.children}
      </Status>
    )
  }

  get color(): string {
    if (this.isHealthy()) {
      return 'green'
    }

    return 'red'
  }

  get label(): string {
    if (this.isHealthy()) {
      return 'Healthy'
    }

    return 'Unhealthy'
  }

  get displayTag(): boolean {
    return !!this.props.value
  }

  isHealthy() {
    if (HEALTHY_VALUES.indexOf(this.props.value || '') >= 0) {
      return true
    }

    return false
  }
}
