import AlgorandPresenter from '../../../core/algorand/presenter/Algorand.presenter'
import AlgorandState from '../../../core/algorand/presenter/Algorand.state'
import PresenterComponent from '../common/PresenterComponent'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import DiskUsage from '../table/DiskUsage'
import Table from '../table/Table'
import TD from '../table/TD'
import TH from '../table/TH'
import Error from '../Error'
import TDHealthStatus from '../table/status/TDHealthStatus'
import TDOnlineStatus from '../table/status/TDOnlineStatus'

export interface AlgorandStakingStatusProps {
  title?: string
  presenter: AlgorandPresenter
}

export default class AlgorandStakingStatus extends PresenterComponent<AlgorandStakingStatusProps, AlgorandState, AlgorandPresenter> {
  constructor(props: AlgorandStakingStatusProps) {
    super(props, props.presenter)
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  render() {
    return <>
      <h1>{this.props.title || 'Algorand Staking Status'}</h1>
      {this.renderTable()}
    </>
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH>Name</TH>
          <TH.Center>IP</TH.Center>
          <TH.Center>Account</TH.Center>
          <TH.Center>Lag</TH.Center>
          <TH.Center>Health</TH.Center>
          <TH.Center>Disk Usage</TH.Center>
          <TH.Center>Participation</TH.Center>
          <TH.Center>Valid Vote</TH.Center>
          <TH.Center>Time</TH.Center>
        </tr>
      </thead>
      <tbody>
        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <LoadingPlaceholder columns={9}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map(
      e => (
        <tr key={e.serverName}>
          <TD.Bold>{e.serverName}</TD.Bold>
          <TD.Center>{e.serverIp}</TD.Center>
          <TD.Center>
            <a href={e.accountURL}>
              {e.account}
            </a>
          </TD.Center>
          <TD.Center>{e.lag} <Error message={e.lagError} /> </TD.Center>
          <TDHealthStatus value={e.healthcheck}><Error message={e.healthcheckError} /> </TDHealthStatus>
          <DiskUsage>{e.diskUsage} <Error message={e.diskUsageError} /> </DiskUsage>
          <TDOnlineStatus value={e.participationOnline}><Error message={e.participationOnlineError} /> </TDOnlineStatus>
          <TDHealthStatus value={e.voteValid}><Error message={e.voteValidError} /> </TDHealthStatus>
          <TD.Center>{e.time}</TD.Center>
        </tr>
      )
    )
  }
}
