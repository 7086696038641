import * as React from 'react'
import styled from 'styled-components'

const StyledOptionsPane = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;

  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 10px 0;

  h1 {
    font-size: 1.3em;
    font-weight: bold;
    margin: auto auto auto 0;
  }

  label {
    margin-right: 10px;
  }
  
  input {
    margin-right: 10px;
    padding: 0 0 0 0.5em;
  }

  select {
    margin-right: 10px;
    padding: 0 0.5em;
  }

  a {
    margin: auto 10px auto 0;
    text-decoration: underline !important;
    font-weight: bold;
    font-size: .8em;
    line-height: .8em;
    cursor: pointer;
    color: #333 !important;

    &:hover {
      text-decoration: underline;
    }
  }
`

export interface OptionsPaneProps {
  title: string,
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

export default class OptionsPane extends React.Component<OptionsPaneProps> {
  public render() {
    return (
      <StyledOptionsPane>
        <h1>{this.props.title}</h1>
        {this.props.children}
      </StyledOptionsPane>
    )
  }
}
