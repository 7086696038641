import APIAdapter from '../../common/adapter/APIAdapter'
import VSysSupernodeService from '../domain/VSysSupernode.service'
import VSysSupernodeLog from '../domain/VSysSupernodeLog.model'
import VSysSupernodeStatus from '../domain/VSysSupernodeStatus.model'

export default class VSysSupernodeAPI extends APIAdapter implements VSysSupernodeService {
  async getLog(pageNumber: number, pageSize: number): Promise<VSysSupernodeLog[]> {
    const response = await this.get<{
      success: boolean,
      data: VSysSupernodeLog[],
    }>('/vsys-supernode-log', {
      pageNumber,
      pageSize
    })
    return response.data
  }

  async getStatus(): Promise<VSysSupernodeStatus> {
    const response = await this.get<{
      success: boolean,
      data: VSysSupernodeStatus
    }>('/vsys-supernode-status')
    return response.data
  }
}
