import Configuration from '../../config/Configuration'
import EthValidatorPresenter from '../../../core/ethereum-validator-status/presenter/EthValidator.presenter'
import EthValidatorPresenterState from '../../../core/ethereum-validator-status/presenter/EthValidator.state'
import PresenterComponent from '../common/PresenterComponent'
import EthValidator from './EthValidator'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import Table from '../table/Table'
import TH from '../table/TH'
import EthValidatorsTimelines from '../eth-validators-timelines/EthValidatorsTimelines'

export interface EthValidatorsProps {
}

export default class EthValidators extends PresenterComponent<EthValidatorsProps, EthValidatorPresenterState, EthValidatorPresenter> {
  constructor(props: EthValidatorsProps) {
    super(props, new EthValidatorPresenter(Configuration.usecases.getValidatorsReports()))
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  render() {
    return <>
      <h1>Ethereum Validators</h1>
      {this.renderTable()}
      <EthValidatorsTimelines></EthValidatorsTimelines>
    </>
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH.Center>Public Key</TH.Center>
          <TH.Center>Index</TH.Center>
          <TH.Center>Balance</TH.Center>
          <TH.Center>State</TH.Center>
          <TH.Center>Last Attestation Slot</TH.Center>
          <TH.Center>Attestation Effectiveness</TH.Center>
          <TH.Center>Income 7 days</TH.Center>
          <TH.Center>Rank 7 days</TH.Center>
        </tr>
      </thead>
      <tbody>

        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <LoadingPlaceholder columns={8}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.validatorsStatus.map(
      validatorStatus => (<EthValidator key={validatorStatus.index} validatorStatus={validatorStatus} />)
    )
  }
}
