import React, { ErrorInfo } from 'react'

interface ErrorBoundaryProps {
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

interface ErrorBoundaryState {
  hasError: boolean
  message?: string
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(givenError: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      message: givenError.message
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.debug(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{this.state.message}</h1>
    }

    return this.props.children
  }
}
