import Presenter from '../../common/presenter/Presenter'
import EthValidatorTimeline from '../domain/EthValidatorTimeline.model'
import { EthValidatorTimelineServiceProvider } from '../domain/EthValidatorTimeline.service'
import { EthValidatorTimelineDigest } from '../domain/EthValidatorTimelineDigest.model'
import BuildValidatorTimelineDigest from '../usecase/BuildValidatorTimelineDigest'
import GetValidatorsTimelines from '../usecase/GetValidatorsTimelines'
import EthValidatorTimelinesState from './EthValidatorTimeline.state'

const SIX_HOURS = 1000 * 60 * 60 * 6

const initialState: EthValidatorTimelinesState = {
  displayData: false,
  displayLoadingMessage: false,
  nodes: [],
  initialTimestamp: Date.now() - SIX_HOURS,
  finalTimestamp: Date.now(),
  initialTimestampLabel: 'From:',
  finalTimestampLabel: 'To:',
  logarithmicScale: false
}

export default class EthValidatorTimelinesPresenter extends Presenter<EthValidatorTimelinesState> {
  protected timelines: EthValidatorTimeline[] | undefined

  constructor(
    protected services: EthValidatorTimelineServiceProvider
  ) {
    super(initialState)
  }

  async init(): Promise<void> {
    await this.refreshStateData()
  }

  async selectAccountAndRegion(): Promise<void> {
    this.refreshStateData()
  }

  async setPeriod(initialTimestamp: number, finalTimestamp: number): Promise<void> {
    this.state.initialTimestamp = initialTimestamp
    this.state.finalTimestamp = finalTimestamp
    this.timelines = undefined

    if (initialTimestamp > finalTimestamp) {
      this.displayInvalidPeriodErrorMessage()
      return
    }

    this.refreshStateData()
  }

  protected async loadDataFromServer(): Promise<void> {
    const timelines = await (new GetValidatorsTimelines(this.services))
      .execute([], this.state.initialTimestamp, this.state.finalTimestamp)
    this.sortTimelinesByIndex(timelines)
    this.timelines = timelines
  }

  protected async refreshStateData(): Promise<void> {
    this.displayLoadingMessage()

    if (!this.timelines) {
      try {
        await this.loadDataFromServer()
      } catch (e) {
        this.displayLoadingErrorMessage()
        return
      }
    }

    this.displayData()
  }

  protected displayData() {
    if (!this.timelines) {
      this.changeState({
        displayData: false,
        displayLoadingMessage: false,
        noDataMessage: 'No data available.',
        nodes: []
      })
      return
    }

    this.changeState({
      displayData: true,
      displayLoadingMessage: false,
      noDataMessage: undefined,
      nodes: this.buildNodesDigest(this.timelines)
    })
  }

  protected displayLoadingMessage() {
    this.changeState({ displayLoadingMessage: true, displayData: false, errorMessage: undefined })
  }

  protected displayLoadingErrorMessage() {
    this.changeState({
      errorMessage: 'Failed to load data',
      displayLoadingMessage: false,
      noDataMessage: 'No data available.'
    })
  }

  protected displayInvalidPeriodErrorMessage() {
    this.changeState({
      errorMessage: 'Invalid period',
      displayLoadingMessage: false,
      displayData: false,
      noDataMessage: 'No data available.'
    })
  }

  private buildNodesDigest(nodeTimelines: EthValidatorTimeline[]): EthValidatorTimelineDigest[] {
    const digester = new BuildValidatorTimelineDigest()
    return nodeTimelines.map(digester.execute.bind(digester))
  }

  toggleLogarithmicScale() {
    this.changeState({ logarithmicScale: !this.state.logarithmicScale })
  }

  private sortTimelinesByIndex(timelines: EthValidatorTimeline[]): EthValidatorTimeline[] {
    return timelines.sort((a, b) => a.index - b.index)
  }
}
