import Presenter from '../../common/presenter/Presenter'
import ChainSyncTimeMetricDigest from '../domain/ChainSyncTimeMetricsDigest'
import { NodeSyncTimeMetricsRepositoryProvider } from '../domain/NodeSyncTimeMetrics.repository'
import BuildSyncTimeMetricsDigests from '../usecase/BuildSyncTimeMetricsDigests.usecase'
import GetSyncTimeMetrics from '../usecase/GetSyncTimeMetrics.usecase'
import ChainSyncTimeMetricsState from './ChainSyncTimeMetrics.state'

const initialState: ChainSyncTimeMetricsState = {
  displayData: false,
  displayLoading: true,
  data: [],
  searchTermText: ''
}

export default class ChainSyncTimeMetricsPresenter extends Presenter<ChainSyncTimeMetricsState> {
  protected allData?: ChainSyncTimeMetricDigest[]

  constructor(protected services: NodeSyncTimeMetricsRepositoryProvider) {
    super(initialState)
  }

  async init() {
    this.changeState({
      data: await this.buildData(this.state.searchTermText),
      displayData: true,
      displayLoading: false
    })
  }

  protected async buildData(searchTermText: string) {
    if (!this.allData) {
      const metrics = await new GetSyncTimeMetrics(this.services).execute()
      this.allData = await new BuildSyncTimeMetricsDigests().execute(metrics)
    }
    return this.filter(this.allData, searchTermText)
  }

  protected filter(data: ChainSyncTimeMetricDigest[], searchTermText: string) {
    if (!searchTermText) {
      return data
    }

    return data.filter(e => e.chain.indexOf(searchTermText) >= 0)
  }

  async setTextFilter(searchTermText: string) {
    if (searchTermText === this.state.searchTermText) {
      return
    }

    this.changeState({
      ...this.state,
      searchTermText,
      data: await this.buildData(searchTermText)
    })
  }
}
