import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Collapse = styled.div.attrs({
  className: 'collpase navbar-collapse'
})``

const List = styled.div.attrs({
  className: 'navbar-nav mr-auto'
})``

const Item = styled.div.attrs({
  className: 'collpase navbar-collapse'
})``

export interface ILinksProps {
  menuItems: {
    url: string
    label: string
  }[]

  brandName: string
}

export default class Links extends React.Component<ILinksProps> {
  public render() {
    return (
      <React.Fragment>
        <Link to="/" className="navbar-brand">
          {this.props.brandName}
        </Link>
        <Collapse>
          <List>
            <Item>
              {
                this.props.menuItems.map((menuItem, i) =>
                  (<Link key={`menu-item-${i}`} to={menuItem.url} className="nav-link">
                  {menuItem.label}
                </Link>)
                )
              }
            </Item>
          </List>
        </Collapse>
      </React.Fragment>
    )
  }
}
