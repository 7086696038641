import Configuration from '../../config/Configuration'
import PeriodMetricsDigest from '../../../core/chain-sync-time/domain/PeriodMetricsDigest'
import ChainSyncTimeMetricsPresenter from '../../../core/chain-sync-time/presenter/ChainSyncTimeMetrics.presenter'
import ChainSyncTimeMetricsState from '../../../core/chain-sync-time/presenter/ChainSyncTimeMetrics.state'
import PresenterComponent from '../common/PresenterComponent'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import Table from '../table/Table'
import TD from '../table/TD'
import TH from '../table/TH'

export interface NodesSyncTimeMetricsProps {
  textSearchTerm: string
}

export default class NodesSyncTimeMetrics extends PresenterComponent<NodesSyncTimeMetricsProps, ChainSyncTimeMetricsState, ChainSyncTimeMetricsPresenter> {
  constructor(props: NodesSyncTimeMetricsProps) {
    const presenter = new ChainSyncTimeMetricsPresenter(Configuration.services)
    super(props, presenter)
  }

  static defaultProps = {
    textSearchTerm: ''
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  componentDidUpdate() {
    this.presenter.setTextFilter(this.props.textSearchTerm)
  }

  render() {
    return (
      this.renderTable()
    )
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH>Chain</TH>
          <TH>Last Month</TH>
          <TH>Last Quarter</TH>
          <TH>Last Year</TH>
        </tr>
      </thead>
      <tbody>
        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoading) {
      return ''
    }

    return <LoadingPlaceholder columns={4}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map(
      (e, i) => (
        <tr key={`record-${i}`}>
          <TD.Bold>{e.chain}</TD.Bold>
          <TD>{this.renderPeriod(e.lastMonth)}</TD>
          <TD>{this.renderPeriod(e.lastQuarter)}</TD>
          <TD>{this.renderPeriod(e.lastYear)}</TD>
        </tr>
      )
    )
  }

  renderPeriod(period: PeriodMetricsDigest) {
    return <>
      <div>Avg Time to Sync: {period.avg}</div>
      <div>Max Time to Sync: {period.max}</div>
      <div>Min Time to Sync: {period.min}</div>
      <div>Avg Time to Catch Up (Per Block): {period.avgToCatchUp}</div>
    </>
  }
}
