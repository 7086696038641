import * as React from 'react'
import styled from 'styled-components'

export interface ITableProps {
  striped?: boolean
  hover?: boolean
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

const StyledTable = styled.table`
  border-width: 0px;
  thead { 
    background-color: #f0f8ff; 
    color: #000;
  }
  tr {
    border-width: 0px;
  }
  th {
    border: solid black 1px;
  }
  td {
    vertical-align: middle;
    border: solid black 1px;
  }
  tbody {
    th {
      padding-top: 1em;
      padding-left: 0em;
      padding-bottom: 0em;
      border-bottom-width: 2px;
      border-right-width: 0px;
      border-left-width: 0px;
      font-size: 1.1em;
    }
  }
`
export default class Table extends React.Component<ITableProps> {
  static defaultProps = {
    striped: false,
    hover: true
  }

  public render() {
    return (
      <StyledTable className={`table table-bordered ${this.props.hover ? 'table-hover' : ''} ${this.props.striped ? 'table-striped-columns' : ''}`} >
        {this.props.children}
      </StyledTable>
    )
  }
}
