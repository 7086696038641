import APIAdapter from '../../common/adapter/APIAdapter'
import SolanaService from '../domain/Solana.service'
import SolanaValidatorStatus from '../domain/SolanaValidatorStatus.model'

export default class SolanaAPI extends APIAdapter implements SolanaService {
  async getAll(): Promise<SolanaValidatorStatus[]> {
    const response = await this.get<{
      success: boolean,
      data: SolanaValidatorStatus[]
    }>('/solana-validator-status')
    return response.data
  }
}
