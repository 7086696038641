import APIAdapter from '../../common/adapter/APIAdapter'
import ChainDataModel, { ChainDataResult } from '../domain/ChainData.entity'
import ChainDataRepository from '../domain/ChainData.repository'

export default class ChainDataAPI extends APIAdapter implements ChainDataRepository {
  async getChainData(): Promise<ChainDataModel<ChainDataResult>[]> {
    const response = await this.post<{
      success: boolean,
      data: ChainDataModel<ChainDataResult>[]
    }>('/datawithparam')
    return response.data
  }

  async getLatestChainData(includeInactiveChains = false): Promise<ChainDataModel<ChainDataResult>[]> {
    const response = await this.get<{
      success: boolean,
      data: ChainDataModel<ChainDataResult>[]
    }>('/chain-data', { includeInactiveChains })
    return response.data
  }
}
