import Presenter from '../../common/presenter/Presenter'
import { OrchidServiceProvider } from '../domain/Orchid.service'
import OrchidStatusDigest from '../domain/OrchidStatusDigest.model'
import BuildOrchidStatusDigest from '../usecase/BuildOrchidStatusDigest.usecase'
import GetOrchidStatus from '../usecase/GetOrchidStatus.usecase'
import OrchidState from './Orchid.state'

const initialState: OrchidState = {
  displayData: false,
  displayLoadingMessage: false,
  displayLoadMoreButton: true,
  data: [],
  pageNumber: 1,
  pageSize: 100
}

export default class OrchidPresenter extends Presenter<OrchidState> {
  constructor(protected services: OrchidServiceProvider) {
    super(initialState)
  }

  async init() {
    this.loadServersData()
  }

  async loadServersData() {
    const status = await new GetOrchidStatus(this.services).execute(this.state.pageNumber, this.state.pageSize)
    const statusDiggested: OrchidStatusDigest[] = new BuildOrchidStatusDigest().execute(status)

    this.changeState({
      data: statusDiggested,
      displayData: true,
      displayLoadingMessage: false,
      displayLoadMoreButton: statusDiggested.length >= this.state.pageSize
    })
  }

  async loadMoreData() {
    this.changeState({
      displayLoadingMessage: true
    })
    const status = await new GetOrchidStatus(this.services).execute(this.state.pageNumber + 1, this.state.pageSize)
    const statusDiggested: OrchidStatusDigest[] = new BuildOrchidStatusDigest().execute(status)
    this.state.data.push(...statusDiggested)
    this.changeState({
      data: this.state.data,
      pageNumber: this.state.pageNumber + 1,
      displayLoadMoreButton: statusDiggested.length >= this.state.pageSize,
      displayLoadingMessage: false
    })
  }
}
