import DateHelper from '../../../helper/date/DateHelper'
import VSysServerStatus from '../domain/VSysServerStatus.model'
import VSysServerStatusDigest from '../domain/VSysServerStatusDigest.model'

export default class BuildVSysServerStatusDigest {
  execute(serversStatus: VSysServerStatus[]): VSysServerStatusDigest[] {
    return serversStatus.map(e => ({
      serverName: e.server.name,
      serverIp: e.server.ip,
      healthcheck: e.healthy.toString(),
      diskUsage: this.formatDiskUsage(e.diskUsage?.root),
      diskUsageError: e.diskUsageError,
      blockchainHeight: e.status?.blockchainHeight,
      blockchainHeightError: e.statusError,
      stateHeight: e.status?.stateHeight,
      stateHeightError: e.statusError,
      updatedTimestamp: this.formatTimestamp(e),
      updatedTimestampError: e.statusError,
      time: new Date(e.time).toLocaleString()
    } as unknown as VSysServerStatusDigest))
  }

  private formatTimestamp(e: VSysServerStatus) {
    return e.status ? DateHelper.format(e.status.updatedTimestamp / 1000000) : ''
  }

  protected formatDiskUsage(diskUsage?: number): string {
    if (typeof diskUsage !== 'number') {
      return ''
    }

    return `${diskUsage.toFixed(2)} %`
  }
}
