import { AlgorandServiceProvider } from '../domain/Algorand.service'
import AlgorandStakingServerStatus from '../domain/AlgorandStakingServerStatus.model'

export default class GetAlgorandStakingServersStatus {
  constructor(protected services: AlgorandServiceProvider) {
    //
  }

  async execute(): Promise<AlgorandStakingServerStatus[]> {
    return this.services.algorandService.getAll()
  }
}
