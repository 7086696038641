import EventBus from '../../common/event/EventBus'
import Presenter from '../../common/presenter/Presenter'
import Credential from '../domain/Credential.model'
import { LoginServiceProvider } from '../domain/Login.service'
import LoginEvent from '../event/Login.event'
import Login from '../usecase/Login.usecase'
import NotifyLoginError from '../usecase/NotifyLoginError.usecase'
import RestoreLogin from '../usecase/RestoreLogin.usecase'
import LoginState from './Login.state'

const initialState: LoginState = {
  displayLoginButton: true,
  displayLogoutButton: false,
  userName: ''
}

export default class LoginPresenter extends Presenter<LoginState> {
  constructor(protected services: LoginServiceProvider) {
    super(initialState)
  }

  async init() {
    this.bindLogin()
    this.restoreLogin()
  }

  async restoreLogin() {
    setTimeout(
      () => new RestoreLogin(this.services.loginService).execute()
      , 0)
  }

  async handeLogIn(credential: Partial<Credential>) {
    if (!credential.clientId || !credential.credential) {
      throw Error('Login Failed')
    }

    new Login(this.services.loginService).execute(credential as Credential)
  }

  async handeLogInError(givenError: Error) {
    new NotifyLoginError(this.services.loginService).execute(givenError)
  }

  protected bindLogin() {
    EventBus.subscribe(LoginEvent.key, this.notifyLogin.bind(this))
  }

  protected notifyLogin() {
    this.changeState({
      displayLoginButton: false,
      displayLogoutButton: true
    })
  }
}
