import * as React from 'react'

export interface InfoProps {
  message: string
}

export default class Info extends React.Component<InfoProps> {
  public render() {
    return (
      <span className='mx-1' title={this.props.message} style={{
        cursor: 'pointer'
      }}>
        ⓘ
      </span>
    )
  }
}
