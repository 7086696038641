import React from 'react'
import styled from 'styled-components'
import TimelineChart from '../chart/timeline/TimelineChart'
import { TimelineSerie } from '../../../core/timeline/domain/TimelineSerie'
import { TimelineError } from '../../../core/timeline/domain/TimelineError'
import HealthStatus from '../status/HealthStatus'

export interface StakingNodesCardProps {
  series: TimelineSerie[]
  instanceId?: string
  ip?: string
  privateIp?: string
  name?: string
  description?: string
  chain?: string
  software?: string
  softwareVersion?: string
  secondarySoftware?: string
  secondarySoftwareVersion?: string
  dproxyVersion?: string
  cloudsnapVersion?: string
  healthPercentagePeriod?: string
  healthPercentage10Minutes?: string
  healthPercentageDay?: string
  healthPercentage30Days?: string
  healthPercentageLifeTime?: string
  status?: string
  url?: string
  logarithmicScale: boolean
  errors: TimelineError[]
}

const Grid = styled.div`
  display: grid;
  gap: 1em;
  grid-template-rows: min-content minmax(0, 1fr) min-content;
  grid-template-columns: 1fr minmax(0, 5fr);
  grid-template-areas: "a h"
                       "a c"
                       "a f";
  min-height: 40vh;
  width: 100%;
  margin: 1em 0;
`
const ASide = styled.aside`
  grid-area: a;
  padding: 1em;
  background-color: #f0f8ff;
  font-size: .9em;

  label {
    font-weight: bold;
    font-size: .9em;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: .4em;
  }
`

const Header = styled.h1`
  grid-area: h;
  line-height: 1.3;
  padding: .2em;
  text-align: center;
  font-size: 1.5em;
  background-color: #f0f8ff;
`
const Footer = styled.footer`
  grid-area: f;
  text-align: center;
  font-size: 0.9em;
  padding: 0.6em 0;
  background-color: #f0f8ff;

  label {
    font-weight: bold;
    font-size: 1em;
  }

  span {
    margin-left: 1em;
  }
`

export default class StakingNodesCard extends React.Component<StakingNodesCardProps> {
  constructor(props: StakingNodesCardProps) {
    super(props)
  }

  render(): React.ReactNode {
    return <Grid>
      <Header>{this.props.description || this.props.instanceId} | {this.props.chain}</Header>
      <ASide>
        <label>Instance id:</label>
        <p>
          <a href={this.props.url} target='_blank'>{this.props.instanceId}</a>
        </p>
        <label>Name:</label>
        <p>{this.props.name}</p>
        <label>Description:</label>
        <p>{this.props.description}</p>
        <label>IP:</label>
        <p>{this.props.ip}</p>
        <label>Private IP:</label>
        <p>{this.props.privateIp}</p>
        <label>Chain:</label>
        <p>{this.props.chain}</p>
        <label>Software:</label>
        <p>{this.props.software} ({this.props.softwareVersion})</p>
        <label>Secondary Software:</label>
        <p> {this.props.secondarySoftware} ({this.props.secondarySoftwareVersion})</p>
        <label>DProxy:</label>
        <p>{this.props.dproxyVersion}</p>
        <label>CloudSnap:</label>
        <p>{this.props.cloudsnapVersion}</p>
      </ASide>
      <figure>
        <TimelineChart
          horizontalReferenceLines={[{ value: 60 }]}
          series={this.props.series}
          logarithmicScale={this.props.logarithmicScale}
          errors={this.props.errors}
          minY={1}
        />
      </figure>
      <Footer>
        <label>Last Status:</label>
        <HealthStatus style={{ margin: '0 1.5em 0 0.5em' }} value={this.props.status} />
        <label>Health Percentage:</label>
        <span>Period <strong>({this.props.healthPercentagePeriod})</strong></span>
        <span>Last 10 Minutes <strong>({this.props.healthPercentage10Minutes})</strong></span>
        <span>Last Day <strong>({this.props.healthPercentageDay})</strong></span>
        <span>Last 30 Days <strong>({this.props.healthPercentage30Days})</strong></span>
        <span>Lifetime <strong>({this.props.healthPercentageLifeTime})</strong></span>
      </Footer>
    </Grid>
  }
}
