import Event from '../event/Event'

export default class APIErrorEvent extends Event {
  static key = Symbol('APIError')

  constructor(public error: unknown | Error, public status: number = 0) {
    super(APIErrorEvent.key)
    this.handleError(error)
  }

  protected handleError(error: unknown) {
    if (!error) {
      return
    }

    console.log('API Error:')
    console.debug(error)
  }
}
