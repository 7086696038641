import BuildAlgorandOverview from '../../algorand/usecase/BuildAlgorandOverview.usecase'
import GetAlgorandStakingServersStatus from '../../algorand/usecase/GetAlgorandStakingServersStatus.usecase'
import BuildSnapshotsOverview from '../../chain-data/usecase/BuildSnapshotsOverview.usecase'
import GetLatestActiveChainsData from '../../chain-data/usecase/GetLatestActiveChainsData.usecase'
import BuildClustersOverview from '../../cluster/usecase/BuildClustersOverview.usecase'
import GetClustersReport from '../../cluster/usecase/GetClustersReport.usecases'
import Presenter from '../../common/presenter/Presenter'
import BuildEthValidatorsOverview from '../../ethereum-validator-status/usecase/BuildEthValidatorsOverview.usecase'
import GetValidatorsReports from '../../ethereum-validator-status/usecase/GetValidatorsReports.usecase'
import BuildOrchidOverview from '../../orchid/usecase/BuildOrchidOverview.usecase'
import GetOrchidStatus from '../../orchid/usecase/GetOrchidStatus.usecase'
import BuildVSysOverview from '../../v-sys-server/usecase/BuildVSysOverview.usecase'
import GetVSysServersStatus from '../../v-sys-server/usecase/GetVSysServerStatus.usecase'
import OverviewState from './Overview.state'

const initialState: OverviewState = {
  serverGroups: []
}

export default class HomePresenter extends Presenter<OverviewState> {
  constructor(
    protected getEthValidatorsReports: GetValidatorsReports,
    protected buildEthValidatorsOverview: BuildEthValidatorsOverview,
    protected getVSysServersStatus: GetVSysServersStatus,
    protected buildVSysOverview: BuildVSysOverview,
    protected getAlgorandStakingServersStatus: GetAlgorandStakingServersStatus,
    protected buildAlgorandOverview: BuildAlgorandOverview,
    protected getOrchidStatus: GetOrchidStatus,
    protected buildOrchidOverview: BuildOrchidOverview,
    protected getClustersReport: GetClustersReport,
    protected buildClustersOverview: BuildClustersOverview,
    protected getLatestActiveChainsData: GetLatestActiveChainsData,
    protected buildSnapshotsOverview: BuildSnapshotsOverview
  ) {
    super(initialState)
  }

  async init() {
    this.changeState({
      serverGroups: await this.buildServerGroups()
    })
  }

  async buildServerGroups() {
    return [
      ...await this.getClustersOverview(),
      ...await this.getSnapshotsOverview(),
      await this.getEthValidatorsOverview(),
      await this.getVSysServersOverview(),
      await this.getAlgorandServersOverview(),
      await this.getOrchidOverview()
    ]
  }

  async getEthValidatorsOverview() {
    const reports = await this.getEthValidatorsReports.execute()
    return {
      id: 'ethereum-validators',
      name: 'Ethereum Validators',
      servers: this.buildEthValidatorsOverview.execute(reports)
    }
  }

  async getVSysServersOverview() {
    const serversStatus = await this.getVSysServersStatus.execute()
    return {
      id: 'vsys-servers',
      name: 'VSYS Servers',
      servers: this.buildVSysOverview.execute(serversStatus)
    }
  }

  async getAlgorandServersOverview() {
    const serversStatus = await this.getAlgorandStakingServersStatus.execute()
    return {
      id: 'algorand-servers',
      name: 'Algorand Servers',
      servers: this.buildAlgorandOverview.execute(serversStatus)
    }
  }

  async getOrchidOverview() {
    const orchidServerLog = await this.getOrchidStatus.execute()
    return {
      id: 'orchid-servers',
      name: 'Orchid Servers',
      servers: [
        this.buildOrchidOverview.execute(orchidServerLog)
      ]
    }
  }

  async getClustersOverview() {
    const report = await this.getClustersReport.execute()
    return this.buildClustersOverview.execute(report)
  }

  async getSnapshotsOverview() {
    const chainsData = await this.getLatestActiveChainsData.execute()
    return this.buildSnapshotsOverview.execute(chainsData)
  }
}
