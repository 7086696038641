export default class DateHelper {
  static formatMsAsSeconds(ms: number, precision = 2) {
    return this.convertMsToSeconds(ms).toFixed(precision)
  }

  static convertMsToSeconds(ms: number) {
    return ms / 1000
  }

  static formatMsAsMinutes(ms: number, precision = 2) {
    return this.convertMsToMinutes(ms).toFixed(precision)
  }

  static convertMsToMinutes(ms: number) {
    return this.convertMsToSeconds(ms) / 60
  }

  static formatDiffTimeInMinutes(startTime: Date | number, endTime: Date | number, precision = 2): string {
    const diff = this.getDiffTimeInMinutes(startTime, endTime)
    return diff.toFixed(precision)
  }

  static getDiffTimeInMinutes(startTime: Date | number | string, endTime: Date | number | string): number {
    const diffMs = new Date(endTime).getTime() - new Date(startTime).getTime()
    return Math.floor(this.convertMsToMinutes(diffMs))
  }

  static format(date: Date | number | string): string {
    if (!date) {
      return ''
    }

    if (!(date instanceof Date)) {
      return this.format(new Date(date))
    }

    const formattedDateWithComma = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'medium' }).format(date)
    return formattedDateWithComma.replace(/,/g, '')
  }

  static convertToLocalTimezone(date: Date | number): Date {
    if (typeof date === 'number') {
      return this.convertToLocalTimezone(new Date(date))
    }

    const offset = date.getTimezoneOffset()
    return new Date(date.getTime() - offset * 60 * 1000)
  }
}
