import APIAdapter from '../../common/adapter/APIAdapter'
import EthValidatorTimeline from '../domain/EthValidatorTimeline.model'
import EthValidatorTimelineService from '../domain/EthValidatorTimeline.service'

export default class EthValidatorTimelineAPI extends APIAdapter implements EthValidatorTimelineService {
  async getTimelines(validatorsIndexes?: number[], initialTimestamp?: number, finalTimestamp?: number): Promise<EthValidatorTimeline[]> {
    const response = await this.get<{
      success: boolean,
      data: EthValidatorTimeline[]
    }>('/eth-validators-timeline', {
      validatorsIndexes: validatorsIndexes?.join(','),
      initialTimestamp,
      finalTimestamp
    })
    return response.data
  }
}
