import DefaultConfiguration from './Configuration.default'
import DevelopmentConfiguration from './Configuration.development.env'
import ProductionConfiguration from './Configuration.production.env'
import TestConfiguration from './Configuration.test.env'
import ConfigurationProperties from './ConfigurationProperties'

function getConfigProperties(): ConfigurationProperties {
  if (process.env.NODE_ENV === 'development') {
    return new DevelopmentConfiguration().build()
  }

  if (process.env.NODE_ENV === 'test') {
    return new TestConfiguration().build()
  }

  if (process.env.NODE_ENV === 'production') {
    return new ProductionConfiguration().build()
  }

  return new DefaultConfiguration().build()
}

const Configuration: Readonly<ConfigurationProperties> = getConfigProperties()

export default Configuration
