import Messages from '../domain/Messages.model'
import MessagesService from '../domain/Messages.service'

export default class MessagesInMemory implements MessagesService {
  constructor(protected messages: Messages) {
    //
  }

  get(messageId: keyof Messages): string {
    return this.messages[messageId]
  }
}
