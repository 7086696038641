import React from 'react'
import styled from 'styled-components'
import TimelineChart from '../chart/timeline/TimelineChart'
import { TimelineError } from '../../../core/timeline/domain/TimelineError'
import { TimelineChart as TimelineChartModel } from '../../../core/timeline/domain/TimelineChart'

export interface StakingNodesCardProps {
  charts: TimelineChartModel[]
  errors: TimelineError[]
  logarithmicScale: boolean
  validatorIndex: string
}

const Grid = styled.div`
  display: grid;
  gap: 0em;
  grid-template-rows: min-content minmax(0, 1fr) min-content;
  grid-template-columns: minmax(0, 5fr);
  grid-template-areas: "h"
                       "c";
  minHeight: 40vh;
  width: 100%;
  margin: 1em 0;
`

const Header = styled.h1`
  grid-area: h;
  line-height: 1.3;
  padding: .2em;
  text-align: center;
  font-size: 1.5em;
  background-color: #f0f8ff;
`

const Figures = styled.div`
  grid-area: c;
`

export default class StakingNodesCard extends React.Component<StakingNodesCardProps> {
  constructor(props: StakingNodesCardProps) {
    super(props)
  }

  render(): React.ReactNode {
    return <Grid>
      <Header>Validator #{this.props.validatorIndex}</Header>
      <Figures>
      {
        this.props.charts.map((chart) => {
          return <figure>
            <TimelineChart
              series={chart.series}
              logarithmicScale={this.props.logarithmicScale}
              errors={this.props.errors}
              pointRadius={0}
              lineHeight={3}
            />
          </figure>
        })
      }
      </Figures>
    </Grid>
  }
}
