import { AxiosInstance } from 'axios'
import APIAdapter from '../../common/adapter/APIAdapter'
import AlgorandService from '../domain/Algorand.service'
import AlgorandStakingServerStatus from '../domain/AlgorandStakingServerStatus.model'

export default class AlgorandAPI extends APIAdapter implements AlgorandService {
  constructor(protected _api?: AxiosInstance | string, protected uri: string = '/algorand-staking-status') {
    super(_api)
  }

  async getAll(): Promise<AlgorandStakingServerStatus[]> {
    const response = await this.get<{
      success: boolean,
      data: AlgorandStakingServerStatus[]
    }>(this.uri)
    return response.data
  }
}
