import * as React from 'react'
import TD from './TD'
import PercentageLinearGradient from '../../../helper/css/PercentageLinearGradient'

export interface IPercentCellProps {
  barColor?: string
  backgroundColor?: string
  children?:
  | React.ReactNode
  | React.ReactNode[];
}

export default class Percent extends React.Component<IPercentCellProps> {
  public render() {
    return (
      <TD.Center style={{ background: this.buildCssBackground() }}>
        {this.props.children}
      </TD.Center>
    )
  }

  buildCssBackground() {
    const barColor = this.props.barColor || '#ccc'
    const backgroundColor = this.props.backgroundColor || '#eee'
    const value = parseFloat((this.props.children || 0).toString())

    return new PercentageLinearGradient(barColor, backgroundColor, value).toString()
  }
}
