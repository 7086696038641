import { TimelineChart } from '../../timeline/domain/TimelineChart'
import { TimelineError } from '../../timeline/domain/TimelineError'
import { TimelineSerieData } from '../../timeline/domain/TimelineSerieData'
import EthValidatorEphemeralStatus from '../domain/EthValidatorEphemeralStatus.model'
import EthValidatorTimeline from '../domain/EthValidatorTimeline.model'
import { EthValidatorTimelineDigest } from '../domain/EthValidatorTimelineDigest.model'
import FormattedEthValidatorStatus from '../domain/FormattedEthValidatorStatus'

export default class BuildValidatorTimelineDigest {
  public execute(nodeTimeline: EthValidatorTimeline): EthValidatorTimelineDigest {
    const lastReport = this.getLastReport(nodeTimeline)
    return {
      index: nodeTimeline.index.toString(),
      validatorURL: FormattedEthValidatorStatus.buildValidatorURL(nodeTimeline.index),
      publicKey: nodeTimeline.publicKey,
      publicKeyURL: FormattedEthValidatorStatus.buildPublicKeyURL(nodeTimeline.publicKey),
      balance: lastReport.status?.balance.toString() ?? 'unknown',
      state: lastReport.status?.state ?? 'unknown',
      lastAttestationSlot: lastReport.status?.lastAttestationSlot.toString() ?? 'unknown',
      attestationEffectiveness: lastReport.status?.attestationEffectiveness.toString() ?? 'unknown',
      income7Days: lastReport.status?.income7Days.toString() ?? 'unknown',
      publicKeyTrimmed: FormattedEthValidatorStatus.trimPublicKey(nodeTimeline.publicKey),
      rank7Days: lastReport.status?.rank7Days.toString() ?? 'unknown',
      charts: this.buildCharts(nodeTimeline),
      errors: this.buildErrors(nodeTimeline)
    }
  }

  private getLastReport(nodeTimeline: EthValidatorTimeline) {
    return nodeTimeline.timeline[nodeTimeline.timeline.length - 1]
  }

  private buildCharts(nodeTimeline: EthValidatorTimeline) {
    return [
      this.buildChart(nodeTimeline, 'rank7Days', 'Ranking 7 Days', '#00539a55'),
      this.buildChart(nodeTimeline, 'balance', 'Balance', '#a2191f55'),
      this.buildChart(nodeTimeline, 'attestationEffectiveness', 'Attestation Effectiveness', '#15551555')
    ]
  }

  private buildChart(nodeTimeline: EthValidatorTimeline, propertyName: keyof Omit<EthValidatorEphemeralStatus, 'state'>, title: string, color: string): TimelineChart {
    return {
      series: [{
        title,
        color,
        data: nodeTimeline.timeline
          .map((report) =>
            report.status &&
            {
              x: report.time,
              y: report.status[propertyName]
            }
          ).filter((v): v is TimelineSerieData => v !== undefined)
      }]
    }
  }

  private buildErrors(nodeTimeline: EthValidatorTimeline) {
    const errors: TimelineError[] = []

    if (!nodeTimeline.errors || !nodeTimeline.errors.length) {
      return errors
    }

    nodeTimeline.timeline.forEach((report) => {
      if (report.errorIndex !== undefined && nodeTimeline.errors) {
        const message = '  ' + nodeTimeline.errors[report.errorIndex]
        const x = report.time
        errors.push({ x, message })
      }
    })

    return errors
  }
}
