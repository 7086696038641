import * as React from 'react'
import TDStatus from './TDStatus'

export interface IVSysStatusCellProps {
  value?: string
  hoverMessage?: string
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

export default class TDVSysStatus extends React.Component<IVSysStatusCellProps> {
  public render() {
    return (
      <TDStatus color={this.color} label={this.label} displayTag={this.displayTag} hoverMessage={this.props.hoverMessage}>
        { this.props.children }
      </TDStatus>
    )
  }

  get color(): string {
    if (this.isSuccess()) {
      return 'green'
    }

    return 'red'
  }

  get label(): string {
    return this.props.value || ''
  }

  get displayTag(): boolean {
    return !!this.props.value
  }

  isSuccess() {
    if (this.props.value?.startsWith('Success')) {
      return true
    }

    return false
  }
}
