import ServerOverview, { ServerStatus } from '../../overview/domain/Server.model'
import ServerGroupOverview from '../../overview/domain/ServerGroup.model'
import ClusterNodeReport from '../domain/ClusterNodeReport.model'
import ClusterReport from '../domain/ClusterReport.model'
import ClustersReport from '../domain/ClustersReport.entity'
import RegionClustersReport from '../domain/RegionClustersReport.model'

export default class BuildClustersOverview {
  constructor(protected diskUsageLimit: number) {
    //
  }

  public execute(report: ClustersReport): ServerGroupOverview[] {
    return this.buildOverview(report)
  }

  private buildOverview(report: ClustersReport) {
    const regionsOverview = Object.keys(report.accounts).map((accountKey) => {
      const accountReports = report.accounts[accountKey]
      return Object.keys(accountReports.regions).map((regionKey) => {
        const regionReports = accountReports.regions[regionKey]
        return this.buildRegionOverview(accountKey, regionReports)
      }).flat()
    })

    return regionsOverview.flat()
  }

  private buildRegionOverview(account: string, regionReports: RegionClustersReport): ServerGroupOverview[] {
    return [{
      id: `${account}-${regionReports.region}`,
      name: `Cluster ::: ${account} :::  ${regionReports.region}`,
      groups: regionReports.clusters.map((clusterReport) => {
        return this.buildClusterOverview(account, regionReports.region, clusterReport)
      })
    }]
  }

  private buildClusterOverview(account: string, region: string, clusterReport: ClusterReport): ServerGroupOverview {
    return {
      id: `${account}-${region}-${clusterReport.name}`,
      name: `${clusterReport.name}`,
      servers: this.buildServerOverview(account, region, clusterReport)
    }
  }

  private buildServerOverview(account: string, region: string, clusterReport: ClusterReport): ServerOverview[] {
    return clusterReport.nodes.map((nodeReport) => {
      return this.buildNodeOverview(account, region, clusterReport, nodeReport)
    })
  }

  private buildNodeOverview(account: string, region: string, clusterReport: ClusterReport, nodeReport: ClusterNodeReport): ServerOverview {
    return {
      label: `${nodeReport.ip}`,
      status: this.buildNodeStatus(nodeReport),
      url: `clusters#${account}|${region}`
    }
  }

  private buildNodeStatus(nodeReport: ClusterNodeReport): ServerStatus {
    if (!nodeReport.health) {
      return 'notok'
    }

    if (nodeReport.diskUsage && nodeReport.diskUsage > this.diskUsageLimit) {
      return 'notok'
    }

    return 'ok'
  }
}
