import { Fragment } from 'react'
import styled from 'styled-components'
import Configuration from '../../config/Configuration'
import VersionsPresenter from '../../../core/chain-versions/presenter/Versions.presenter'
import VersionsState from '../../../core/chain-versions/presenter/Versions.state'
import PresenterComponent from '../common/PresenterComponent'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import Table from '../table/Table'
import OptionsPane from '../layout/OptionsPane'
import Checkbox from '../form/Checkbox'

const VersionRow = styled.tr`
  &.secondary {
    td {
      color: #0062cc;
      font-style: italic;
    }
  }
`
const Version = styled.td`
  text-align: center;

  &.up-to-date {
    background-color: rgb(195, 230, 203);
  }
  &.ready-for-update {
    background-color: rgb(245, 198, 203);
  }
  &.update-blocked {
    background-color: rgb(207, 149, 255);
  }
`

export interface IVersionsProps {
}

export default class Versions extends PresenterComponent<IVersionsProps, VersionsState, VersionsPresenter> {
  constructor(props: IVersionsProps) {
    const presenter = new VersionsPresenter(Configuration.services)
    super(props, presenter)
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this.presenter.init()
  }

  public render() {
    return (
      <>
        {this.renderOptions()}
        {this.renderTable()}
      </>
    )
  }

  protected renderOptions() {
    return <OptionsPane title='Node Versions'>
      <Checkbox
        id="display-inactive-checkbox"
        onChange={this.toggleDisplayInactiveChains.bind(this)}
        initialState={this.state.displayInactiveChains}
      />
      <label htmlFor="display-inactive-checkbox">Display Inactive Chains</label>
    </OptionsPane>
  }

  protected toggleDisplayInactiveChains() {
    this.presenter.setDisplayInactiveChains(!this.state.displayInactiveChains)
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <th rowSpan={2}>Chain</th>
          <th rowSpan={2} className="text-center">Software</th>
          <th colSpan={9999} className="text-center">Versions</th>
        </tr>
        <tr>
          {
            this.state.spots.map(spot =>
              <th key={`spot-${spot}`} className="text-center">{spot}</th>
            )
          }
        </tr>
      </thead>
      <tbody>
        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }
    return <LoadingPlaceholder columns={5}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map((value, i) => (
      <Fragment key={`versions-${i}`}>
        <VersionRow>
          <td rowSpan={value.secondarySoftware ? 2 : 1}>{value.chain}</td>
          <td className="text-center">{value.software}</td>
          {
            this.state.spots.map((spot, j) => (
              <Version
                key={`spot-value-${spot}-${i}-${j}`}
                className={value.versions[spot]?.softwareVersionStatus}
                title={value.versions[spot]?.softwareVersionTime}
              >
                {value.versions[spot]?.softwareVersion}
              </Version>
            ))
          }
        </VersionRow>
        {!value.secondarySoftware
          ? ''
          : <VersionRow className='secondary'>
            <td className="text-center">{value.secondarySoftware}</td>
            {
              this.state.spots.map((spot, j) => (
                <Version
                  key={`version-${spot}-${i}-${j}`}
                  className={value.versions[spot]?.secondarySoftwareVersionStatus}
                  title={value.versions[spot]?.secondarySoftwareVersionTime}
                >
                  {value.versions[spot]?.secondarySoftwareVersion}
                </Version>
              ))
            }
          </VersionRow>
        }
      </Fragment>
    ))
  }
}
