import * as React from 'react'
import ChainFilter from '../chain/ChainFilter'
import NodesSyncTime from './NodesSyncTime'
import NodeSyncTimeMetrics from './NodesSyncTimeMetrics'

export interface IMetricsProps {
}

export interface IMetricsState {
  textSearchTerm: string
}

export default class Metrics extends React.Component<IMetricsProps, IMetricsState> {
  constructor(props: IMetricsState) {
    super(props)
  }

  render() {
    return (
      <>
        <h1>Overall Metrics</h1>
        <ChainFilter onChange={(textSearchTerm: string) => { this.setState({ textSearchTerm }) }}/>
        <NodeSyncTimeMetrics textSearchTerm={this.state?.textSearchTerm || ''}/>
        <NodesSyncTime textSearchTerm={this.state?.textSearchTerm || ''}/>
      </>
    )
  }
}
