import * as React from 'react'
import styled from 'styled-components'

export interface WarningStripProps {
  message?: string
}

const Warning = styled.div`
  background-color: #fd7e14;
  color: black;
  font-size: 1.2em;
  font-weight: bold;
`

export default class WarningStrip extends React.Component<WarningStripProps> {
  public render() {
    if (!this.props.message) {
      return
    }

    return (
      <Warning className='my-4 p-3'>
        <span className='pe-2'>⚠</span>{this.props.message}
      </Warning>
    )
  }
}
