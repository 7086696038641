import { compareVersions } from '../../../helper/version/versionHelper'
import ChainLocationVersions, { VersionStatus } from '../domain/ChainLocationVersions.model'
import ChainLocationVersionsMap from '../domain/ChainLocationVersionsMap.model'
import ChainVersions from '../domain/ChainVersions.model'
import LastestVersions from '../domain/LatestVersions.model'
import { VersionsServiceProvider } from '../domain/Versions.service'

export default class GetChainVersionsByLocation {
  constructor(protected services: VersionsServiceProvider) {
    //
  }

  async execute(includeInactive = false): Promise<ChainVersions[]> {
    const latestVersions = await this.services.versionsService.getLatestVersions()
    const activeLatestVersions = includeInactive ? latestVersions : this.filterActiveChains(latestVersions)
    const regions = Array.from(new Set(activeLatestVersions.filter(e => e.region).map(e => e.region)))
    const chains = Array.from(new Set(activeLatestVersions.map(e => e.chain))).sort()
    return this.buildDataResult(activeLatestVersions, regions, chains)
  }

  filterActiveChains(data: LastestVersions[]): LastestVersions[] {
    return data.filter(e => e.cloudsnapInfo)
  }

  protected buildDataResult(latestVersions: LastestVersions[], regions: string[], chains: string[]): ChainVersions[] {
    return chains.map(chain => {
      const values = latestVersions.filter(e => e.chain === chain)

      const mostRecentTime = new Date(values.reduce((v1, v2) => v1.time > v2.time ? v1 : v2).time).toLocaleString()

      const valuesWithSoftwareDeclared = values.filter(e => e.cloudsnapInfo?.software || e.software)
      const software = valuesWithSoftwareDeclared.length ? valuesWithSoftwareDeclared[0].cloudsnapInfo?.software ?? valuesWithSoftwareDeclared[0].software : ''

      const vendorVersion = values.reduce((v1, v2) => (v1.vendorVersionTime || 0) > (v2.vendorVersionTime || 0) ? v1 : v2).vendorVersion || ''
      const vendorVersionTime = vendorVersion
        ? new Date(values.reduce((v1, v2) => (v1.vendorVersionTime || 0) > (v2.vendorVersionTime || 0) ? v1 : v2).vendorVersionTime || '').toLocaleString()
        : ''

      const dockerVersion = values.reduce((v1, v2) => (v1.dockerVersionTime || 0) > (v2.dockerVersionTime || 0) ? v1 : v2).dockerVersion || ''
      const dockerVersionTime = dockerVersion
        ? new Date(values.reduce((v1, v2) => (v1.dockerVersionTime || 0) > (v2.dockerVersionTime || 0) ? v1 : v2).dockerVersionTime || '').toLocaleString()
        : ''
      const dockerVersionStatus = this.getVersionStatus(dockerVersion, vendorVersion)

      const utilVersion = this.getUtilVersion(values)
      const utilVersionStatus = this.getVersionStatus(utilVersion, dockerVersion, vendorVersion)

      const secondarySoftware = values[0].secondarySoftware || ''
      const secondarySoftwareVersionTime = secondarySoftware
        ? new Date(values.reduce((v1, v2) => ((v1.secondarySoftware && v1.time > v2.time) || !v2.secondarySoftware) ? v1 : v2).time).toLocaleString()
        : ''

      const secondaryUtilVersion = values.reduce((v1, v2) => v1.time > v2.time ? v1 : v2).secondarySoftwareVersion || ''
      const secondaryVendorVersion = values.reduce((v1, v2) => (v1.secondaryVendorVersionTime || 0) > (v2.secondaryVendorVersionTime || 0) ? v1 : v2).secondaryVendorVersion || ''
      const secondaryVendorVersionTime = vendorVersion
        ? new Date(values.reduce((v1, v2) => (v1.secondaryVendorVersionTime || 0) > (v2.secondaryVendorVersionTime || 0) ? v1 : v2).secondaryVendorVersionTime || '').toLocaleString()
        : ''

      const secondaryDockerVersion = values.reduce((v1, v2) => v1.secondaryDockerVersion ? v1 : v2).secondaryDockerVersion || ''
      const secondaryDockerVersionTime = vendorVersion
        ? new Date(values.reduce((v1, v2) => (v1.secondaryDockerVersionTime || 0) > (v2.secondaryDockerVersionTime || 0) ? v1 : v2).secondaryDockerVersionTime || '').toLocaleString()
        : ''
      const secondaryDockerVersionStatus = this.getVersionStatus(secondaryDockerVersion, secondaryVendorVersion)

      const secondaryUtilVersionStatus = this.getVersionStatus(secondaryUtilVersion, secondaryDockerVersion, secondaryVendorVersion)

      const versions: ChainLocationVersionsMap = {}
      regions.forEach(region => {
        versions[region] = this.buildChainLocationVersions(region, values, utilVersion, secondaryUtilVersion, vendorVersion, secondaryVendorVersion)
      })

      return {
        chain,
        software,
        utilVersion,
        dockerVersion,
        dockerVersionStatus,
        vendorVersion,
        secondarySoftware,
        secondaryUtilVersion,
        secondaryDockerVersion,
        secondaryVendorVersion,
        versions: {
          vendor: {
            softwareVersion: vendorVersion,
            softwareVersionTime: vendorVersionTime,
            softwareVersionStatus: '',
            secondarySoftwareVersion: secondaryVendorVersion,
            secondarySoftwareVersionTime: secondaryVendorVersionTime,
            secondarySoftwareVersionStatus: ''
          },
          docker: {
            softwareVersion: dockerVersion,
            softwareVersionTime: dockerVersionTime,
            softwareVersionStatus: dockerVersionStatus,
            secondarySoftwareVersion: secondaryDockerVersion,
            secondarySoftwareVersionTime: secondaryDockerVersionTime,
            secondarySoftwareVersionStatus: secondaryDockerVersionStatus
          },
          'util.js': {
            softwareVersion: utilVersion,
            softwareVersionTime: mostRecentTime,
            softwareVersionStatus: utilVersionStatus,
            secondarySoftwareVersion: secondaryUtilVersion,
            secondarySoftwareVersionTime,
            secondarySoftwareVersionStatus: secondaryUtilVersionStatus
          },
          ...versions
        }
      }
    })
  }

  private getUtilVersion(values: LastestVersions[]) {
    const newerValue = values.reduce((v1, v2) => v1.time > v2.time ? v1 : v2)
    const version = newerValue.utilVersion || this.sanitizeVersion(newerValue.cloudsnapInfo?.version || '')
    return version
  }

  buildChainLocationVersions(region: string, values: LastestVersions[], utilVersion: string, secondaryUtilVersion: string, vendorVersion: string, secondaryVendorVersion: string): ChainLocationVersions {
    const valuesFromRegion = values.filter(e => e.region === region)
    if (valuesFromRegion.length === 0) {
      return {
        softwareVersion: '',
        softwareVersionTime: '',
        softwareVersionStatus: '',
        secondarySoftwareVersion: '',
        secondarySoftwareVersionTime: '',
        secondarySoftwareVersionStatus: ''
      }
    }

    const softwareVersion = valuesFromRegion.reduce((e1, e2) => e1.softwareVersion ? e1 : e2).softwareVersion
    const softwareVersionTime = softwareVersion ? new Date(valuesFromRegion.reduce((e1, e2) => e1.softwareVersion ? e1 : e2).time).toLocaleString() : ''
    const softwareVersionStatus = this.getVersionStatus(softwareVersion, utilVersion, vendorVersion)
    const secondarySoftwareVersion = valuesFromRegion.reduce((e1, e2) => e1.secondarySoftwareVersion ? e1 : e2).secondarySoftwareVersion
    const secondarySoftwareVersionTime = secondarySoftwareVersion ? new Date(valuesFromRegion.reduce((e1, e2) => e1.secondarySoftwareVersion ? e1 : e2).time).toLocaleString() : ''
    const secondarySoftwareVersionStatus = this.getVersionStatus(secondarySoftwareVersion, secondaryUtilVersion, secondaryVendorVersion)

    return {
      softwareVersion,
      softwareVersionTime,
      softwareVersionStatus,
      secondarySoftwareVersion,
      secondarySoftwareVersionTime,
      secondarySoftwareVersionStatus
    }
  }

  getVersionStatus(version?: string, previousStepVersion?: string, higherVersion?: string): VersionStatus {
    if (!version || !previousStepVersion) {
      return ''
    }

    const result = compareVersions(this.sanitizeVersion(version), this.sanitizeVersion(previousStepVersion))

    if (result === undefined) {
      return ''
    }

    if (result === -1) {
      return 'ready-for-update'
    }

    if (!higherVersion) {
      return 'up-to-date'
    }

    const higherVersionComparisonResult = compareVersions(this.sanitizeVersion(version), this.sanitizeVersion(higherVersion))

    if (higherVersionComparisonResult === 0) {
      return 'up-to-date'
    }

    return 'update-blocked'
  }

  sanitizeVersion(version: string) {
    if (!version) {
      return version
    }

    return version.replace(/-(stable|mainet|testnet)$/, '').replace(/^v/, '')
  }
}
