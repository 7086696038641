import Presenter from '../../common/presenter/Presenter'
import { AlgorandServiceProvider } from '../domain/Algorand.service'
import AlgorandStatusDigest from '../domain/AlgorandStatusDigest.model'
import BuildAlgorandStatusDigest from '../usecase/BuildAlgorandStatusDigest.usecase'
import GetAlgorandStakingServersStatus from '../usecase/GetAlgorandStakingServersStatus.usecase'
import AlgorandState from './Algorand.state'

const initialState: AlgorandState = {
  displayData: false,
  displayLoadingMessage: false,
  data: []
}

export default class AlgorandPresenter extends Presenter<AlgorandState> {
  constructor(protected services: AlgorandServiceProvider) {
    super(initialState)
  }

  async init() {
    this.loadServersData()
  }

  async loadServersData() {
    const serverData = await new GetAlgorandStakingServersStatus(this.services).execute()
    const data: AlgorandStatusDigest[] = new BuildAlgorandStatusDigest().execute(serverData)

    this.changeState({
      data,
      displayData: true,
      displayLoadingMessage: false
    })
  }
}
