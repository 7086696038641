import * as React from 'react'
import EthValidatorStatusDigest from '../../../core/ethereum-validator-status/domain/EthValidatorStatusDigest'
import CopyToClipboardButton from '../CopyToClipboardButton'
import Percent from '../table/Percent'
import TDBeaconchainStatus from '../table/status/TDBeaconhainStatus'
import TD from '../table/TD'
import Error from '../Error'

export interface EthValidatorProps {
  validatorStatus: EthValidatorStatusDigest
}

export interface EthValidatorState {
  validatorStatus: EthValidatorStatusDigest
}

export default class EthValidator extends React.Component<EthValidatorProps, EthValidatorState> {
  constructor(props: EthValidatorProps) {
    super(props)
    this.state = {
      validatorStatus: props.validatorStatus
    }
  }

  render() {
    return (
      <tr>
        <TD.Center>
          <a href={this.state.validatorStatus.publicKeyURL}>
            {this.state.validatorStatus.publicKeyTrimmed}
          </a>
          <CopyToClipboardButton
            text={this.state.validatorStatus.publicKey}
          ></CopyToClipboardButton>
        </TD.Center>
        <TD.Center>
          <a href={this.state.validatorStatus.validatorURL}>
            {this.state.validatorStatus.index}
          </a>
          <Error message={this.state.validatorStatus.error} />
        </TD.Center>
        <TD.Center>
          {this.state.validatorStatus.balance}
        </TD.Center>
        <TDBeaconchainStatus value={this.state.validatorStatus.state} >
        </TDBeaconchainStatus>
        <TD.Center>
          {this.state.validatorStatus.lastAttestationSlot}
        </TD.Center>
        <Percent>
          {this.state.validatorStatus.attestationEffectiveness}
        </Percent>
        <TD.Center>
          {this.state.validatorStatus.income7Days}
        </TD.Center>
        <TD.Center>
          {this.state.validatorStatus.rank7Days}
        </TD.Center>
      </tr>
    )
  }
}
