import { VSysServerServiceProvider } from '../domain/VSysServer.service'
import VSysServerStatus from '../domain/VSysServerStatus.model'

export default class GetVSysServersStatus {
  constructor(protected services: VSysServerServiceProvider) {
    //
  }

  async execute(): Promise<VSysServerStatus[]> {
    return this.services.vsysServerService.getAll()
  }
}
