import APIAdapter from '../../common/adapter/APIAdapter'
import ClustersReport from '../domain/ClustersReport.entity'
import ClustersReportRepository from '../domain/ClustersReport.repository'

export class ClustersReportAPI extends APIAdapter implements ClustersReportRepository {
  async getReport(): Promise<ClustersReport> {
    const response = await this.get<{
      success: boolean,
      data: ClustersReport
    }>('/clusters-report')
    return response.data
  }
}
