import StakingNodesPresenter from '../../../core/staking-nodes/presenter/StakingNodes.presenter'
import StakingNodesState from '../../../core/staking-nodes/presenter/StakingNodes.state'
import Configuration from '../../config/Configuration'
import ErrorMessage from '../ErrorMessage'
import PresenterComponent from '../common/PresenterComponent'
import Checkbox from '../form/Checkbox'
import PeriodPicker from '../form/period/PeriodPicker'
import OptionsPane from '../layout/OptionsPane'
import StakingNodesCard from './StakingNodeCard'

export interface StakingNodesProps {
}

export default class StakingNodes extends PresenterComponent<StakingNodesProps, StakingNodesState, StakingNodesPresenter> {
  constructor(props: StakingNodesProps) {
    super(props, new StakingNodesPresenter(Configuration.services))
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  public render() {
    return (
      <>
        {this.renderOptions()}
        {this.renderData()}
        {this.renderErrorMessage()}
        {this.renderNoDataMessage()}
      </>
    )
  }

  private renderOptions() {
    return <OptionsPane key='options-pane' title='Staking Nodes'>
      {this.renderAccountSelector()}
      {this.renderRegionSelector()}

      <PeriodPicker
        initialTimestamp={this.state.initialTimestamp}
        finalTimestamp={this.state.finalTimestamp}
        initialTimestampLabel={this.state.initialTimestampLabel}
        finalTimestampLabel={this.state.finalTimestampLabel}
        onChange={this.handlePeriodChange.bind(this)}
      />

      <Checkbox
        id="logarithmic-scale-checkbox"
        onChange={this.toggleLogarithmicScale.bind(this)}
        initialState={this.state.logarithmicScale}
      />
      <label htmlFor="logarithmic-scale-checkbox">Logarithmic scale</label>
    </OptionsPane>
  }

  private handlePeriodChange(initialTimestamp: number, finalTimestamp: number) {
    this.presenter.setPeriod(initialTimestamp, finalTimestamp)
  }

  renderRegionSelector() {
    if (!this.state.displayRegionSelector) {
      return <></>
    }

    return <>
      <label>Region:</label><select onChange={(event) => this.presenter.selectRegion(event.target.value)}>
        {this.state.regions.map(region => <option value={region.key}>{region.key}</option>)}
      </select>
    </>
  }

  renderAccountSelector() {
    if (!this.state.displayAccountSelector) {
      return <></>
    }

    return <>
      <label>Account:</label>
      <select onChange={(event) => this.presenter.selectAccount(event.target.value)}>
        {this.state.accounts.map(account => <option value={account.key} selected={account.active}>{account.key}</option>)}
      </select>
    </>
  }

  protected renderLoadingMessage() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <h3>Loading...</h3>
  }

  protected renderNoDataMessage() {
    if (!this.state.noDataMessage) {
      return ''
    }

    return <em>{this.state.noDataMessage}</em>
  }

  protected renderData() {
    if (!this.state.displayData && !this.state.displayLoadingMessage) {
      return ''
    }

    return <>
      {this.renderLoadingMessage()}
      {this.renderErrorMessage()}
      {this.renderCards()}
    </>
  }

  private renderCards() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.nodes.map((node) =>
      <StakingNodesCard
        key={node.instanceId}
        series={node.series}
        instanceId={node.instanceId}
        ip={node.ip}
        privateIp={node.privateIp}
        name={node.name}
        description={node.description}
        chain={node.chain}
        software={node.software}
        softwareVersion={node.softwareVersion}
        secondarySoftware={node.secondarySoftware}
        secondarySoftwareVersion={node.secondarySoftwareVersion}
        dproxyVersion={node.dproxyVersion}
        cloudsnapVersion={node.cloudsnapVersion}
        url={node.url}
        logarithmicScale={this.state.logarithmicScale}
        errors={node.errors}
        status={node.status}
        healthPercentagePeriod={node.healthReport?.period?.label}
        healthPercentage10Minutes={node.healthReport?.last10Minutes?.label}
        healthPercentageDay={node.healthReport?.lastDay?.label}
        healthPercentage30Days={node.healthReport?.last30Days?.label}
        healthPercentageLifeTime={node.healthReport?.lifetime?.label}
      />
    )
  }

  private renderErrorMessage() {
    if (!this.state.errorMessage) {
      return ''
    }
    return <ErrorMessage content={this.state.errorMessage} />
  }

  private toggleLogarithmicScale() {
    this.presenter.toggleLogarithmicScale()
  }
}
