import OrchidPresenter from '../../../core/orchid/presenter/Orchid.presenter'
import OrchidState from '../../../core/orchid/presenter/Orchid.state'
import Configuration from '../../config/Configuration'
import PresenterComponent from '../common/PresenterComponent'
import Error from '../Error'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import LoadMoreButton from '../table/LoadMoreButton'
import Table from '../table/Table'
import TD from '../table/TD'
import TH from '../table/TH'

export interface OrchidStatusProps {
}

export default class OrchidStatusPage extends PresenterComponent<OrchidStatusProps, OrchidState, OrchidPresenter> {
  constructor(props: OrchidStatusProps) {
    super(props, new OrchidPresenter(Configuration.services))
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  render() {
    return <>
      <h1>Orchid</h1>
      {this.renderTable()}
      {this.renderLoadMoreButton()}
    </>
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH>Timestamp</TH>
          <TH.Center>Price</TH.Center>
          <TH.Center>Speed</TH.Center>
          <TH.Center>IP</TH.Center>
          <TH.Center>Address</TH.Center>
          <TH.Center>Commit</TH.Center>
        </tr>
      </thead>
      <tbody>
        {this.renderTableData()}
        {this.renderLoadingContent()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <LoadingPlaceholder columns={6}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map(
      e => (
        <tr key={e.time}>
          <TD.Bold>{e.time}</TD.Bold>
          <TD.Center>{e.price}<Error message={e.error}></Error></TD.Center>
          <TD.Center>{e.speed}</TD.Center>
          <TD.Center>{e.ip}</TD.Center>
          <TD.Center>{e.address}</TD.Center>
          <TD.Center><a href={e.commitURL}>{e.commit}</a></TD.Center>
        </tr>
      )
    )
  }

  private renderLoadMoreButton() {
    if (!this.state.displayLoadMoreButton) {
      return ''
    }

    return <LoadMoreButton onClick={() => this.handleLoadMoreButtonClick()} />
  }

  handleLoadMoreButtonClick() {
    this.presenter.loadMoreData()
  }
}
