import APIAdapter from '../../common/adapter/APIAdapter'
import NodeSyncTimeMetrics from '../domain/NodeSyncTimeMetrics.entity'
import NodeSyncTimeMetricsRepository from '../domain/NodeSyncTimeMetrics.repository'

export default class NodeSyncTimeMetricsAPI extends APIAdapter implements NodeSyncTimeMetricsRepository {
  async getNodesSyncTimeMetrics(): Promise<NodeSyncTimeMetrics[]> {
    const response = await this.get<{
      success: boolean,
      data: NodeSyncTimeMetrics[]
    }>('/node-sync-metrics')
    return response.data
  }
}
