import * as React from 'react'
import Percent from './Percent'

export interface IDiskUsageCellProps {
  limit?: number,
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

export default class DiskUsage extends React.Component<IDiskUsageCellProps> {
  static defaultProps = {
    limit: 97
  }

  static setDefaultLimit(limit: number) {
    DiskUsage.defaultProps.limit = limit
  }

  public render() {
    return (
      <Percent
        backgroundColor={this.isFull() ? '#ff0000' : this.isAboveLimit() ? '#ff9999' : undefined}
        barColor={this.isAboveLimit() ? '#ff0000' : undefined}
      >
        {this.props.children}
      </Percent>
    )
  }

  isFull() {
    return this.value >= 100
  }

  isAboveLimit() {
    return this.value >= (this.props.limit || 100)
  }

  get value(): number {
    return parseFloat((this.props.children || 0).toString())
  }
}
