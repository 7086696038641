import Configuration from '../../../react/config/Configuration'
import Presenter from '../../common/presenter/Presenter'
import ChainData from '../domain/ChainData.entity'
import { ChainDataRepositoryProvider } from '../domain/ChainData.repository'
import ChainDataDigest from '../domain/ChainDataDigest.model'
import ChainDataMethodFilter from '../domain/ChainDataMethodFilter.model'
import BuildChainDataDigest from '../usecase/BuildChainDataDigest.usecase'
import FilterChainData from '../usecase/FilterChainData.usecase'
import GetAllChainData from '../usecase/GetAllChainData.usecase'
import AllChainDataState from './AllChainData.state'

const initialState: AllChainDataState = {
  data: [],
  displayData: false,
  displayLoadingMessage: false,
  displayError: false
}

export default class AllChainDataPresenter extends Presenter<AllChainDataState> {
  protected chainData: ChainData[] = []

  constructor(protected services: ChainDataRepositoryProvider) {
    super({
      ...initialState
    })
  }

  async init(): Promise<void> {
    this.refreshData()
  }

  async refreshData(): Promise<void> {
    this.changeState({ displayLoadingMessage: true, displayData: false })
    this.chainData = await new GetAllChainData(this.services).execute()
    const data = this.buildDataResult()
    this.changeState({ data, displayLoadingMessage: false, displayData: true })
  }

  setTextSearchTerm(searchTerm: string): void {
    this.changeState({
      searchTerm,
      data: this.buildDataResult(searchTerm, this.state.methodFilter)
    })
  }

  setMethodFilter(methodFilter: ChainDataMethodFilter): void {
    this.changeState({
      methodFilter,
      data: this.buildDataResult(this.state.searchTerm, methodFilter)
    })
  }

  protected buildDataResult(searchTerm?: string, methodFilter?: ChainDataMethodFilter): ChainDataDigest[] {
    const filteredData = new FilterChainData().execute(this.chainData, { searchTerm, methodFilter })
    const data: ChainDataDigest[] = filteredData.map(chainData => new BuildChainDataDigest(Configuration.services).execute(chainData))
    return data
  }
}
