import Axios, { AxiosError, AxiosInstance } from 'axios'
import EventBus from '../event/EventBus'
import APIErrorEvent from './APIError.event'

export default class APIAdapter {
  constructor(protected _api?: AxiosInstance | string) {
    //
  }

  protected get api(): AxiosInstance {
    if (!this._api) {
      this._api = '/api'
    }

    if (typeof this._api === 'string') {
      this._api = Axios.create({
        baseURL: this._api
      })
    }

    return this._api
  }

  async get<T>(uri: string, params?: Record<string, unknown | undefined>): Promise<T> {
    try {
      const response = await this.api.get<T>(uri, {
        params
      })
      return response.data
    } catch (error: unknown) {
      this.notifyError(error)
      throw error
    }
  }

  async post<T>(uri: string, params?: Record<string, unknown | undefined>): Promise<T> {
    try {
      const response = await this.api.post<T>(uri, params)
      return response.data
    } catch (error: unknown) {
      this.notifyError(error)
      throw error
    }
  }

  protected notifyError(error: unknown) {
    if (Axios.isAxiosError(error)) {
      const axiosError = (error as AxiosError)
      EventBus.publish(new APIErrorEvent(axiosError, axiosError.request.status))
      return
    }

    EventBus.publish(new APIErrorEvent(error))
  }
}
