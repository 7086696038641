import EventBus from '../../common/event/EventBus'
import Credential from '../domain/Credential.model'
import LoginService from '../domain/Login.service'
import LoginEvent from '../event/Login.event'

export default class Login {
  constructor(protected loginService: LoginService) {
    //
  }

  execute(credential: Credential) {
    this.loginService.setCredential(credential)
    EventBus.publish(new LoginEvent())
  }
}
