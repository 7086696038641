import DateHelper from '../../../helper/date/DateHelper'
import ChainSyncTimeDigest, { TimeDiffClassification } from '../domain/ChainSyncTimeDigest'
import NodeSyncTime from '../domain/NodeSyncTime.entity'
import NodeSyncTimeMetrics from '../domain/NodeSyncTimeMetrics.entity'

export class BuildSyncTimeDigests {
  async execute(syncTimes: NodeSyncTime[], metrics: NodeSyncTimeMetrics[]): Promise<ChainSyncTimeDigest[]> {
    return this.buildData(syncTimes, metrics)
  }

  protected async buildData(syncTimes: NodeSyncTime[], allMetrics: NodeSyncTimeMetrics[]): Promise<ChainSyncTimeDigest[]> {
    return syncTimes.map(e => {
      const metrics = allMetrics.find(m => m.chain === e.chain)

      const chain = e.chain
      const region = e.region
      const startTime = DateHelper.format(e.startTime)
      const syncTime = DateHelper.format(e.syncTime)
      const timeToSyncAsNumber = DateHelper.getDiffTimeInMinutes(e.startTime, e.syncTime)
      const timeToSync = timeToSyncAsNumber.toFixed(2)
      const startBlockHeight = e.startBlockHeight.toString()
      const completedSyncBlockHeight = e.syncBlockHeight.toString()
      const blocksSyncked = (e.syncBlockHeight - e.startBlockHeight).toString()
      const timeDiffMonth = this.getDiffFromAverage(timeToSyncAsNumber, metrics?.month.avgTimeToSync)
      const timeDiffMonthClassification = this.getTimeDiffClass(timeDiffMonth)
      const timeDiffQuarter = this.getDiffFromAverage(timeToSyncAsNumber, metrics?.quarter.avgTimeToSync)
      const timeDiffQuarterClassification = this.getTimeDiffClass(timeDiffQuarter)
      const timeDiffYear = this.getDiffFromAverage(timeToSyncAsNumber, metrics?.year.avgTimeToSync)
      const timeDiffYearClassification = this.getTimeDiffClass(timeDiffYear)

      return {
        chain,
        region,
        startTime,
        syncTime,
        timeToSync,
        startBlockHeight,
        completedSyncBlockHeight,
        blocksSyncked,
        timeDiffMonth,
        timeDiffMonthClassification,
        timeDiffQuarter,
        timeDiffQuarterClassification,
        timeDiffYear,
        timeDiffYearClassification
      }
    })
  }

  protected getDiffFromAverage(timeToSync: number, avgTime?: number) {
    if (!avgTime) {
      return 'N/A'
    }
    const x = timeToSync - DateHelper.convertMsToMinutes(avgTime)
    return `${x.toFixed(2)} min`
  }

  protected getTimeDiffClass(diffText: string): TimeDiffClassification {
    const diff = parseFloat(diffText)
    if (Number.isNaN(diff)) {
      return 'neutral'
    }

    if (diff < 1) {
      return 'negative'
    }

    return 'positive'
  }
}
