import Event from './Event'

export type Subscription<T> = (event: T) => void

export default class EventBusInstance {
  private listeners = new Map<symbol, Subscription<Event>[]>()

  public publish(event: Event) {
    const listeners = this.listeners.get(event.key)
    if (!listeners) {
      return
    }
    listeners.forEach(listener => listener(event))
  }

  public subscribe(eventKey: symbol, listener: Subscription<Event>): void {
    let listeners = this.listeners.get(eventKey)
    if (!listeners) {
      listeners = []
      this.listeners.set(eventKey, listeners)
    }
    listeners.push(listener)
  }

  public unsubscribe(eventKey: symbol, listener: Subscription<Event>): void {
    const listeners = this.listeners.get(eventKey)
    if (listeners) {
      const index = listeners.indexOf(listener)
      if (index > -1) {
        listeners.splice(index, 1)
      }
    }
  }
}
