import Configuration from '../../config/Configuration'
import PackagesVersionsPresenter from '../../../core/packages-versions/presenter/PackagesVersions.presenter'
import PackagesVersionsState from '../../../core/packages-versions/presenter/PackagesVersions.state'
import PresenterComponent from '../common/PresenterComponent'

export interface PackagesVersionsProps {
  //
}

export default class PackagesVersions extends PresenterComponent<PackagesVersionsProps, PackagesVersionsState, PackagesVersionsPresenter> {
  constructor(props: PackagesVersionsProps) {
    const presenter = new PackagesVersionsPresenter(Configuration.services)
    super(props, presenter)
    presenter.init()
  }

  public render() {
    return <>
      <span><strong>nodes-dashboard-client: </strong>{this.state.clientVersion}</span>
      <span><strong>nodes-dashboard-server: </strong>{this.state.serverVersion}</span>
      <span><strong>cloud-snap: </strong>{this.state.cloudSnapVersion}</span>
    </>
  }
}
