import Presenter from '../../common/presenter/Presenter'
import ChainSyncTimeDigest from '../domain/ChainSyncTimeDigest'
import NodeSyncTime from '../domain/NodeSyncTime.entity'
import { NodeSyncTimeRepositoryProvider } from '../domain/NodeSyncTime.repository'
import NodeSyncTimeMetrics from '../domain/NodeSyncTimeMetrics.entity'
import { NodeSyncTimeMetricsRepositoryProvider } from '../domain/NodeSyncTimeMetrics.repository'
import { BuildSyncTimeDigests } from '../usecase/BuildSyncTimeDigests.usecase'
import GetSyncTimeMetrics from '../usecase/GetSyncTimeMetrics.usecase'
import GetSyncTimes from '../usecase/GetSyncTimes.usecase'
import ChainSyncTimeState from './ChainSyncTime.state'

const initialState: ChainSyncTimeState = {
  searchTermText: '',
  displayData: false,
  displayLoading: true,
  data: []
}

export default class ChainSyncTimePresenter extends Presenter<ChainSyncTimeState> {
  protected syncTimes: NodeSyncTime[] = []
  protected metrics: NodeSyncTimeMetrics[] = []
  protected allData?: ChainSyncTimeDigest[]

  constructor(protected services: NodeSyncTimeRepositoryProvider & NodeSyncTimeMetricsRepositoryProvider) {
    super(initialState)
  }

  async init() {
    this.loadData()
  }

  protected async loadData() {
    this.syncTimes = await new GetSyncTimes(this.services).execute()
    this.metrics = await new GetSyncTimeMetrics(this.services).execute()
    const data = await this.buildData(this.state.searchTermText)
    this.changeState({
      data,
      displayData: true,
      displayLoading: false
    })
  }

  protected async buildData(searchTermText: string): Promise<ChainSyncTimeDigest[]> {
    if (!this.allData) {
      this.allData = await new BuildSyncTimeDigests().execute(this.syncTimes, this.metrics)
    }

    if (!searchTermText) {
      return this.allData
    }

    return this.allData.filter(e => e.chain.indexOf(searchTermText) >= 0)
  }

  async setTextFilter(searchTermText: string) {
    if (searchTermText === this.state.searchTermText) {
      return
    }

    this.changeState({
      ...this.state,
      searchTermText,
      data: await this.buildData(searchTermText)
    })
  }
}
