import Configuration from '../../config/Configuration'
import ErrorMessage from '../ErrorMessage'
import PresenterComponent from '../common/PresenterComponent'
import Checkbox from '../form/Checkbox'
import PeriodPicker from '../form/period/PeriodPicker'
import EthValidatorsTimelinesCard from './EthValidatorCard'
import EthValidatorTimelinesPresenter from '../../../core/ethereum-validator-status/presenter/EthValidatorTimeline.presenter'
import EthValidatorTimelinesState from '../../../core/ethereum-validator-status/presenter/EthValidatorTimeline.state'
import OptionsPane from '../layout/OptionsPane'

export interface EthValidatorsTimelinesProps {
}

export default class EthValidatorsTimelines extends PresenterComponent<EthValidatorsTimelinesProps, EthValidatorTimelinesState, EthValidatorTimelinesPresenter> {
  constructor(props: EthValidatorsTimelinesProps) {
    super(props, new EthValidatorTimelinesPresenter(Configuration.services))
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  public render() {
    return (
      <>
        {this.renderOptions()}
        {this.renderData()}
        {this.renderErrorMessage()}
        {this.renderNoDataMessage()}
      </>
    )
  }

  private renderOptions() {
    return <OptionsPane title='History Log'>
      <PeriodPicker
        initialTimestamp={this.state.initialTimestamp}
        finalTimestamp={this.state.finalTimestamp}
        initialTimestampLabel={this.state.initialTimestampLabel}
        finalTimestampLabel={this.state.finalTimestampLabel}
        onChange={this.handlePeriodChange.bind(this)}
      />

      <Checkbox
        id="logarithmic-scale-checkbox"
        onChange={this.toggleLogarithmicScale.bind(this)}
        initialState={this.state.logarithmicScale}
      />
      <label htmlFor="logarithmic-scale-checkbox">Logarithmic scale</label>
    </OptionsPane>
  }

  private handlePeriodChange(initialTimestamp: number, finalTimestamp: number) {
    this.presenter.setPeriod(initialTimestamp, finalTimestamp)
  }

  protected renderLoadingMessage() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <h3>Loading...</h3>
  }

  protected renderNoDataMessage() {
    if (!this.state.noDataMessage) {
      return ''
    }

    return <em>{this.state.noDataMessage}</em>
  }

  protected renderData() {
    if (!this.state.displayData && !this.state.displayLoadingMessage) {
      return ''
    }

    return <>
      {this.renderLoadingMessage()}
      {this.renderErrorMessage()}
      {this.renderCards()}
    </>
  }

  private renderCards() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.nodes.map((validator) =>
      <EthValidatorsTimelinesCard
        key={validator.index}
        charts={validator.charts}
        validatorIndex={validator.index}
        logarithmicScale={this.state.logarithmicScale}
        errors={validator.errors}
      />
    )
  }

  private renderErrorMessage() {
    if (!this.state.errorMessage) {
      return ''
    }
    return <ErrorMessage content={this.state.errorMessage} />
  }

  private toggleLogarithmicScale() {
    this.presenter.toggleLogarithmicScale()
  }
}
