import styled from 'styled-components'
import ServerOverview from '../../../core/overview/domain/Server.model'
import ServerGroupOverview from '../../../core/overview/domain/ServerGroup.model'
import OverviewPresenter from '../../../core/overview/presenter/Overview.presenter'
import OverviewState from '../../../core/overview/presenter/Overview.state'
import Configuration from '../../config/Configuration'
import PresenterComponent from '../common/PresenterComponent'
import OptionsPane from '../layout/OptionsPane'
import ServerOverviewTag from './ServerOverview'
import ServerGroupOverviewTag from './ServerGroupOverview'
import MagicGrid, { MagicGridProps } from 'magic-grid'
import React from 'react'

export interface OverviewProps {
}

const Container = styled.div`
  > * {
    width: 22vw;
    margin: 1vw; 
  }
`

export default class Overview extends PresenterComponent<OverviewProps, OverviewState, OverviewPresenter> {
  container = React.createRef<HTMLDivElement>()
  magicGrid?: MagicGrid = undefined

  magicGridOptions: Partial<MagicGridProps> = {
    animate: true,
    static: true,
    useMin: true
  }

  constructor(props: OverviewProps) {
    const presenter = new OverviewPresenter(
      Configuration.usecases.getEthValidatorsReports(),
      Configuration.usecases.buildEthValidatorsOverview(),
      Configuration.usecases.getVSysServersStatus(),
      Configuration.usecases.buildVSysOverview(),
      Configuration.usecases.getAlgorandStakingServersStatus(),
      Configuration.usecases.buildAlgorandOverview(),
      Configuration.usecases.getOrchidStatus(),
      Configuration.usecases.buildOrchidOverview(),
      Configuration.usecases.getClustersReport(),
      Configuration.usecases.buildClustersOverview(),
      Configuration.usecases.getLatestActiveChainsData(),
      Configuration.usecases.buildSnapshotsOverview()
    )
    super(props, presenter)
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this.presenter.init()
  }

  async componentDidUpdate(): Promise<void> {
    this.applyMagicGrid()
  }

  applyMagicGrid() {
    if (!this.container.current) {
      return
    }

    if (this.magicGrid) {
      this.magicGrid.positionItems()
      return
    }

    this.magicGrid = new MagicGrid({
      container: this.container.current,
      ...this.magicGridOptions
    })
    this.magicGrid.listen()
  }

  public render() {
    return (
      <>
        {this.renderOptions()}
        {this.renderServerGroups()}
      </>
    )
  }

  public renderOptions() {
    return <OptionsPane key='options-pane' title='Overview'></OptionsPane>
  }

  protected renderServerGroups() {
    return <Container ref={this.container}>
      {
        this.state.serverGroups.map((serverGroup, index) => this.createServerGroup(index, serverGroup))
      }
    </Container>
  }

  private createServerGroup(index: number, serverGroup: ServerGroupOverview): JSX.Element {
    return <ServerGroupOverviewTag key={index} title={serverGroup.name}>
      {(serverGroup.servers ?? []).map((server, index) => {
        return this.createServerTag(index, server)
      })}
      {(serverGroup.groups ?? []).map((serverSubGroup, index) => {
        return this.createServerGroup(index, serverSubGroup)
      })}
    </ServerGroupOverviewTag>
  }

  private createServerTag(index: number, server: ServerOverview): JSX.Element {
    return <ServerOverviewTag key={index} label={server.label} status={server.status} url={server.url} message={server.message}></ServerOverviewTag>
  }
}
