import styled from 'styled-components'
import Configuration from '../../config/Configuration'
import ChainSyncTimePresenter from '../../../core/chain-sync-time/presenter/ChainSyncTime.presenter'
import ChainSyncTimeState from '../../../core/chain-sync-time/presenter/ChainSyncTime.state'
import PresenterComponent from '../common/PresenterComponent'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import Table from '../table/Table'
import TD from '../table/TD'
import TH from '../table/TH'

const TimeDiff = styled.div`
  &.negative {
    color: #27ae60;
  }

  &.positive {
    color: #c0392b;
  }
`

export interface NodesSyncTimeProps {
  textSearchTerm: string
}

export default class NodesSyncTime extends PresenterComponent<NodesSyncTimeProps, ChainSyncTimeState, ChainSyncTimePresenter> {
  constructor(props: NodesSyncTimeProps) {
    const presenter = new ChainSyncTimePresenter(Configuration.services)
    super(props, presenter)
  }

  static defaultProps = {
    textSearchTerm: ''
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    await this.presenter.init()
  }

  componentDidUpdate() {
    this.presenter.setTextFilter(this.props.textSearchTerm)
  }

  render() {
    return (
      this.renderTable()
    )
  }

  protected renderTable() {
    return <Table>
      <thead>
        <tr>
          <TH>Chain</TH>
          <TH>Region</TH>
          <TH>Start Time</TH>
          <TH>Sync Time</TH>
          <TH>Time to Sync</TH>
          <TH>Start Block Height</TH>
          <TH>Completed Sync Block Height</TH>
          <TH>Blocks Synced</TH>
          <TH>Difference in time </TH>
        </tr>
      </thead>
      <tbody>
        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoading) {
      return ''
    }

    return <LoadingPlaceholder columns={9}></LoadingPlaceholder>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.map(
      (e, i) => (
        <tr key={`record-${i}`}>
          <TD.Bold>{e.chain}</TD.Bold>
          <TD>{e.region}</TD>
          <TD>{e.startTime}</TD>
          <TD>{e.syncTime}</TD>
          <TD>{e.timeToSync}</TD>
          <TD>{e.startBlockHeight}</TD>
          <TD>{e.completedSyncBlockHeight}</TD>
          <TD>{e.blocksSyncked}</TD>
          <TD>
            <TimeDiff className={e.timeDiffMonthClassification}>Month: {e.timeDiffMonth}</TimeDiff>
            <TimeDiff className={e.timeDiffQuarterClassification}>Quarter: {e.timeDiffQuarter}</TimeDiff>
            <TimeDiff className={e.timeDiffYearClassification}>Year: {e.timeDiffYear}</TimeDiff>
          </TD>
        </tr>
      )
    )
  }
}
