import Presenter from '../../common/presenter/Presenter'
import EthValidatorReport from '../domain/EthValidatorReport.model'
import BuildValidatorsStatusDigest from '../usecase/BuildValidatorsStatusDigest.usecase'
import GetValidatorsReports from '../usecase/GetValidatorsReports.usecase'
import EthValidatorPresenterState from './EthValidator.state'

const initialState: EthValidatorPresenterState = {
  validatorsStatus: [],
  displayData: false,
  displayLoadingMessage: true
}

export default class EthValidatorPresenter extends Presenter<EthValidatorPresenterState> {
  constructor(protected getReports: GetValidatorsReports) {
    super(initialState)
  }

  async init() {
    this.loadEthValidators()
  }

  protected async loadEthValidators() {
    const reports = await this.getReports.execute()
    this.sortReportsByIndex(reports)
    this.changeState({
      validatorsStatus: new BuildValidatorsStatusDigest().execute(reports),
      displayData: true,
      displayLoadingMessage: false
    })
  }

  protected sortReportsByIndex(reports: EthValidatorReport[]) {
    reports.sort((a, b) => a.validatorIndex - b.validatorIndex)
  }
}
