import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

export default class DashboardServer {
  protected authToken?: string
  protected api: AxiosInstance

  constructor(protected baseURL: string) {
    this.api = this.createApi()
  }

  protected createApi() {
    const api = Axios.create({
      baseURL: this.baseURL
    })
    api.interceptors.request.use(this.addAuthenticationToken.bind(this))
    api.interceptors.response.use(this.checkResponse.bind(this), this.checkRejectedResponse.bind(this))
    return api
  }

  getApi() {
    return this.api
  }

  setAuthToken(authToken?: string) {
    this.authToken = authToken
  }

  protected addAuthenticationToken(config: AxiosRequestConfig) {
    if (this.authToken) {
      if (!config.headers) {
        config.headers = {}
      }
      config.headers.Authorization = this.authToken
    }
    return config
  }

  protected checkResponse(response: AxiosResponse) {
    // if (response.status === 401) {
    //   throw Error('Errrooooo')
    // }
    return response
  }

  protected checkRejectedResponse(error: unknown) {
    // if (response.status === 401) {
    //   throw Error('Errrooooo')
    // }
    console.log('Nodes Dashboard Server Error:')
    console.debug(error)
    throw error
  }
}
