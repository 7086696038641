import FormattedEther from '../../ethereum/domain/FormattedEther'
import EthValidatorStatus from './EthValidatorStatus.model'

export interface IFormattedEthValidatorStatus extends EthValidatorStatus {
  formattedBalance: string
  formattedIncome7Days: string
  trimmedPublicKey: string
  formatedAttestationEffectiveness: string
}

export default class FormattedEthValidatorStatus implements IFormattedEthValidatorStatus {
  protected _index: number
  protected _publicKey: string
  protected _balance: number
  protected _state: string
  protected _lastAttestationSlot: number
  protected _attestationEffectiveness: number
  protected _income7Days: number
  protected _rank7Days: number

  get index(): number {
    return this._index
  }

  get publicKey(): string {
    return this._publicKey
  }

  get publicKeyURL(): string {
    return FormattedEthValidatorStatus.buildPublicKeyURL(this.publicKey)
  }

  static buildPublicKeyURL(publicKey: string): string {
    return `https://beaconscan.com/main/validator/${publicKey}`
  }

  get validatorURL(): string {
    return FormattedEthValidatorStatus.buildValidatorURL(this.index)
  }

  static buildValidatorURL(index: number): string {
    return `https://beaconscan.com/validator/${index}`
  }

  get balance(): number {
    return this._balance
  }

  get state(): string {
    return this._state
  }

  get lastAttestationSlot(): number {
    return this._lastAttestationSlot
  }

  get attestationEffectiveness(): number {
    return this._attestationEffectiveness
  }

  get income7Days(): number {
    return this._income7Days
  }

  get rank7Days(): number {
    return this._rank7Days
  }

  constructor(values: EthValidatorStatus) {
    this._index = values.index
    this._publicKey = values.publicKey
    this._balance = values.balance
    this._state = values.state
    this._lastAttestationSlot = values.lastAttestationSlot
    this._attestationEffectiveness = values.attestationEffectiveness
    this._income7Days = values.income7Days
    this._rank7Days = values.rank7Days
  }

  get formattedBalance(): string {
    return new FormattedEther(this.balance).toString()
  }

  get formattedIncome7Days(): string {
    return new FormattedEther(this.income7Days).toString()
  }

  get trimmedPublicKey() {
    return FormattedEthValidatorStatus.trimPublicKey(this.publicKey)
  }

  static trimPublicKey(publicKey: string) {
    return `${publicKey.substring(0, 10)}...`
  }

  get formatedAttestationEffectiveness() {
    if (this.attestationEffectiveness === 1) {
      return '100 %'
    }
    const decimalPercent = (this.attestationEffectiveness * 100).toFixed(1).toString()
    return `${decimalPercent} %`
  }
}
