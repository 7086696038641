import React from 'react'

export interface ChainDataFilterProps {
  onChange: ChangeHandler
}
export type ChangeHandler = (text: string) => void
export type ResetHandler = () => void

export interface ChainFilterState {
  inputValue: string
}

export default class ChainFilter extends React.Component<ChainDataFilterProps, ChainFilterState> {
  public render() {
    return (
      <div className='mb-4'>
        <h5>Search by chain</h5>
        <input
          id="main-search"
          type="search"
          value={this.state?.inputValue || ''}
          onChange={this.handleInputChange}
          className="form-control form-control-lg"
          placeholder="Search / Filter Chains"
        />
        <button onClick={this.clearSearch}>
          Remove Search / Original List
        </button>
      </div>
    )
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputValue: event.target.value })
    this.props.onChange(event.target.value)
  }

  clearSearch = () => {
    this.setState({ inputValue: '' })
    this.props.onChange('')
  }
}
