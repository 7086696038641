import * as React from 'react'
import TDStatus from './TDStatus'

export interface IOnlineStatusCellProps {
  value?: string
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

const ONLINE_VALUES = ['online', 'ok', 'true']

export default class TDOnlineStatus extends React.Component<IOnlineStatusCellProps> {
  public render() {
    return (
      <TDStatus color={this.color} label={this.label} displayTag={this.displayTag}>
        { this.props.children }
      </TDStatus>
    )
  }

  get color(): string {
    if (this.isOnline()) {
      return 'green'
    }

    return 'red'
  }

  get label(): string {
    if (this.isOnline()) {
      return 'Online'
    }

    return 'Offline'
  }

  get displayTag(): boolean {
    return !!this.props.value
  }

  isOnline() {
    if (ONLINE_VALUES.indexOf(this.props.value || '') >= 0) {
      return true
    }

    return false
  }
}
