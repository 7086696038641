import Configuration from '../../../react/config/Configuration'
import Presenter from '../../common/presenter/Presenter'
import ChainData from '../domain/ChainData.entity'
import ChainStateDigest from '../domain/ChainStateDigest.model'
import BuildChainsStateDigestFromRegion from '../usecase/BuildChainsStateDigestFromRegion.usecase'
import GetLatestActiveChainsData from '../usecase/GetLatestActiveChainsData.usecase'
import LastestChainDataState from './LastestChainData.state'

const initialState: LastestChainDataState = {
  selectedRegion: 'us-east-1',
  regions: [],
  data: [],
  displayData: false,
  displayLoadingMessage: false,
  displayError: false,
  displayInactiveChains: false
}

export default class LastestChainDataPresenter extends Presenter<LastestChainDataState> {
  protected chainData: ChainData[] = []
  protected regions: string[] = []
  protected selectedRegion = ''

  constructor(protected getLatestActiveChainsData: GetLatestActiveChainsData) {
    super({
      ...initialState
    })
  }

  async init(regions: string[], selectedRegion: string) {
    this.regions = regions
    this.changeState({ selectedRegion })
    this.refreshRegions()
    this.loadData()
  }

  private refreshRegions() {
    this.changeState({
      regions: this.buildRegions(this.state.selectedRegion)
    })
  }

  async loadData(): Promise<void> {
    this.refreshData()
  }

  async refreshData(): Promise<void> {
    this.changeState({ displayLoadingMessage: true, displayData: false })
    this.chainData = await this.getLatestActiveChainsData.execute(this.state.displayInactiveChains)
    const data = this.buildDataResult(this.chainData, this.state.selectedRegion)
    this.changeState({ data, displayLoadingMessage: false, displayData: true })
  }

  async selectRegion(selectedRegion: string): Promise<void> {
    this.changeState({
      selectedRegion,
      data: this.buildDataResult(this.chainData, selectedRegion),
      regions: this.buildRegions(selectedRegion)
    })
  }

  protected buildRegions(selectedRegion: string) {
    return this.regions.map(key => ({
      key,
      active: selectedRegion === key
    }))
  }

  protected buildDataResult(originalData: ChainData[], region: string): ChainStateDigest[] {
    const data = new BuildChainsStateDigestFromRegion(Configuration.services).execute(originalData, region)
    return this.sortData(data)
  }

  protected sortData(data: ChainStateDigest[]) {
    return data.sort((a, b) => a.chain.localeCompare(b.chain))
  }

  async setDisplayInactiveChains(displayInactiveChains: boolean): Promise<void> {
    this.changeState({ displayInactiveChains })
    this.loadData()
  }
}
