import * as React from 'react'
import DateHelper from '../../../../helper/date/DateHelper'

const HOUR = 1000 * 60 * 60
const DAY = HOUR * 24
const WEEK = DAY * 7
const MONTH = DAY * 30
const YEAR = DAY * 365

export interface PeriodPickerProps {
  id?: string
  initialTimestamp: number,
  initialTimestampLabel?: string,
  finalTimestamp: number
  finalTimestampLabel?: string
  onChange?: (initialTimestamp: number, finalTimestamp: number) => void
}

export interface PeriodPickerState {
  initialTimestamp: number,
  finalTimestamp: number
}

export default class PeriodPicker extends React.Component<PeriodPickerProps, PeriodPickerState> {
  constructor(props: PeriodPickerProps) {
    super(props)
    this.state = {
      initialTimestamp: this.props.initialTimestamp,
      finalTimestamp: this.props.finalTimestamp
    }
  }

  public render() {
    return (
      <>
        {
          this.props.initialTimestampLabel
            ? <label htmlFor="initialTimestamp">{this.props.initialTimestampLabel}</label>
            : <></>
        }
        <input id="initialTimestamp"
          type="datetime-local"
          onChange={(e) => this.handleInitialTimestampChange(e)}
          value={this.convertToInputDateTimeFormat(this.state.initialTimestamp)}
        />
        {
          this.props.finalTimestampLabel
            ? <label htmlFor="finalTimestamp">{this.props.finalTimestampLabel}</label>
            : <></>
        }
        <input id="finalTimestamp"
          type="datetime-local"
          onChange={(e) => this.handleFinalTimestampChange(e)}
          value={this.convertToInputDateTimeFormat(this.state.finalTimestamp)}
        />
        <a href="#" onClick={() => this.setInterval(Date.now() - HOUR, Date.now())}>1H</a>
        <a href="#" onClick={() => this.setInterval(Date.now() - HOUR * 3, Date.now())}>3H</a>
        <a href="#" onClick={() => this.setInterval(Date.now() - HOUR * 6, Date.now())}>6H</a>
        <a href="#" onClick={() => this.setInterval(Date.now() - DAY, Date.now())}>1D</a>
        <a href="#" onClick={() => this.setInterval(Date.now() - WEEK, Date.now())}>1W</a>
        <a href="#" onClick={() => this.setInterval(Date.now() - MONTH, Date.now())}>1M</a>
        <a href="#" onClick={() => this.setInterval(Date.now() - YEAR, Date.now())}>1Y</a>
      </>
    )
  }

  convertToInputDateTimeFormat(timestamp: number) {
    const date = DateHelper.convertToLocalTimezone(timestamp)
    return date.toISOString().slice(0, 16)
  }

  private setInterval(initialTimestamp: number, finalTimestamp: number) {
    this.setState({ initialTimestamp, finalTimestamp })
    if (this.props.onChange) {
      this.props.onChange(initialTimestamp, finalTimestamp)
    }
  }

  private handleInitialTimestampChange(event: React.ChangeEvent<HTMLInputElement>) {
    const initialTimestamp = new Date(event.target?.value || 0).getTime()
    this.setState({ initialTimestamp })
    if (this.props.onChange) {
      this.props.onChange(initialTimestamp, this.state.finalTimestamp)
    }
  }

  private handleFinalTimestampChange(event: React.ChangeEvent<HTMLInputElement>) {
    const finalTimestamp = new Date(event.target?.value || 0).getTime()
    this.setState({ finalTimestamp })
    if (this.props.onChange) {
      this.props.onChange(this.state.initialTimestamp, finalTimestamp)
    }
  }
}
