import * as React from 'react'
import styled from 'styled-components'
import Links from './Links'
import Logo from './Logo'

const Container = styled.div.attrs({
  className: 'container-fluid'
})`
  padding: 0
`

const Nav = styled.nav.attrs({
  className: 'navbar navbar-expand-lg navbar-dark bg-dark'
})`
  padding-left: 20px;
  margin-bottom: 20px;
`

export interface INavBarProps {
  menuItems: {
    url: string
    label: string
  }[],

  brandName: string
}

export default class NavBar extends React.Component<INavBarProps> {
  public render() {
    return (
      <Container>
        <Nav>
          <Logo />
          <Links menuItems={this.props.menuItems} brandName={this.props.brandName} />
        </Nav>
      </Container>
    )
  }
}
