import APIAdapter from '../../common/adapter/APIAdapter'
import StakingNodeTimeline from '../domain/StakingNodeTimeline.model'
import StakingNodeTimelineService from '../domain/StakingNodeTimeline.service'

export default class StakingNodeTimelineAPI extends APIAdapter implements StakingNodeTimelineService {
  async getTimelines(instancesIds?: string[], initialTimestamp?: number, finalTimestamp?: number): Promise<StakingNodeTimeline[]> {
    const response = await this.get<{
      success: boolean,
      data: StakingNodeTimeline[]
    }>('/staking-nodes-timeline', {
      instancesIds: instancesIds?.join(','),
      initialTimestamp,
      finalTimestamp
    })
    return response.data
  }
}
