import { TimelineError } from '../../timeline/domain/TimelineError'
import { TimelineSerie } from '../../timeline/domain/TimelineSerie'
import { TimelineSerieData } from '../../timeline/domain/TimelineSerieData'
import { StakingNodeDigest } from '../domain/StakingNodeDigest'
import StakingNodeTimeline from '../domain/StakingNodeTimeline.model'

export default class BuildStakingNodeDigest {
  public execute(nodeTimeline: StakingNodeTimeline): StakingNodeDigest {
    return {
      chain: nodeTimeline.software?.chain || '',
      cloudsnapVersion: nodeTimeline.software?.cloudsnapVersion,
      dproxyVersion: nodeTimeline.software?.dproxyVersion,
      instanceId: nodeTimeline.node.instanceId,
      url: `https://${nodeTimeline.node.region}.console.aws.amazon.com/ec2/home?region=${nodeTimeline.node.region}#InstanceDetails:instanceId=${nodeTimeline.node.instanceId}`,
      ip: nodeTimeline.node.ip,
      privateIp: nodeTimeline.node.privateIp,
      name: nodeTimeline.node.name,
      description: nodeTimeline.node.description,
      secondarySoftware: nodeTimeline.software?.secondarySoftware,
      software: nodeTimeline.software?.software,
      secondarySoftwareVersion: nodeTimeline.software?.secondarySoftwareVersion,
      softwareVersion: nodeTimeline.software?.softwareVersion,
      account: nodeTimeline.node.account,
      region: nodeTimeline.node.region,
      series: this.buildSeries(nodeTimeline),
      errors: this.buildErrors(nodeTimeline),
      status: this.getLastHealthyStatus(nodeTimeline),
      healthReport: this.buildHealthReport(nodeTimeline)
    }
  }

  private getLastHealthyStatus(nodeTimeline: StakingNodeTimeline) {
    const lastReport = this.getLastReport(nodeTimeline)
    return lastReport.status?.status
  }

  private getLastReport(nodeTimeline: StakingNodeTimeline) {
    return nodeTimeline.timeline[nodeTimeline.timeline.length - 1]
  }

  private buildSeries(nodeTimeline: StakingNodeTimeline) {
    const series: TimelineSerie[] = [{
      title: 'Consensus Client Lag',
      color: '#00539a55',
      data: [] as TimelineSerieData[]
    }, {
      title: 'Execution Engine Lag',
      color: '#a2191f55',
      data: [] as TimelineSerieData[]
    }]

    nodeTimeline.timeline.forEach((report) => {
      series[0].data.push({
        x: report.time,
        y: parseInt(report.status?.consensusClientLag ?? '0')
      })
      series[1].data.push({
        x: report.time,
        y: parseInt(report.status?.executionEngineLag ?? '0')
      })
    })
    return series
  }

  private buildErrors(nodeTimeline: StakingNodeTimeline) {
    const errors: TimelineError[] = []

    if (!nodeTimeline.errors || !nodeTimeline.errors.length) {
      return errors
    }

    nodeTimeline.timeline.forEach((report) => {
      if (report.errorIndex !== undefined && nodeTimeline.errors) {
        const message = '  ' + nodeTimeline.errors[report.errorIndex]
        const x = report.time
        errors.push({ x, message })
      }
    })

    return errors
  }

  private buildHealthReport(nodeTimeline: StakingNodeTimeline) {
    const periodHeathPercentage = this.calculateHealthPercentageFromPeriod(nodeTimeline)
    return {
      period: {
        value: periodHeathPercentage,
        label: `${periodHeathPercentage}%`
      },
      last10Minutes: {
        value: nodeTimeline.healthReport?.lastTenMinutes || 0,
        label: this.formatPercentage(nodeTimeline.healthReport?.lastTenMinutes || 0)
      },
      lastDay: {
        value: nodeTimeline.healthReport?.lastDay || 0,
        label: this.formatPercentage(nodeTimeline.healthReport?.lastDay || 0)
      },
      last30Days: {
        value: nodeTimeline.healthReport?.lastThirtyDays || 0,
        label: this.formatPercentage(nodeTimeline.healthReport?.lastThirtyDays || 0)
      },
      lifetime: {
        value: nodeTimeline.healthReport?.lifetime || 0,
        label: this.formatPercentage(nodeTimeline.healthReport?.lifetime || 0)
      }
    }
  }

  private formatPercentage(percentage: number) {
    return `${Math.round(percentage * 1000) / 10}%`
  }

  private calculateHealthPercentageFromPeriod(nodeTimeline: StakingNodeTimeline) {
    const lastReport = this.getLastReport(nodeTimeline)
    if (!lastReport.status) {
      return 0
    }

    const healthyReports = nodeTimeline.timeline.filter((report) => {
      return report.status?.status === 'healthy'
    }).length

    return Math.round((healthyReports / nodeTimeline.timeline.length) * 1000) / 10
  }
}
