import DateHelper from '../../../helper/date/DateHelper'
import OrchidStatus from '../domain/OrchidStatus.model'
import OrchidStatusDigest from '../domain/OrchidStatusDigest.model'

export default class BuildOrchidStatusDigest {
  constructor() {
    //
  }

  execute(logs: OrchidStatus[]): OrchidStatusDigest[] {
    return logs.map(l =>
      (l.error || !l.status)
        ? {
            time: DateHelper.format(l.time),
            commitURL: '',
            error: l.error,
            address: '',
            commit: '',
            ip: '',
            price: '',
            speed: ''
          }
        : {
            time: DateHelper.format(l.time),
            commitURL: `https://github.com/OrchidTechnologies/orchid/commit/${l.status?.commit}`,
            ...l.status
          })
  }
}
