import Presenter from '../../common/presenter/Presenter'
import { VSysServerServiceProvider } from '../domain/VSysServer.service'
import VSysServerStatusDigest from '../domain/VSysServerStatusDigest.model'
import BuildVSysServerStatusDigest from '../usecase/BuildVSysServerStatusDigest.usecase'
import GetVSysServersStatus from '../usecase/GetVSysServerStatus.usecase'
import VSysServerState from './VSysServer.state'

const initialState: VSysServerState = {
  displayData: false,
  displayLoadingMessage: false,
  data: []
}

export default class VSysServerPresenter extends Presenter<VSysServerState> {
  constructor(protected services: VSysServerServiceProvider) {
    super(initialState)
  }

  async init() {
    this.loadServersData()
  }

  async loadServersData() {
    const serverData = await new GetVSysServersStatus(this.services).execute()
    const data: VSysServerStatusDigest[] = new BuildVSysServerStatusDigest().execute(serverData)

    this.changeState({
      data,
      displayData: true,
      displayLoadingMessage: false
    })
  }
}
