import * as React from 'react'

export interface LoadingMessageProps {
  columns: number
  rows?: number
}

export default class LoadingPlaceholder extends React.Component<LoadingMessageProps> {
  public render() {
    return (
      <>
        {Array.from({ length: this.props.rows || 1 }, (e, i) =>
          <tr key={`placeholder-row-${i}`}>
          {
            Array.from({ length: this.props.columns }, (c, j) =>
              <td key={`placeholder-${j}`} className='placeholder-glow'><span className='placeholder col-12'></span></td>
            )
          }
          </tr>
        )}
      </>
    )
  }
}
