import EventBus from '../../common/event/EventBus'
import LoginService from '../domain/Login.service'
import LoginErrorEvent from '../event/LoginError.event'

export default class NotifyLoginError {
  constructor(protected loginService: LoginService) {
    //
  }

  execute(givenError: Error) {
    this.loginService.logout()
    EventBus.publish(new LoginErrorEvent(givenError))
  }
}
