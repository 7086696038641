import APIAdapter from '../../common/adapter/APIAdapter'
import EthValidatorReport from '../domain/EthValidatorReport.model'
import EthValidatorReportService from '../domain/EthValidatorReport.service'

export default class EthValidatorReportAPI extends APIAdapter implements EthValidatorReportService {
  async getAll(): Promise<EthValidatorReport[]> {
    const response = await this.get<{
      success: boolean,
      data: EthValidatorReport[]
    }>('/eth-validators-report')
    return response.data
  }
}
