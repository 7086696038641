import { Chart, ChartConfiguration, ChartDataset, Point } from 'chart.js/auto'
import * as React from 'react'
import HorizonalLinePlugin from './HorizontalLinePlugin'
import 'chartjs-adapter-moment'
import { ReferenceLine } from '../../../../core/staking-nodes/domain/ReferenceLine'
import { TimelineSerie } from '../../../../core/timeline/domain/TimelineSerie'
import VerticalTextPlugin from './VerticalTextPlugin'
import { TimelineError } from '../../../../core/timeline/domain/TimelineError'

export interface TimelineChartProps {
  series: TimelineSerie[]
  horizontalReferenceLines?: ReferenceLine[]
  style?: React.CSSProperties
  logarithmicScale: boolean
  errors: TimelineError[]
  minY?: number
  pointRadius?: number
  lineHeight?: number
}

export default class TimelineChart extends React.Component<TimelineChartProps> {
  static defaultProps = {
    minY: undefined
  }

  canvas = React.createRef<HTMLCanvasElement>()
  creatingChart = false
  chart: Chart | undefined

  public render() {
    return (
      <canvas style={this.props.style} ref={this.canvas}></canvas>
    )
  }

  componentDidMount(): void {
    console.log('TimelineChart.componentDidMount')
    this.createChart()
  }

  componentDidUpdate(): void {
    console.log('TimelineChart.componentDidUpdate')
    console.log(`Logarithmic scale: ${this.props.logarithmicScale}`)
    this.recreateChart()
  }

  recreateChart() {
    if (this.creatingChart) {
      console.log('Recreating chart in 100ms')
      setTimeout(() => this.recreateChart(), 100)
      return
    }

    if (this.chart) {
      this.chart?.destroy()
      this.chart = undefined
    }

    this.createChart()
  }

  createChart() {
    if (this.creatingChart) {
      return
    }
    this.creatingChart = true

    if (this.chart) {
      this.creatingChart = false
      return
    }

    if (!this.canvas.current) {
      this.creatingChart = false
      return
    }

    const ctx = this.canvas.current.getContext('2d')

    if (!ctx) {
      this.creatingChart = false
      return
    }

    this.chart = new Chart(ctx, this.buildChartConfig())

    this.creatingChart = false
    console.log('Chart created')
  }

  private buildChartConfig(): ChartConfiguration<'line'> {
    return {
      type: 'line',
      data: {
        datasets: this.createDatasets()
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'DD/MM/YY HH:mm'
              }
            }
          },
          y: {
            type: this.props.logarithmicScale ? 'logarithmic' : 'linear',
            min: this.props.minY
          }
        },
        elements: {
          point: {
            radius: this.props.pointRadius ?? 2
          }
        }
      },
      plugins: [...this.createHorizontalReferenceLines(), ...this.createErrorsPlugins()]
    }
  }

  createHorizontalReferenceLines() {
    const referenceLines = this.props.horizontalReferenceLines || []
    return referenceLines.map(line => new HorizonalLinePlugin(line))
  }

  createErrorsPlugins() {
    return this.props.errors.map(error => new VerticalTextPlugin({ x: error.x, text: error.message }))
  }

  createDatasets(): ChartDataset<'line', (number | Point | null)[]>[] {
    return this.props.series.map(serie => {
      return {
        label: serie.title,
        data: serie.data,
        backgroundColor: serie.color,
        borderColor: serie.color,
        borderWidth: this.props.lineHeight ?? 1,
        tension: 0.1
      }
    })
  }
}
