import EventBus from '../../common/event/EventBus'
import LoginService from '../domain/Login.service'
import LoginEvent from '../event/Login.event'

export default class RestoreLogin {
  constructor(protected loginService: LoginService) {
    //
  }

  async execute() {
    if (this.loginService.isLoggedIn()) {
      EventBus.publish(new LoginEvent())
    }
  }
}
