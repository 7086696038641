import ClustersPresenter from '../../../core/cluster/presenter/Clusters.presenter'
import ClustersState from '../../../core/cluster/presenter/Clusters.state'
import Configuration from '../../config/Configuration'
import PresenterComponent from '../common/PresenterComponent'
import Error from '../Error'
import ErrorMessage from '../ErrorMessage'
import Tabs from '../layout/Tabs'
import DiskUsage from '../table/DiskUsage'
import LoadingPlaceholder from '../table/LoadingPlaceholder'
import TDHealthStatus from '../table/status/TDHealthStatus'
import Table from '../table/Table'
import TD from '../table/TD'
import TH from '../table/TH'

export interface ClustersProps {
  accounts?: string[]
  regions: string[]
}

export default class Clusters extends PresenterComponent<ClustersProps, ClustersState, ClustersPresenter> {
  constructor(props: ClustersProps) {
    const presenter = new ClustersPresenter(Configuration.services)
    super(props, presenter)
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this.presenter.init()
    this.checkHash()
  }

  private checkHash() {
    const hash = window.location.hash.replace('#', '')
    if (hash) {
      const [account, region] = hash.split('|')
      this.presenter.selectAccount(account)
      this.presenter.selectRegion(region)
    }
  }

  public render() {
    return (
      <>
        <h1>Status Dashboard - Clusters</h1>
        {this.renderAccountsTabs()}
        {this.renderTabs()}
        {this.renderTable()}
        {this.renderErrorMessage()}
        {this.renderNoDataMessage()}
      </>
    )
  }

  private renderAccountsTabs() {
    return <Tabs tabs={this.state.accounts.map(account => ({
      ...account,
      onClick: () => { this.presenter.selectAccount(account.key) }
    }))} />
  }

  private renderTabs() {
    return <Tabs tabs={this.state.regions.map(region => ({
      ...region,
      onClick: () => { this.presenter.selectRegion(region.key) }
    }))} />
  }

  protected renderLoadingContent() {
    if (!this.state.displayLoadingMessage) {
      return ''
    }

    return <LoadingPlaceholder columns={6}></LoadingPlaceholder>
  }

  protected renderNoDataMessage() {
    if (!this.state.noDataMessage) {
      return ''
    }

    return <em>{this.state.noDataMessage}</em>
  }

  protected renderTable() {
    if (!this.state.displayData && !this.state.displayLoadingMessage) {
      return ''
    }

    return <Table>
      <thead>
        <tr>
          <TH>IP</TH>
          <TH.Center>Chain</TH.Center>
          <TH.Center>Lag</TH.Center>
          <TH.Center>Health</TH.Center>
          <TH.Center>Disk Usage</TH.Center>
          <TH.Center>Status Time</TH.Center>
        </tr>
      </thead>
      <tbody>
        {this.renderLoadingContent()}
        {this.renderTableData()}
      </tbody>
    </Table>
  }

  private renderTableData() {
    if (!this.state.displayData) {
      return ''
    }

    return this.state.data.clusters.map((cluster) => (
      <>
        <tr key={`header-${cluster.name}`}>
          <th colSpan={6}>
            {cluster.name}
          </th>
        </tr>
        {
          cluster.nodes.map((node) => (
            <tr key={node.ip}>
              <TD.Bold>{node.ip}</TD.Bold>
              <TD.Center>{node.chain} <Error message={node.chainError} /></TD.Center>
              <TD.Center>{node.lag} <Error message={node.lagError} /> </TD.Center>
              <TDHealthStatus value={node.health}> <Error message={node.healthError} /></TDHealthStatus>
              <DiskUsage limit={Configuration.settings.diskUsageLimit}>{node.diskUsage} <Error message={node.diskUsageError} /></DiskUsage>
              <TD.Center>{node.time}</TD.Center>
            </tr>)
          )
        }
      </>
    ))
  }

  private renderErrorMessage() {
    if (!this.state.errorMessage) {
      return ''
    }
    return <ErrorMessage content={this.state.errorMessage} />
  }
}
