import APIAdapter from '../../common/adapter/APIAdapter'
import LastestVersions from '../domain/LatestVersions.model'
import VersionsService from '../domain/Versions.service'

export default class VersionsAPI extends APIAdapter implements VersionsService {
  async getLatestVersions(): Promise<LastestVersions[]> {
    const response = await this.get<{
      success: boolean,
      data: LastestVersions[]
    }>('/versions')
    return response.data
  }
}
