import VSysStakingServerStatus from '../domain/VSysSupernodeStatus.model'
import VSysSupernodeStatusDigest from '../domain/VSysSupernodeStatusDigest.model'

export default class BuildVSysSupernodeStatusDigest {
  execute(status: VSysStakingServerStatus): VSysSupernodeStatusDigest {
    return {
      url: `https://explorer.v.systems/nodeInfo/${status.address}`,
      ...status
    }
  }
}
