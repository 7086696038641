import AlgorandStakingServerStatus from '../domain/AlgorandStakingServerStatus.model'
import AlgorandStatusDigest from '../domain/AlgorandStatusDigest.model'

export default class BuildAlgorandStatusDigest {
  execute(serversStatus: AlgorandStakingServerStatus[]): AlgorandStatusDigest[] {
    return serversStatus.map(e => ({
      serverName: e.server.name,
      serverIp: e.server.ip,
      account: e.server.account ? this.trimAccount(e.server.account) : '',
      accountURL: e.server.account ? `https://algoexplorer.io/address/${e.server.account}` : '',
      lag: e.status.lag,
      lagError: e.status.lagError,
      healthcheck: e.status.healthcheck,
      healthcheckError: e.status.healthcheckError,
      diskUsage: e.status.diskUsage ? `${e.status.diskUsage} %` : '',
      diskUsageError: e.status.diskUsageError,
      participationOnline: e.status.participationOnline,
      participationOnlineError: e.status.participationOnlineError,
      voteValid: e.status.voteValid,
      voteValidError: e.status.voteValidError,
      time: new Date(e.time).toLocaleString()
    }))
  }

  protected trimAccount(key: string) {
    return `${key.substring(0, 10)}...`
  }
}
