import AlgorandAPI from '../../core/algorand/adapter/AlgorandAPI.adapter'
import AlgorandPresenter from '../../core/algorand/presenter/Algorand.presenter'
import ChainDataAPI from '../../core/chain-data/adapter/ChainDataAPI.adapter'
import GetLatestActiveChainsData from '../../core/chain-data/usecase/GetLatestActiveChainsData.usecase'
import NodeSyncTimeAPI from '../../core/chain-sync-time/adapter/NodeSyncTimeAPI.adapter'
import NodeSyncTimeMetricsAPI from '../../core/chain-sync-time/adapter/NodeSyncTimeMetricsAPI.adapter'
import VersionsAPI from '../../core/chain-versions/adapter/VersionsAPI.adapter'
import { ClustersReportAPI } from '../../core/cluster/adapter/ClustersReportAPI.adapter'
import EthValidatorReportAPI from '../../core/ethereum-validator-status/adapter/EthValidatorReportAPI.adapter'
import EthValidatorTimelineAPI from '../../core/ethereum-validator-status/adapter/EthValidatorTimelineAPI.adapter'
import GetValidatorsReports from '../../core/ethereum-validator-status/usecase/GetValidatorsReports.usecase'
import GoogleLogin from '../../core/login/adapter/GoogleLogin.adapter'
import SessionStorageCredentialHolder from '../../core/login/adapter/SessionStorageCredentialHolder.adapter'
import MessagesInMemory from '../../core/messages/adapter/MessagesInMemory.adapter'
import Messages from '../../core/messages/domain/Messages.model'
import NumberAdapter from '../../core/number/adapter/Number.adapter'
import OrchidAPI from '../../core/orchid/adapter/OrchidAPI.adapter'
import PackagesVersionsAPI from '../../core/packages-versions/adapter/PackagesVersionsAPI.adapter'
import SolanaAPI from '../../core/solana/adapter/SolanaAPI.adapter'
import StakingNodeTimelineAPI from '../../core/staking-nodes/adapter/StakingNodeTimelineAPI.adapter'
import VSysServerAPI from '../../core/v-sys-server/adapter/VSysServerAPI.adapter'
import VSysSupernodeAPI from '../../core/v-sys-supernode/adapter/VSysSupernodeAPI.adapter'
import DashboardServer from '../../helper/dashboard-server/DashboardServer'
import ChainDataAll from '../../react/components/chain-data/ChainDataAll'
import ChainDataLatest from '../../react/components/chain-data/ChainDataLatest'
import Metrics from '../../react/components/chain-sync-time/Metrics'
import Versions from '../../react/components/chain-versions/Versions'
import EthValidators from '../../react/components/eth-validators/EthValidators'
import AlgorandStakingStatus from '../components/algorand/AlgorandStakingStatus'
import Clusters from '../components/clusters/Clusters'
import Overview from '../components/overview/Overview'
import OrchidStatusPage from '../components/orchid/OrchidStatusPage'
import SolanaValidatorStatus from '../components/solana/SolanaValidatorStatus'
import StakingNodes from '../components/staking-nodes/StakingNodes'
import VSysSupernodeStatusPage from '../components/v-sys/VSysSupernodeStatusPage'
import ConfigurationProperties from './ConfigurationProperties'
import ConfigurationProvider from './ConfigurationProvider'
import BuildEthValidatorsOverview from '../../core/ethereum-validator-status/usecase/BuildEthValidatorsOverview.usecase'
import GetVSysServersStatus from '../../core/v-sys-server/usecase/GetVSysServerStatus.usecase'
import BuildVSysOverview from '../../core/v-sys-server/usecase/BuildVSysOverview.usecase'
import GetAlgorandStakingServersStatus from '../../core/algorand/usecase/GetAlgorandStakingServersStatus.usecase'
import BuildAlgorandOverview from '../../core/algorand/usecase/BuildAlgorandOverview.usecase'
import GetOrchidStatus from '../../core/orchid/usecase/GetOrchidStatus.usecase'
import BuildOrchidOverview from '../../core/orchid/usecase/BuildOrchidOverview.usecase'
import GetClustersReport from '../../core/cluster/usecase/GetClustersReport.usecases'
import BuildClustersOverview from '../../core/cluster/usecase/BuildClustersOverview.usecase'
import BuildSnapshotsOverview from '../../core/chain-data/usecase/BuildSnapshotsOverview.usecase'

export default class DefaultConfiguration implements ConfigurationProvider {
  protected apiBasePath = '/api'
  protected regions = [
    'us-east-1',
    'us-east-2',
    'eu-central-1',
    'af-south-1',
    'ap-northeast-2'
  ]

  protected settings = {
    diskUsageLimit: 95
  }

  protected getMessages(): Messages {
    return {
      brandName: 'Status Dashboard',
      missingSnapshotBlockInfo: 'Some networks may have a non-linear structure making the last block indeterminable. This health status was obtained from the dproxy healthcheck endpoint.'
    }
  }

  build(): ConfigurationProperties {
    const server = new DashboardServer(this.apiBasePath)

    const credentialHolder = new SessionStorageCredentialHolder()
    credentialHolder.subscribe((state) => {
      server.setAuthToken(state.credential)
    }, true)

    const services = {
      chainDataRepository: new ChainDataAPI(server.getApi()),
      versionsService: new VersionsAPI(server.getApi()),
      ethValidatorReportService: new EthValidatorReportAPI(server.getApi()),
      ethValidatorTimelineService: new EthValidatorTimelineAPI(server.getApi()),
      nodeSyncTimeRepository: new NodeSyncTimeAPI(server.getApi()),
      nodeSyncTimeMetricsRepository: new NodeSyncTimeMetricsAPI(server.getApi()),
      packagesVersionsService: new PackagesVersionsAPI(server.getApi()),
      messageService: new MessagesInMemory(this.getMessages()),
      clustersReportRepository: new ClustersReportAPI(server.getApi()),
      solanaService: new SolanaAPI(server.getApi()),
      vsysSupernodeService: new VSysSupernodeAPI(server.getApi()),
      numberService: new NumberAdapter(),
      orchidService: new OrchidAPI(server.getApi()),
      loginService: new GoogleLogin(credentialHolder),
      vsysServerService: new VSysServerAPI(server.getApi()),
      algorandService: new AlgorandAPI(server.getApi()),
      stakingNodes: new StakingNodeTimelineAPI(server.getApi())
    }

    const properties: ConfigurationProperties = {
      packageVersion: process.env.REACT_APP_VERSION || '-',

      menuItems: [
        { url: '/', label: 'Overview' },
        { url: '/snapshots', label: 'Snapshots' },
        { url: '/chains/list', label: 'List All' },
        { url: '/versions', label: 'Versions' },
        { url: '/metrics', label: 'Metrics' },
        { url: '/eth-validators', label: 'Eth Validators' },
        { url: '/solana-validators', label: 'Solana Validators' },
        { url: '/clusters', label: 'Clusters' },
        { url: '/vsys-supernode', label: 'VSys Supernode' },
        { url: '/orchid', label: 'Orchid' },
        { url: '/algorand', label: 'Algorand' },
        { url: '/staking', label: 'Staking' }
      ],

      services,

      routes: () => ([
        { path: '/', component: <Overview/> },
        { path: '/snapshots', component: <ChainDataLatest regions={properties.regions} /> },
        { path: '/chains/list', component: <ChainDataAll /> },
        { path: '/versions', component: <Versions /> },
        { path: '/metrics', component: <Metrics /> },
        { path: '/eth-validators', component: <EthValidators /> },
        { path: '/solana-validators', component: <SolanaValidatorStatus /> },
        { path: '/clusters', component: <Clusters regions={properties.regions} /> },
        { path: '/vsys-supernode', component: <VSysSupernodeStatusPage /> },
        { path: '/orchid', component: <OrchidStatusPage /> },
        { path: '/algorand', component: <AlgorandStakingStatus key={'algorand'} presenter={new AlgorandPresenter(properties.services)} /> },
        { path: '/staking', component: <StakingNodes /> }
      ]),

      usecases: {
        getValidatorsReports: () => new GetValidatorsReports(services),
        getLatestActiveChainsData: () => new GetLatestActiveChainsData(services),
        getEthValidatorsReports: () => new GetValidatorsReports(services),
        buildEthValidatorsOverview: () => new BuildEthValidatorsOverview(),
        getVSysServersStatus: () => new GetVSysServersStatus(services),
        buildVSysOverview: () => new BuildVSysOverview(this.settings.diskUsageLimit),
        getAlgorandStakingServersStatus: () => new GetAlgorandStakingServersStatus(services),
        buildAlgorandOverview: () => new BuildAlgorandOverview(this.settings.diskUsageLimit),
        getOrchidStatus: () => new GetOrchidStatus(services),
        buildOrchidOverview: () => new BuildOrchidOverview(),
        getClustersReport: () => new GetClustersReport(services),
        buildClustersOverview: () => new BuildClustersOverview(this.settings.diskUsageLimit),
        buildSnapshotsOverview: () => new BuildSnapshotsOverview(this.settings.diskUsageLimit)
      },

      regions: this.regions,

      googleIdentity: {
        clientId: process.env.REACT_APP_GOOGLE_ID_CLIENT_ID || ''
      },

      settings: this.settings
    }

    return properties
  }
}
