import * as React from 'react'
import styled from 'styled-components'

export interface ITabsProps {
  tabs: {
    key: string
    active: boolean
    onClick: () => void
  }[]
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

const StyledTabs = styled.ul`
  margin-bottom: 1em;
  border-bottom: solid 1px #aaa;

  width: 100%;
  .nav-item {
    margin-right: 0.2em;
    
    .nav-link {
      color: #000;
      margin-right: 0.3em;
      cursor: pointer;
      
      &.active {
      }
    }
    
  }  
`
export default class Tabs extends React.Component<ITabsProps> {
  public render() {
    return (
      <StyledTabs className='nav nav-tabs'>
        {this.props.tabs.map(tab => (
          <li key={tab.key} className='nav-item'>
            <a className={`nav-link ${tab.active ? 'active' : ''}`} href='#' onClick={tab.onClick}>{tab.key}</a>
          </li>
        ))}
      </StyledTabs>
    )
  }
}
