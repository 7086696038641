import APIAdapter from '../../common/adapter/APIAdapter'
import NodeSyncTime from '../domain/NodeSyncTime.entity'
import NodeSyncTimeRepository from '../domain/NodeSyncTime.repository'

export default class NodeSyncTimeAPI extends APIAdapter implements NodeSyncTimeRepository {
  async getNodesSyncTimes(): Promise<NodeSyncTime[]> {
    const response = await this.get<{
      success: boolean,
      data: NodeSyncTime[]
    }>('/node-sync-time')
    return response.data
  }
}
