import { NodeSyncTimeMetricsRepositoryProvider } from '../domain/NodeSyncTimeMetrics.repository'

export default class GetSyncTimeMetrics {
  constructor(protected services: NodeSyncTimeMetricsRepositoryProvider) {
    //
  }

  execute() {
    return this.services.nodeSyncTimeMetricsRepository.getNodesSyncTimeMetrics()
  }
}
