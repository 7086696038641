import React from 'react'
import styled from 'styled-components'

export interface IServerGroupProps {
  title: string
  children?: React.ReactNode | React.ReactNode[]
}

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  background-color: #ccc;
  border-radius: 0.6em;
  overflow: hidden;
  box-shadow: 0 0 0.2em #00000099
`
const Header = styled.div`
  font-weight: bold;
  margin: 0em 0 0.3em 0;
  background-color: #666;
  color: #fff;
  padding: 0.2em 0.5em 0 0.5em;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.5em;

  > * {
    margin: 0 0.5em 0.5em 0;
  }
`

export default class ServerGroup extends React.Component<IServerGroupProps> {
  public render() {
    return (
      <Container className='server-group'>
        <Header>
          {this.props.title}
        </Header>
        <Content>
          {this.props.children}
        </Content>
      </Container>
    )
  }
}
