import semver from 'semver'

/**
 * Compare two semantic version.
 * Versions are normalized before being compared.
 *
 * @see normalize
 * @param {string} v1 Version to be compared
 * @param {string} v2 Reference version
 * @returns
 *  `-1` when `v1 < v2`
 *
 *  `0` when `v1 == v2`
 *
 *  `1` when `v1 > v2`
 *
 *  `undefined` when v1 or v2 is a invalid version
 */
export function compareVersions(v1: string, v2: string): 1 | 0 | -1 | undefined {
  if (!v1 || !v2) {
    return undefined
  }

  const cv1 = normalize(v1)
  const cv2 = normalize(v2)

  if (!cv1 || !cv2) {
    return undefined
  }

  if (semver.gt(cv1, cv2)) { return 1 }

  if (semver.lt(cv1, cv2)) { return -1 }

  return 0
}

/**
 * Normalize the given version.
 *
 * Fix the following errors:
 *
 * Adds minnor or path version:
 * 1.0 => 1.0.0
 *
 * Removes zeros left padding:
 * 1.1.05 => 1.1.5
 *
 * Removes 'v' prefix:
 * v1.0.0 => 1.0.0
 *
 * @param {string} version
 * @returns
 */
export function normalize(version: string): string | null {
  if (!version) {
    return version
  }

  while (version.split('.').length < 3) {
    version = `${version}.0`
  }

  return semver.clean(version, { loose: true })
}
