import React from 'react'
import styled from 'styled-components'
import PackagesVersions from '../packages-versions/PackagesVersions'

const Container = styled.footer.attrs({
  className: 'bg-dark text-light px-3 py-1'
})`
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  gap: 1.5em;
  color: #6c757d;
  justify-content: flex-end;
`
export default class Footer extends React.Component {
  public render() {
    return <Container>
      <PackagesVersions />
    </Container>
  }
}
