import ServerOverview, { ServerStatus } from '../../overview/domain/Server.model'
import AlgorandStakingServerStatus from '../domain/AlgorandStakingServerStatus.model'

export default class BuildAlgorandOverview {
  constructor(protected diskUsageLimit: number) {
    //
  }

  execute(serversStatus: AlgorandStakingServerStatus[]): ServerOverview[] {
    return serversStatus.map(e => ({
      label: e.server.name,
      status: this.getStatus(e),
      url: 'algorand'
    }))
  }

  getStatus(serversStatus: AlgorandStakingServerStatus): ServerStatus {
    if (serversStatus.status.healthcheck !== 'healthy') {
      return 'notok'
    }

    if (serversStatus.status.diskUsage && parseFloat(serversStatus.status.diskUsage) > this.diskUsageLimit) {
      return 'notok'
    }

    return 'ok'
  }
}
