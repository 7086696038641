import { SolanaServiceProvider } from '../domain/Solana.service'
import SolanaValidatorStatus from '../domain/SolanaValidatorStatus.model'

export default class GetSolanaValidatorsStatus {
  constructor(protected services: SolanaServiceProvider) {
    //
  }

  async execute(): Promise<SolanaValidatorStatus[]> {
    return this.services.solanaService.getAll()
  }
}
