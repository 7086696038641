import Event from './Event'
import EventBusInstance, { Subscription } from './EventBusInstance'

export default class EventBus {
  static instance = new EventBusInstance()

  static publish(event: Event) {
    this.instance.publish(event)
  }

  static subscribe<E extends Event = Event>(eventKey: symbol, listener: Subscription<E>): void {
    this.instance.subscribe(eventKey, listener as Subscription<Event>)
  }

  static unsubscribe(eventKey: symbol, listener: Subscription<Event>): void {
    this.instance.unsubscribe(eventKey, listener)
  }
}
