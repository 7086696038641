import ServerOverview, { ServerStatus } from '../../overview/domain/Server.model'
import ServerGroupOverview from '../../overview/domain/ServerGroup.model'
import ChainData, { ChainDataSnapshotResult } from '../domain/ChainData.entity'
import FilterChainData from './FilterChainData.usecase'

export default class BuildSnapshotsOverview {
  constructor(protected diskUsageLimit: number) {
    //
  }

  execute(chainsData: ChainData[]): ServerGroupOverview[] {
    const regions = chainsData.map(e => e.region).filter((v, i, a) => a.indexOf(v) === i)
    return regions
      .map(region => this.buildRegionOverview(chainsData, region ?? 'unknown'))
      .filter(e => e.servers && e.servers.length > 0)
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  private buildRegionOverview(chainsData: ChainData[], region: string): ServerGroupOverview {
    const regionChainData = this.filterSnapshotData(chainsData, region)
    return {
      id: `snapshot-${region}`,
      name: `Snapshot ::: ${region}`,
      servers: regionChainData
        .map(chainData => this.buildChainOverview(chainData))
        .sort((a, b) => a.label.localeCompare(b.label))
    }
  }

  private filterSnapshotData(chainsData: ChainData[], region: string): ChainData<ChainDataSnapshotResult>[] {
    const filter = {
      region,
      methodFilter: {
        snapshot: true
      }
    }

    return new FilterChainData().execute(chainsData, filter) as ChainData<ChainDataSnapshotResult>[]
  }

  private buildChainOverview(chainData: ChainData<ChainDataSnapshotResult>): ServerOverview {
    return {
      label: `${chainData.chain}`,
      status: this.buildChainStatus(chainData),
      url: `snapshots#${this.extractAWSRegion(chainData.region)}`
    }
  }

  /**
   * Extract the AWS region from the given subregion. If the subregion is null, then
   * return the given region.
   * If the parameter is a region, then return the region itself.
   * Example: "us-east-1a" -> "us-east-1"
   */
  private extractAWSRegion(subregion: string | null) {
    if (subregion === null) {
      return subregion
    }

    const regionRegex = /([a-z]+-[a-z]+-[0-9]+)/
    const match = subregion.match(regionRegex)
    if (match === null) {
      return subregion
    }

    return match[1]
  }

  private buildChainStatus(chainData: ChainData<ChainDataSnapshotResult>): ServerStatus {
    if (chainData.result.health) {
      return 'notok'
    }

    if (Number.parseFloat(chainData.result.usedSpace) > this.diskUsageLimit) {
      return 'notok'
    }

    return 'ok'
  }
}
