import EthValidatorReport from '../domain/EthValidatorReport.model'
import { EthValidatorReportServiceProvider } from '../domain/EthValidatorReport.service'

export default class GetValidatorsReports {
  constructor(protected services: EthValidatorReportServiceProvider) {
    //
  }

  async execute(): Promise<EthValidatorReport[]> {
    return this.services.ethValidatorReportService.getAll()
  }
}
