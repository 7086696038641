import * as React from 'react'
import styled from 'styled-components'

export interface LoadMoreButtonProps {
  onClick?: () => void
}

const LoadMore = styled.div`
  background-color: #ddd;
  text-align: center;
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #aaa;
  }
`

export default class LoadMoreButton extends React.Component<LoadMoreButtonProps> {
  public render() {
    return (
      <LoadMore className='my-4 p-3' onClick={this.props.onClick}>
        Load More
      </LoadMore>
    )
  }
}
