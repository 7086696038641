import * as React from 'react'
import Status from '../../status/Status'
import TD from '../TD'

export interface IStatusCellProps {
  label: string
  color: string
  blink?: boolean
  displayTag?: boolean
  hoverMessage?: string
  children?:
  | React.ReactNode
  | React.ReactNode[]
}

export default class TDStatus extends React.Component<IStatusCellProps> {
  static defaultProps = {
    blink: false,
    displayTag: true,
    hoverMessage: ''
  }

  public render() {
    return (
      <TD.Center>
        <Status
          color={this.props.color}
          label={this.props.label}
          displayTag={this.props.displayTag}
          hoverMessage={this.props.hoverMessage}
          blink={this.props.blink}
        >
          {this.props.children}
        </Status>
      </TD.Center >
    )
  }
}
