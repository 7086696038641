import StakingNodeTimeline from '../domain/StakingNodeTimeline.model'
import { StakingNodeTimelineServiceProvider } from '../domain/StakingNodeTimeline.service'

export default class GetStakingNodesTimelines {
  constructor(
    private services: StakingNodeTimelineServiceProvider
  ) {
    //
  }

  async execute(instancesIds?: string[], initialTimestamp?: number, finalTimestamp?: number): Promise<StakingNodeTimeline[]> {
    return this.services.stakingNodes.getTimelines(instancesIds, initialTimestamp, finalTimestamp)
  }
}
