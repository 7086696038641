import SolanaValidatorStatus from '../domain/SolanaValidatorStatus.model'
import SolanaValidatorStatusDigest from '../domain/SolanaValidatorStatusDigest.model'

export default class BuildSolanaValidatorStatusDigest {
  execute(serversStatus: SolanaValidatorStatus[]): SolanaValidatorStatusDigest[] {
    return serversStatus.map(e => ({
      serverName: e.server.name,
      serverIp: e.server.ip,
      publicKey: e.server.publicKey ?? '',
      publicKeyURL: e.server.publicKey ? `https://explorer.solana.com/address/${e.server.publicKey}?cluster=${e.server.cluster}` : '',
      voteAccount: e.server.voteAccount ?? '',
      voteAccountURL: e.server.voteAccount ? `https://explorer.solana.com/address/${e.server.voteAccount}?cluster=${e.server.cluster}` : '',
      healthcheck: e.status.healthcheck,
      healthcheckError: e.status.healthcheckError,
      diskUsage: e.status.diskUsage ? `${e.status.diskUsage} %` : '',
      diskUsageError: e.status.diskUsageError,
      diskUsageBlockchain: e.status.diskUsageBlockchain ? `${e.status.diskUsageBlockchain} %` : '',
      diskUsageBlockchainError: e.status.diskUsageBlockchainError,
      time: new Date(e.time).toLocaleString()
    }))
  }
}
