type Subscription<S> = (state: S) => void

export default abstract class StateController<S> {
  private internalState: S
  private listeners: Subscription<S>[] = []

  constructor(initalState: S) {
    this.internalState = initalState
  }

  public get state(): S {
    return this.internalState
  }

  protected changeState(state: Partial<S>): void {
    this.setState({
      ...this.internalState,
      ...state
    })
  }

  protected setState(state: S): void {
    this.internalState = state
    this.notifyStateChangeToListeners()
  }

  protected notifyStateChangeToListeners() {
    if (this.listeners.length > 0) {
      this.listeners.forEach(this.notifyStateChangeToListener.bind(this))
    }
  }

  protected notifyStateChangeToListener(listener: Subscription<S>) {
    listener(this.state)
  }

  public subscribe(listener: Subscription<S>, sendCurrentStatus = false): void {
    this.listeners.push(listener)
    if (sendCurrentStatus) {
      this.notifyStateChangeToListener(listener)
    }
  }

  public unsubscribe(listener: Subscription<S>): void {
    const index = this.listeners.indexOf(listener)
    if (index > -1) {
      this.listeners.splice(index, 1)
    }
  }
}
