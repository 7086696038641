import APIErrorEvent from '../../common/adapter/APIError.event'
import EventBus from '../../common/event/EventBus'
import Presenter from '../../common/presenter/Presenter'
import { LoginServiceProvider } from '../../login/domain/Login.service'
import LoginEvent from '../../login/event/Login.event'
import LoginErrorEvent from '../../login/event/LoginError.event'
import AppState from './App.state'

const initialState: AppState = {
  displayContent: false,
  hasErrors: false
}

export default class AppPresenter extends Presenter<AppState> {
  constructor(protected services: LoginServiceProvider) {
    super(initialState)
  }

  init() {
    this.bindLogin()
    this.bindLoginError()
    this.bindAPIError()
  }

  protected bindLogin() {
    EventBus.subscribe(LoginEvent.key, this.handleLogin.bind(this))
  }

  protected bindLoginError() {
    EventBus.subscribe(LoginErrorEvent.key, this.handleLoginError.bind(this))
  }

  protected bindAPIError() {
    EventBus.subscribe(APIErrorEvent.key, this.handleAPIError.bind(this))
  }

  protected handleLogin() {
    this.changeState({
      displayContent: true
    })
  }

  protected handleLoginError() {
    this.changeState({
      hasErrors: true,
      errorMessage: 'Login failed.'
    })
  }

  protected handleAPIError(event: APIErrorEvent) {
    this.changeState({
      hasErrors: true,
      errorMessage: this.extractErrorMessage(event)
    })
  }

  protected extractErrorMessage(event: APIErrorEvent): string {
    if (typeof event.error === 'string') {
      return event.error
    }

    if (!(event.error instanceof Error)) {
      return 'Unknown error on server response.'
    }

    // 401 Unauthorized errors code normally are supressed.
    // So we consider status `0` as auth error
    if (event.status === 401 || event.status === 0) {
      // TODO Fix session expiration properly
      sessionStorage.clear()
      return 'Login expired or invalid. Refresh the page to login again.'
    }

    if (event.status) {
      return `Error on server response: ${event.error.message} (Status code: ${event.status})`
    }

    return 'Unknown error on server response.'
  }

  notifyError(error: Error) {
    this.changeState({
      hasErrors: true,
      errorMessage: error.message
    })
  }
}
