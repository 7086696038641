import APIAdapter from '../../common/adapter/APIAdapter'
import PackagesVersionsService from '../domain/PackagesVersions.service'
import PackagesVersions from '../domain/PackagesVersions.model'
import ExternalPackagesVersions from '../domain/ExternalPackagesVersions.model'
import Configuration from '../../../react/config/Configuration'

export default class PackagesVersionsAPI extends APIAdapter implements PackagesVersionsService {
  async getVersions(): Promise<PackagesVersions> {
    const externalVersions = await this.getExternalVersions()
    return {
      client: this.getInternalVersion(),
      server: externalVersions['nodes-dashboard-server'],
      cloudSnap: externalVersions['cloud-snap']
    }
  }

  async getExternalVersions(): Promise<ExternalPackagesVersions> {
    const response = await this.get<{
      success: boolean,
      data: ExternalPackagesVersions
    }>('/packages')
    return response.data
  }

  getInternalVersion() {
    return Configuration.packageVersion
  }
}
