import React, { ReactNode } from 'react'

export interface ITHProps {
  style?: React.CSSProperties
  children?:
  | ReactNode
  | ReactNode[]
}

export default class TH extends React.Component<ITHProps> {
  static Bold = (props: ITHProps) => <th className="fw-bold" style={props.style}>{props.children}</th>
  static Center = (props: ITHProps) => <th className="text-center" style={props.style}>{props.children}</th>
  public render() {
    return (
      <th style={this.props.style}> {this.props.children} </th>
    )
  }
}
