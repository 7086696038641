import EthValidatorReport from '../domain/EthValidatorReport.model'
import EthValidatorStatusDigest from '../domain/EthValidatorStatusDigest'
import FormattedEthValidatorStatus from '../domain/FormattedEthValidatorStatus'

export default class BuildValidatorsStatusDigest {
  execute(reports: EthValidatorReport[]): EthValidatorStatusDigest[] {
    return this.buildValidatorsReportDigest(reports)
  }

  protected buildValidatorsReportDigest(validatorsReports: EthValidatorReport[]): EthValidatorStatusDigest[] {
    return validatorsReports.map(report => {
      if (report.status) {
        return this.buildValidatorsStatusDigest(report)
      } else {
        return this.buildValidatorsStatusErrorDigest(report)
      }
    })
  }

  protected buildValidatorsStatusDigest(report: EthValidatorReport): EthValidatorStatusDigest {
    if (!report.status) {
      throw new Error('Invalid report')
    }

    const formattedStatus = new FormattedEthValidatorStatus(report.status)
    return {
      index: formattedStatus.index.toString(),
      validatorURL: formattedStatus.validatorURL,
      publicKey: formattedStatus.publicKey,
      publicKeyURL: formattedStatus.publicKeyURL,
      balance: formattedStatus.formattedBalance,
      state: formattedStatus.state,
      lastAttestationSlot: formattedStatus.lastAttestationSlot.toString(),
      attestationEffectiveness: formattedStatus.formatedAttestationEffectiveness,
      income7Days: formattedStatus.formattedIncome7Days,
      publicKeyTrimmed: formattedStatus.trimmedPublicKey,
      rank7Days: formattedStatus.rank7Days.toString()
    }
  }

  protected buildValidatorsStatusErrorDigest(report: EthValidatorReport): EthValidatorStatusDigest {
    return {
      index: report.validatorIndex.toString(),
      validatorURL: FormattedEthValidatorStatus.buildValidatorURL(report.validatorIndex),
      publicKey: '',
      publicKeyURL: '',
      balance: '',
      state: '',
      lastAttestationSlot: '',
      attestationEffectiveness: '',
      income7Days: '',
      publicKeyTrimmed: '',
      rank7Days: '',
      error: report.error
    }
  }
}
